import React from 'react'

export type { PlantIconProps } from './basePlant'

export const seed = React.lazy(() => import('./seed'))
export const seedless = React.lazy(() => import('./seedless'))
export const BasePlant = React.lazy(() => import('./basePlant'))

// Empty
export const empty1 = React.lazy(
    () => import('./emptyStatePlants/emptyStatePlant1')
)
export const empty2 = React.lazy(
    () => import('./emptyStatePlants/emptyStatePlant2')
)
export const empty3 = React.lazy(
    () => import('./emptyStatePlants/emptyStatePlant3')
)
export const empty4 = React.lazy(
    () => import('./emptyStatePlants/emptyStatePlant4')
)

// Beginner
export const beginner1 = React.lazy(() => import('./beginner/beginner1'))
export const beginner2 = React.lazy(() => import('./beginner/beginner2'))
export const beginner3 = React.lazy(() => import('./beginner/beginner3'))
export const beginner4 = React.lazy(() => import('./beginner/beginner4'))
export const beginner5 = React.lazy(() => import('./beginner/beginner5'))

// Beginner Rare
export const beginnerRare1 = React.lazy(
    () => import('./beginnerRare/beginnerRare1')
)
export const beginnerRare2 = React.lazy(
    () => import('./beginnerRare/beginnerRare2')
)
export const beginnerRare3 = React.lazy(
    () => import('./beginnerRare/beginnerRare3')
)
export const beginnerRare4 = React.lazy(
    () => import('./beginnerRare/beginnerRare4')
)
export const beginnerRare5 = React.lazy(
    () => import('./beginnerRare/beginnerRare5')
)

// Intermediate
export const intermediate1 = React.lazy(
    () => import('./intermediate/intermediate1')
)
export const intermediate2 = React.lazy(
    () => import('./intermediate/intermediate2')
)
export const intermediate3 = React.lazy(
    () => import('./intermediate/intermediate3')
)
export const intermediate4 = React.lazy(
    () => import('./intermediate/intermediate4')
)
export const intermediate5 = React.lazy(
    () => import('./intermediate/intermediate5')
)
export const intermediate6 = React.lazy(
    () => import('./intermediate/intermediate6')
)
export const intermediate7 = React.lazy(
    () => import('./intermediate/intermediate7')
)
export const intermediate8 = React.lazy(
    () => import('./intermediate/intermediate8')
)
export const intermediate9 = React.lazy(
    () => import('./intermediate/intermediate9')
)
export const intermediate10 = React.lazy(
    () => import('./intermediate/intermediate10')
)

// Intermediate Rare
export const intermediateRare1 = React.lazy(
    () => import('./intermediateRare/intermediateRare1')
)
export const intermediateRare2 = React.lazy(
    () => import('./intermediateRare/intermediateRare2')
)
export const intermediateRare3 = React.lazy(
    () => import('./intermediateRare/intermediateRare3')
)
export const intermediateRare4 = React.lazy(
    () => import('./intermediateRare/intermediateRare4')
)
export const intermediateRare5 = React.lazy(
    () => import('./intermediateRare/intermediateRare5')
)
export const intermediateRare6 = React.lazy(
    () => import('./intermediateRare/intermediateRare6')
)
export const intermediateRare7 = React.lazy(
    () => import('./intermediateRare/intermediateRare7')
)
export const intermediateRare8 = React.lazy(
    () => import('./intermediateRare/intermediateRare8')
)
export const intermediateRare9 = React.lazy(
    () => import('./intermediateRare/intermediateRare9')
)
export const intermediateRare10 = React.lazy(
    () => import('./intermediateRare/intermediateRare10')
)

// Advanced
export const advanced1 = React.lazy(() => import('./advanced/advanced1'))
export const advanced2 = React.lazy(() => import('./advanced/advanced2'))
export const advanced3 = React.lazy(() => import('./advanced/advanced3'))
export const advanced4 = React.lazy(() => import('./advanced/advanced4'))
export const advanced5 = React.lazy(() => import('./advanced/advanced5'))
export const advanced6 = React.lazy(() => import('./advanced/advanced6'))
export const advanced7 = React.lazy(() => import('./advanced/advanced7'))
export const advanced8 = React.lazy(() => import('./advanced/advanced8'))
export const advanced9 = React.lazy(() => import('./advanced/advanced9'))
export const advanced10 = React.lazy(() => import('./advanced/advanced10'))
export const advanced11 = React.lazy(() => import('./advanced/advanced11'))
export const advanced12 = React.lazy(() => import('./advanced/advanced12'))
export const advanced13 = React.lazy(() => import('./advanced/advanced13'))
export const advanced14 = React.lazy(() => import('./advanced/advanced14'))
export const advanced15 = React.lazy(() => import('./advanced/advanced15'))

// Advanced Rare
export const advancedRare1 = React.lazy(
    () => import('./advancedRare/advancedRare1')
)
export const advancedRare2 = React.lazy(
    () => import('./advancedRare/advancedRare2')
)
export const advancedRare3 = React.lazy(
    () => import('./advancedRare/advancedRare3')
)
export const advancedRare4 = React.lazy(
    () => import('./advancedRare/advancedRare4')
)
export const advancedRare5 = React.lazy(
    () => import('./advancedRare/advancedRare5')
)
export const advancedRare6 = React.lazy(
    () => import('./advancedRare/advancedRare6')
)
export const advancedRare7 = React.lazy(
    () => import('./advancedRare/advancedRare7')
)
export const advancedRare8 = React.lazy(
    () => import('./advancedRare/advancedRare8')
)
export const advancedRare9 = React.lazy(
    () => import('./advancedRare/advancedRare9')
)
export const advancedRare10 = React.lazy(
    () => import('./advancedRare/advancedRare10')
)
export const advancedRare11 = React.lazy(
    () => import('./advancedRare/advancedRare11')
)
export const advancedRare12 = React.lazy(
    () => import('./advancedRare/advancedRare12')
)
export const advancedRare13 = React.lazy(
    () => import('./advancedRare/advancedRare13')
)
export const advancedRare14 = React.lazy(
    () => import('./advancedRare/advancedRare14')
)
export const advancedRare15 = React.lazy(
    () => import('./advancedRare/advancedRare15')
)

// Resiliflora
export const resilifloraRare1 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare1')
)
export const resilifloraRare2 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare2')
)
export const resilifloraRare3 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare3')
)
export const resilifloraRare4 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare4')
)
export const resilifloraRare5 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare5')
)
export const resilifloraRare6 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare6')
)
export const resilifloraRare7 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare7')
)
export const resilifloraRare8 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare8')
)
export const resilifloraRare9 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare9')
)
export const resilifloraRare10 = React.lazy(
    () => import('./resilifloraRare/resilifloraRare10')
)

// Nova Futura
export const novafutura = React.lazy(() => import('./novafutura'))

// Nutrivora
export const nutrivora1 = React.lazy(() => import('./nutrivora/nutrivora1'))
export const nutrivora2 = React.lazy(() => import('./nutrivora/nutrivora2'))
export const nutrivora3 = React.lazy(() => import('./nutrivora/nutrivora3'))
export const nutrivora4 = React.lazy(() => import('./nutrivora/nutrivora4'))
export const nutrivora5 = React.lazy(() => import('./nutrivora/nutrivora5'))
export const nutrivora6 = React.lazy(() => import('./nutrivora/nutrivora6'))
export const nutrivora7 = React.lazy(() => import('./nutrivora/nutrivora7'))
export const nutrivora8 = React.lazy(() => import('./nutrivora/nutrivora8'))
export const nutrivora9 = React.lazy(() => import('./nutrivora/nutrivora9'))
export const nutrivora10 = React.lazy(() => import('./nutrivora/nutrivora10'))
export const nutrivora11 = React.lazy(() => import('./nutrivora/nutrivora11'))
export const nutrivora12 = React.lazy(() => import('./nutrivora/nutrivora12'))
export const nutrivora13 = React.lazy(() => import('./nutrivora/nutrivora13'))
export const nutrivora14 = React.lazy(() => import('./nutrivora/nutrivora14'))
export const nutrivora15 = React.lazy(() => import('./nutrivora/nutrivora15'))

// Trophies
export const referralTrophy = React.lazy(() => import('./../trophies/referral'))
export const personalResetTrophy = React.lazy(
    () => import('./../trophies/personalReset')
)

// Audaciaflora
export const audaciaflora1 = React.lazy(
    () => import('./audaciaflora/audaciaflora1')
)
export const audaciaflora2 = React.lazy(
    () => import('./audaciaflora/audaciaflora2')
)
export const audaciaflora3 = React.lazy(
    () => import('./audaciaflora/audaciaflora3')
)
export const audaciaflora4 = React.lazy(
    () => import('./audaciaflora/audaciaflora4')
)
export const audaciaflora5 = React.lazy(
    () => import('./audaciaflora/audaciaflora5')
)
export const audaciaflora6 = React.lazy(
    () => import('./audaciaflora/audaciaflora6')
)
export const audaciaflora7 = React.lazy(
    () => import('./audaciaflora/audaciaflora7')
)
export const audaciaflora8 = React.lazy(
    () => import('./audaciaflora/audaciaflora8')
)
export const audaciaflora9 = React.lazy(
    () => import('./audaciaflora/audaciaflora9')
)
export const audaciaflora10 = React.lazy(
    () => import('./audaciaflora/audaciaflora10')
)

// Bonvira
export const bonvira1 = React.lazy(() => import('./bonvira/bonvira1'))
export const bonvira2 = React.lazy(() => import('./bonvira/bonvira2'))
export const bonvira3 = React.lazy(() => import('./bonvira/bonvira3'))
export const bonvira4 = React.lazy(() => import('./bonvira/bonvira4'))
export const bonvira5 = React.lazy(() => import('./bonvira/bonvira5'))
export const bonvira6 = React.lazy(() => import('./bonvira/bonvira6'))
export const bonvira7 = React.lazy(() => import('./bonvira/bonvira7'))
export const bonvira8 = React.lazy(() => import('./bonvira/bonvira8'))
export const bonvira9 = React.lazy(() => import('./bonvira/bonvira9'))
export const bonvira10 = React.lazy(() => import('./bonvira/bonvira10'))

// Cresfolia
export const cresfolia1 = React.lazy(() => import('./cresfolia/cresfolia1'))
export const cresfolia2 = React.lazy(() => import('./cresfolia/cresfolia2'))
export const cresfolia3 = React.lazy(() => import('./cresfolia/cresfolia3'))
export const cresfolia4 = React.lazy(() => import('./cresfolia/cresfolia4'))
export const cresfolia5 = React.lazy(() => import('./cresfolia/cresfolia5'))
export const cresfolia6 = React.lazy(() => import('./cresfolia/cresfolia6'))
export const cresfolia7 = React.lazy(() => import('./cresfolia/cresfolia7'))
export const cresfolia8 = React.lazy(() => import('./cresfolia/cresfolia8'))
export const cresfolia9 = React.lazy(() => import('./cresfolia/cresfolia9'))
export const cresfolia10 = React.lazy(() => import('./cresfolia/cresfolia10'))

// Fortituda
export const fortituda1 = React.lazy(() => import('./fortituda/fortituda1'))
export const fortituda2 = React.lazy(() => import('./fortituda/fortituda2'))
export const fortituda3 = React.lazy(() => import('./fortituda/fortituda3'))
export const fortituda4 = React.lazy(() => import('./fortituda/fortituda4'))
export const fortituda5 = React.lazy(() => import('./fortituda/fortituda5'))
export const fortituda6 = React.lazy(() => import('./fortituda/fortituda6'))
export const fortituda7 = React.lazy(() => import('./fortituda/fortituda7'))
export const fortituda8 = React.lazy(() => import('./fortituda/fortituda8'))
export const fortituda9 = React.lazy(() => import('./fortituda/fortituda9'))
export const fortituda10 = React.lazy(() => import('./fortituda/fortituda10'))

// Harmonia
export const harmonia1 = React.lazy(() => import('./harmonia/harmonia1'))
export const harmonia2 = React.lazy(() => import('./harmonia/harmonia2'))
export const harmonia3 = React.lazy(() => import('./harmonia/harmonia3'))
export const harmonia4 = React.lazy(() => import('./harmonia/harmonia4'))
export const harmonia5 = React.lazy(() => import('./harmonia/harmonia5'))
export const harmonia6 = React.lazy(() => import('./harmonia/harmonia6'))
export const harmonia7 = React.lazy(() => import('./harmonia/harmonia7'))
export const harmonia8 = React.lazy(() => import('./harmonia/harmonia8'))
export const harmonia9 = React.lazy(() => import('./harmonia/harmonia9'))
export const harmonia10 = React.lazy(() => import('./harmonia/harmonia10'))

// Revalesca
export const revalesca1 = React.lazy(() => import('./revalesca/revalesca1'))
export const revalesca2 = React.lazy(() => import('./revalesca/revalesca2'))
export const revalesca3 = React.lazy(() => import('./revalesca/revalesca3'))
export const revalesca4 = React.lazy(() => import('./revalesca/revalesca4'))
export const revalesca5 = React.lazy(() => import('./revalesca/revalesca5'))
export const revalesca6 = React.lazy(() => import('./revalesca/revalesca6'))
export const revalesca7 = React.lazy(() => import('./revalesca/revalesca7'))
export const revalesca8 = React.lazy(() => import('./revalesca/revalesca8'))
export const revalesca9 = React.lazy(() => import('./revalesca/revalesca9'))
export const revalesca10 = React.lazy(() => import('./revalesca/revalesca10'))

// Serenita
export const serenita1 = React.lazy(() => import('./serenita/serenita1'))
export const serenita2 = React.lazy(() => import('./serenita/serenita2'))
export const serenita3 = React.lazy(() => import('./serenita/serenita3'))
export const serenita4 = React.lazy(() => import('./serenita/serenita4'))
export const serenita5 = React.lazy(() => import('./serenita/serenita5'))

// Solaflora
export const solaflora1 = React.lazy(() => import('./solaflora/solaflora1'))
export const solaflora2 = React.lazy(() => import('./solaflora/solaflora2'))
export const solaflora3 = React.lazy(() => import('./solaflora/solaflora3'))
export const solaflora4 = React.lazy(() => import('./solaflora/solaflora4'))
export const solaflora5 = React.lazy(() => import('./solaflora/solaflora5'))

// Vitara
export const vitara1 = React.lazy(() => import('./vitara/vitara1'))
export const vitara2 = React.lazy(() => import('./vitara/vitara2'))
export const vitara3 = React.lazy(() => import('./vitara/vitara3'))
export const vitara4 = React.lazy(() => import('./vitara/vitara4'))
export const vitara5 = React.lazy(() => import('./vitara/vitara5'))
export const vitara6 = React.lazy(() => import('./vitara/vitara6'))
export const vitara7 = React.lazy(() => import('./vitara/vitara7'))
export const vitara8 = React.lazy(() => import('./vitara/vitara8'))
export const vitara9 = React.lazy(() => import('./vitara/vitara9'))
export const vitara10 = React.lazy(() => import('./vitara/vitara10'))
