import { ClassNames, keyframes } from '@emotion/react'
import { useMediaQuery } from '@mui/material'
import React, { ReactElement, useCallback, useMemo } from 'react'

export interface SwayAnimationProps {
    show?: boolean
    iterationCount?: string
    duration?: number
    children: ReactElement<any, any>
}

const SwayAnimation: React.FC<SwayAnimationProps> = ({
    show = true,
    iterationCount = 'infinite',
    duration = 3,
    children
}) => {
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )

    const wiggleAnimation = keyframes({
        '0%': { transform: 'rotate(0deg)' },
        '20%': { transform: 'rotate(-3deg)' },
        '40%': { transform: 'rotate(2deg)' },
        '60%': { transform: 'rotate(-1deg)' },
        '80%': { transform: 'rotate(2deg)' },
        '100%': { transform: 'rotate(0deg)' }
    })

    const wiggleAnimationStyles = useMemo(() => {
        return {
            animation: wiggleAnimation,
            animationName: wiggleAnimation.name,
            animationDirection: 'normal',
            animationDuration: `${duration}s`,
            animationTimingFunction: 'ease-in',
            animationIterationCount: iterationCount,
            animationPlayState:
                show && !prefersReducedMotion ? 'running' : 'paused',
            transformOrigin: 'bottom'
        }
    }, [wiggleAnimation, duration, iterationCount, show, prefersReducedMotion])

    const renderChildElement = useCallback(
        (css, cx) => {
            return React.cloneElement(children, {
                className: cx(css(wiggleAnimationStyles))
            })
        },
        [children, wiggleAnimationStyles]
    )

    return (
        <ClassNames>{({ css, cx }) => renderChildElement(css, cx)}</ClassNames>
    )
}

export default SwayAnimation
