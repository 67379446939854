import { withAchievementsProvider, withPlantsProvider } from '../../../../hooks'
import AchievementsCenterMenu from './AchievementsCenterMenu'
import AchievementsCenterMenuContext from './AchievementsCenterMenuContext'

export const AchievementsCenterMenuWithPlantProvider = withPlantsProvider(
    AchievementsCenterMenuContext
)
export const AchievementsCenterMenuWithProvider = withAchievementsProvider(
    AchievementsCenterMenuWithPlantProvider
)

export default AchievementsCenterMenu
