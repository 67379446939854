import BasePlant, { PlantIconProps } from '../basePlant'

const resilifloraRare10: React.FC<PlantIconProps> = ({ ...props }) => {
    return (
        <BasePlant {...props} data-testid="resiliflora-rare-10">
            <g>
                <path
                    d="M172.52 50.8013C172.52 50.8013 176.2 39.4813 176.01 37.2313C175.82 34.9813 175.69 33.3913 175.69 33.3913C175.69 33.3913 172.51 31.5513 170.95 32.0213C169.39 32.4913 166.87 33.4513 166.87 33.4513C166.87 33.4513 166.1 36.2913 167.43 40.1313C168.76 43.9713 170.87 43.4013 170.2 45.8713C169.53 48.3413 170.51 49.6313 170.51 49.6313L172.52 50.8113V50.8013Z"
                    fill="#14808D"
                />
                <path
                    d="M173.9 34.2113L173.96 28.9113C173.96 28.9113 171.5 25.5813 169.97 26.4713C168.44 27.3613 166.19 28.5313 166.88 30.7113C167.57 32.8913 168.97 35.5513 168.97 35.5513L173.9 34.2113Z"
                    fill="#46A0A6"
                />
                <path
                    d="M160.739 30.1713C158.139 29.1513 156.079 26.8413 155.369 24.1413C155.159 23.3313 155.139 22.2913 155.839 21.8413C156.449 21.4513 157.299 21.7213 157.969 21.4413C158.399 21.2613 158.709 20.8813 159.019 20.5413C159.889 19.6113 160.97 18.8713 162.18 18.4413C162.76 18.2313 163.429 18.1113 163.989 18.3713C164.439 18.5813 164.749 19.0113 165.019 19.4313C166.289 21.3713 167.299 23.4813 168.029 25.6813C168.489 27.0813 168.84 28.5513 168.68 30.0113C168.51 31.4713 167.749 32.9313 166.449 33.6113"
                    fill="#F2947F"
                />
                <path
                    d="M170.81 29.8913C168.52 28.9813 166.65 27.0513 165.83 24.7313C165.01 22.4113 165.23 19.7313 166.44 17.5813C166.8 16.9413 167.25 16.3413 167.52 15.6513C167.82 14.8913 167.9 14.0413 168.37 13.3813C168.6 13.0413 168.92 12.7813 169.24 12.5113C169.32 12.4413 169.41 12.3713 169.52 12.3413C169.66 12.3013 169.81 12.3413 169.95 12.3713C170.52 12.5213 171.1 12.6713 171.67 12.8313C172.17 12.9613 172.67 13.0913 173.13 13.3113C174.7 14.0313 175.74 15.6013 176.2 17.2613C176.66 18.9213 176.6 20.6713 176.48 22.3913C176.32 24.6713 176.03 26.9813 175.07 29.0513C174.87 29.4713 174.65 29.8913 174.36 30.2513C173.5 31.3213 172.03 31.8713 170.68 31.6313"
                    fill="#F2947F"
                />
                <path
                    d="M165.49 20.2213C166.53 21.9413 167.39 23.7713 168.02 25.6813C168.34 26.6413 168.6 27.6413 168.68 28.6513C167.39 27.6413 166.37 26.2713 165.82 24.7213C165.31 23.2813 165.21 21.7113 165.48 20.2113L165.49 20.2213Z"
                    fill="#CA7B6A"
                />
                <path
                    d="M177.28 26.9513C176.29 25.5113 175.49 23.9413 174.91 22.2913C174.72 21.7413 174.55 21.1713 174.51 20.5913C174.48 20.0513 174.57 19.5013 174.66 18.9613C174.85 17.8413 175.06 16.7313 175.27 15.6113C175.37 15.1013 175.47 14.5713 175.77 14.1413C176.32 13.3713 177.36 13.1413 178.3 13.1413C179.1 13.1413 179.94 13.2713 180.57 13.7613C181.18 14.2313 181.53 14.9713 181.76 15.7113C182.48 17.9713 182.38 20.4313 181.84 22.7313C181.53 24.0613 181.02 25.4213 179.96 26.2713C179.44 26.6913 178.82 26.9513 178.2 27.1913C176.83 27.7213 175.42 28.1713 173.99 28.5213"
                    fill="#F2947F"
                />
                <path
                    d="M176.2 17.2513C176.66 18.9113 176.6 20.6613 176.48 22.3813C176.41 23.3013 176.321 24.2313 176.181 25.1413C175.681 24.2213 175.251 23.2713 174.911 22.2913C174.721 21.7413 174.551 21.1713 174.521 20.5913C174.491 20.0513 174.581 19.5013 174.671 18.9613C174.861 17.8413 175.071 16.7313 175.281 15.6113C175.301 15.5113 175.32 15.4013 175.34 15.3013C175.72 15.9013 176.02 16.5713 176.21 17.2513H176.2Z"
                    fill="#CA7B6A"
                />
                <path
                    d="M173.99 28.5213L175.57 27.7613C175.53 27.8813 175.49 27.9913 175.45 28.1113C174.97 28.2513 174.48 28.4013 173.99 28.5213Z"
                    fill="#CA7B6A"
                />
                <path
                    d="M170.02 28.9113C169.2 27.6913 167.32 27.7713 166.21 26.8013C165.86 26.5013 165.6 26.0613 165.66 25.6013C165.71 25.2113 166 24.9013 166.31 24.6713C166.65 24.4313 167.1 24.2613 167.49 24.4213C167.78 24.5413 168.06 24.8313 168.34 24.7013C168.43 23.7613 169.16 22.9113 170.07 22.6813C170.19 22.2113 170.75 21.9713 171.23 22.0513C171.71 22.1313 172.11 22.4513 172.47 22.7813C172.57 22.8713 172.68 22.9813 172.7 23.1113C172.72 23.2413 172.61 23.4013 172.47 23.3713C172.92 23.7913 173.41 24.3113 173.36 24.9213C173.3 25.5913 172.61 26.0613 172.48 26.7213C172.34 27.4513 172.92 28.2513 172.58 28.9013C170.55 29.3013 168.46 28.8113 166.44 28.3213"
                    fill="#FFF48D"
                />
                <path
                    d="M168.68 31.7313C166.99 31.3313 165.24 30.8913 163.91 29.7913C163.34 29.3213 162.87 28.7513 162.34 28.2413C160.4 26.3813 157.63 25.4313 154.96 25.7113C154.81 25.7313 154.64 25.7513 154.53 25.8613C154.42 25.9713 154.39 26.1413 154.37 26.3013C154.2 27.9413 154.79 29.6113 155.77 30.9413C156.75 32.2713 158.09 33.2913 159.52 34.1313C160.68 34.8113 161.93 35.3813 163.26 35.6013C164.22 35.7613 165.2 35.7413 166.17 35.6713C167.06 35.6113 167.97 35.5013 168.74 35.0513C169.69 34.4913 170.27 33.4713 170.8 32.5113C170.86 32.4013 170.83 32.1713 170.71 32.2413"
                    fill="#CA7B6A"
                />
                <path
                    d="M174.63 33.5713C174.5 31.1713 174.8 28.7513 175.5 26.4513C175.6 26.1413 175.7 25.8213 175.91 25.5613C176.3 25.0613 176.96 24.8813 177.47 24.4913C178.21 23.9213 178.52 22.9713 178.88 22.1013C179.63 20.2813 180.85 18.5313 182.65 17.7313C182.73 17.7013 182.81 17.6613 182.89 17.6913C182.96 17.7113 183.01 17.7813 183.05 17.8413C184.01 19.2513 184.22 21.0713 183.93 22.7613C183.64 24.4413 182.89 26.0113 182.05 27.4913C180.95 29.4513 179.68 31.3213 178.27 33.0813C178 33.4213 177.72 33.7513 177.37 34.0113C177.01 34.2713 176.6 34.4413 176.18 34.5913C175.22 34.9313 174.23 35.1813 173.23 35.3213C173.06 35.3513 172.87 35.3613 172.72 35.2813C172.57 35.2013 172.5 34.9513 172.63 34.8413"
                    fill="#CA7B6A"
                />
                <path
                    d="M165.61 36.2313C163.96 35.2813 162.7 33.6713 162.11 31.8513C161.76 30.7813 161.68 29.5113 162.38 28.6313C162.56 28.4113 162.79 28.2213 163.06 28.1313C163.3 28.0613 163.55 28.0613 163.8 28.0913C165.13 28.2613 166.38 29.1613 167.69 28.8913C168.49 28.7213 169.13 28.1413 169.77 27.6213C170.94 26.6713 172.22 25.8813 173.59 25.2613C173.8 25.1613 174.03 25.0713 174.26 25.0813C174.85 25.1013 175.2 25.7313 175.41 26.2813C175.96 27.7513 176.27 29.3113 176.32 30.8913C176.35 31.6413 176.31 32.4113 176.08 33.1313C175.9 33.7013 175.6 34.2313 175.3 34.7513C175.11 35.0813 174.91 35.4213 174.62 35.6713C173.63 36.5213 171.83 36.9513 170.55 37.1013C168.83 37.3013 167.14 37.1013 165.61 36.2313Z"
                    fill="#CA7B6A"
                />
                <path
                    d="M170.989 27.2413C170.349 27.7613 169.709 28.3413 168.909 28.5113C168.779 28.5413 168.659 28.5413 168.529 28.5513C168.969 28.2913 169.369 27.9613 169.769 27.6313C170.939 26.6813 172.219 25.8913 173.589 25.2713C173.799 25.1713 174.029 25.0813 174.259 25.0913C174.289 25.0913 174.319 25.1013 174.349 25.1113C173.149 25.6913 172.019 26.4013 170.989 27.2413Z"
                    fill="#BA5242"
                />
                <path
                    d="M163.069 28.1413C163.289 28.0713 163.509 28.0713 163.739 28.1013C163.689 28.1513 163.649 28.2013 163.609 28.2513C162.909 29.1213 162.989 30.4013 163.339 31.4713C163.929 33.2913 165.179 34.8913 166.839 35.8413C168.369 36.7213 170.049 36.9213 171.779 36.7113C172.239 36.6613 172.769 36.5613 173.309 36.4313C172.409 36.7913 171.379 37.0013 170.559 37.1013C168.839 37.3013 167.149 37.1013 165.619 36.2313C163.969 35.2813 162.709 33.6713 162.119 31.8513C161.769 30.7813 161.689 29.5113 162.389 28.6313C162.569 28.4113 162.799 28.2213 163.069 28.1313V28.1413Z"
                    fill="#BA5242"
                />
                <path
                    d="M103.43 88.0413L94.7197 75.1913L101.15 88.9613L103.43 88.0413Z"
                    fill="#86CED3"
                />
                <path
                    d="M117.12 87.6213L130.31 79.4413L118.83 89.3913L117.12 87.6213Z"
                    fill="#86CED3"
                />
                <path
                    d="M122.85 56.8913L131.86 44.2513L125.11 57.8613L122.85 56.8913Z"
                    fill="#86CED3"
                />
                <path
                    d="M113.95 43.0713L113.33 40.9413C112.76 38.9613 112.33 36.9513 112.09 34.9013C111.77 32.0913 110.96 27.8613 108.94 27.8213C105.91 27.7613 103.42 28.9013 104.08 33.4213C104.73 37.9513 112.13 44.2913 112.13 44.2913C112.13 44.2913 112.8 44.3913 113.66 44.1113C114.52 43.8313 113.95 43.0613 113.95 43.0613V43.0713Z"
                    fill="#46A0A6"
                />
                <path
                    d="M107.399 33.0613C108.829 32.7613 110.339 31.9613 111.439 31.2813C110.939 29.4213 110.159 27.8613 108.939 27.8313C106.159 27.7813 103.829 28.7413 103.989 32.4213C104.839 32.9313 106.019 33.3513 107.399 33.0613Z"
                    fill="#14808D"
                />
                <path
                    d="M118.899 34.4213C119.369 31.9413 120.839 25.1313 123.829 25.5913C126.819 26.0513 129.079 27.5913 127.659 31.9413C126.239 36.2913 119.129 43.8413 119.129 43.8413C119.129 43.8413 118.449 43.8213 117.649 43.4013C117.649 43.4013 118.109 38.5813 118.889 34.4213H118.899Z"
                    fill="#14808D"
                />
                <path
                    d="M124.45 31.0113C123.09 30.4713 121.74 29.4213 120.77 28.5713C121.58 26.8313 122.62 25.4113 123.83 25.6013C126.58 26.0213 128.71 27.3713 127.93 30.9713C127.01 31.3213 125.77 31.5413 124.46 31.0113H124.45Z"
                    fill="#46A0A6"
                />
                <path
                    d="M98.3299 59.7513L96.1299 55.4513L98.9299 57.6013L98.3299 59.7513Z"
                    fill="#86CED3"
                />
                <path
                    d="M104.71 45.4913C104.37 45.0013 101.17 38.3513 101.17 38.3513L106.6 45.2413L104.71 45.4913Z"
                    fill="#86CED3"
                />
                <path
                    d="M106.03 108.171C104.73 107.901 103.59 107.281 102.81 105.911C102.04 104.551 101.82 102.951 101.62 101.401C99.5898 85.8512 93.5898 65.7812 100.5 50.7712C104.78 41.5412 113.42 37.8413 122.85 42.1713C124.22 42.8013 125.56 43.6312 126.42 44.8712C130.54 50.8012 128.19 62.9613 127.14 69.5113C124.79 84.1213 118.25 95.8613 110.76 108.321C109.21 108.321 107.52 108.471 106.03 108.161V108.171Z"
                    fill="#46A0A6"
                />
                <path
                    d="M114.97 99.5113C113.59 99.2513 112.18 99.0013 110.76 99.1113C109.16 99.2313 107.72 99.8013 106.34 100.361C104.81 100.981 103.27 101.591 101.74 102.211C101.93 103.501 102.18 104.781 102.82 105.911C103.6 107.281 104.74 107.901 106.04 108.171C107.53 108.481 109.22 108.331 110.77 108.331C112.52 105.421 114.21 102.551 115.81 99.6813C115.53 99.6313 115.26 99.5713 114.98 99.5213L114.97 99.5113Z"
                    fill="#0B5D68"
                />
                <path
                    d="M104.36 66.5013C104.835 66.5013 105.22 66.1162 105.22 65.6413C105.22 65.1663 104.835 64.7813 104.36 64.7813C103.885 64.7813 103.5 65.1663 103.5 65.6413C103.5 66.1162 103.885 66.5013 104.36 66.5013Z"
                    fill="#332421"
                />
                <path
                    d="M117.219 72.6813C117.694 72.6813 118.079 72.2962 118.079 71.8213C118.079 71.3463 117.694 70.9613 117.219 70.9613C116.744 70.9613 116.359 71.3463 116.359 71.8213C116.359 72.2962 116.744 72.6813 117.219 72.6813Z"
                    fill="#332421"
                />
                <path
                    d="M121.58 56.3113C122.055 56.3113 122.44 55.9262 122.44 55.4513C122.44 54.9763 122.055 54.5913 121.58 54.5913C121.105 54.5913 120.72 54.9763 120.72 55.4513C120.72 55.9262 121.105 56.3113 121.58 56.3113Z"
                    fill="#332421"
                />
                <path
                    d="M112.089 58.6213C112.653 58.6213 113.109 58.1646 113.109 57.6013C113.109 57.0379 112.653 56.5813 112.089 56.5813C111.526 56.5813 111.069 57.0379 111.069 57.6013C111.069 58.1646 111.526 58.6213 112.089 58.6213Z"
                    fill="#332421"
                />
                <path
                    d="M106.01 48.1513C106.574 48.1513 107.03 47.6946 107.03 47.1313C107.03 46.5679 106.574 46.1113 106.01 46.1113C105.447 46.1113 104.99 46.5679 104.99 47.1313C104.99 47.6946 105.447 48.1513 106.01 48.1513Z"
                    fill="#332421"
                />
                <path
                    d="M108.95 83.1413C109.513 83.1413 109.97 82.6846 109.97 82.1213C109.97 81.5579 109.513 81.1013 108.95 81.1013C108.386 81.1013 107.93 81.5579 107.93 82.1213C107.93 82.6846 108.386 83.1413 108.95 83.1413Z"
                    fill="#332421"
                />
                <path
                    d="M113.93 95.5813C114.494 95.5813 114.95 95.1246 114.95 94.5613C114.95 93.9979 114.494 93.5413 113.93 93.5413C113.367 93.5413 112.91 93.9979 112.91 94.5613C112.91 95.1246 113.367 95.5813 113.93 95.5813Z"
                    fill="#332421"
                />
                <path
                    d="M35.2102 49.6213L34.2602 47.6213C33.3802 45.7613 32.6302 43.8413 32.0702 41.8613C31.3002 39.1313 29.8202 35.0913 27.8302 35.3813C24.8302 35.8113 22.5602 37.3213 23.9302 41.6913C25.3002 46.0613 33.6102 51.1313 33.6102 51.1313C33.6102 51.1313 34.2902 51.1313 35.0902 50.7013C35.8902 50.2713 35.2102 49.6213 35.2102 49.6213Z"
                    fill="#14808D"
                />
                <path
                    d="M27.1402 40.7813C28.5102 40.2613 29.8701 39.2313 30.8501 38.3813C30.0601 36.6313 29.0402 35.2013 27.8302 35.3813C25.0702 35.7713 22.9402 37.0913 23.6702 40.7013C24.5902 41.0613 25.8202 41.2913 27.1402 40.7813Z"
                    fill="#46A0A6"
                />
                <path
                    d="M38.7105 40.2913C38.7805 37.7613 39.1405 30.8113 42.1705 30.7913C45.2005 30.7713 47.6705 31.9313 46.9705 36.4513C46.2705 40.9713 40.4505 49.5613 40.4505 49.5613C40.4505 49.5613 39.7805 49.6512 38.9205 49.3612C38.9205 49.3612 38.6005 44.5313 38.7105 40.2913Z"
                    fill="#14808D"
                />
                <path
                    d="M43.6509 36.0313C42.2209 35.7113 40.7209 34.9013 39.6309 34.2113C40.1509 32.3613 40.9508 30.8013 42.1708 30.7913C44.9508 30.7713 47.2709 31.7613 47.0709 35.4313C46.2109 35.9313 45.0309 36.3313 43.6509 36.0313Z"
                    fill="#46A0A6"
                />
                <path
                    d="M154.381 65.6413L152.931 61.0313L155.331 63.6213L154.381 65.6413Z"
                    fill="#86CED3"
                />
                <path
                    d="M164.75 50.0113L163.311 45.4013L165.7 47.9913L164.75 50.0113Z"
                    fill="#86CED3"
                />
                <path
                    d="M178.431 64.8413L184.481 61.9513L178.051 67.0213L178.431 64.8413Z"
                    fill="#0B5D68"
                />
                <path
                    d="M179.421 53.2213L183.171 51.7913L179.591 55.2113L179.421 53.2213Z"
                    fill="#0B5D68"
                />
                <path
                    d="M153.49 80.4813C153.89 74.0613 152.92 65.7113 155.1 59.6713C155.75 57.8613 156.84 56.2512 158.04 54.7412C161.01 51.0012 164.74 47.7812 169.13 45.9012C169.78 45.6212 170.45 45.3712 171.16 45.3012C174.38 44.9612 178.87 48.0213 180.25 50.7213C183.42 56.9313 180.14 65.9113 176.04 70.9513C174.64 72.6713 159.01 85.6412 157.45 84.1812L153.5 80.4813H153.49Z"
                    fill="#46A0A6"
                />
                <path
                    d="M156.081 58.2613C156.021 58.1513 155.95 58.0413 155.88 57.9213C155.58 58.4813 155.311 59.0613 155.091 59.6613C152.911 65.7013 153.88 74.0513 153.48 80.4713C153.48 80.4713 157.43 84.1613 157.43 84.1713C157.71 84.4413 158.471 84.2213 159.521 83.6813C159.971 75.0513 160.23 65.5013 156.07 58.2613H156.081Z"
                    fill="#0B5D68"
                />
                <path
                    d="M151.181 72.5313L164.371 64.3613L152.901 74.3113L151.181 72.5313Z"
                    fill="#86CED3"
                />
                <path
                    d="M144.961 67.2713L134.141 56.1513L146.321 65.2213L144.961 67.2713Z"
                    fill="#86CED3"
                />
                <path
                    d="M170.13 57.0113C170.605 57.0113 170.99 56.6262 170.99 56.1513C170.99 55.6763 170.605 55.2913 170.13 55.2913C169.656 55.2913 169.271 55.6763 169.271 56.1513C169.271 56.6262 169.656 57.0113 170.13 57.0113Z"
                    fill="#332421"
                />
                <path
                    d="M173.871 66.9713C174.346 66.9713 174.731 66.5862 174.731 66.1113C174.731 65.6363 174.346 65.2513 173.871 65.2513C173.396 65.2513 173.011 65.6363 173.011 66.1113C173.011 66.5862 173.396 66.9713 173.871 66.9713Z"
                    fill="#332421"
                />
                <path
                    d="M163.26 68.0213C163.824 68.0213 164.28 67.5646 164.28 67.0013C164.28 66.4379 163.824 65.9813 163.26 65.9813C162.697 65.9813 162.24 66.4379 162.24 67.0013C162.24 67.5646 162.697 68.0213 163.26 68.0213Z"
                    fill="#332421"
                />
                <path
                    d="M158.441 59.8113C159.004 59.8113 159.461 59.3546 159.461 58.7913C159.461 58.2279 159.004 57.7713 158.441 57.7713C157.878 57.7713 157.421 58.2279 157.421 58.7913C157.421 59.3546 157.878 59.8113 158.441 59.8113Z"
                    fill="#332421"
                />
                <path
                    d="M39.72 61.8913L24.29 57.2713L38.5701 63.8313L39.72 61.8913Z"
                    fill="#86CED3"
                />
                <path
                    d="M47.8398 74.2813L28.2197 68.1513L46.6898 76.2213L47.8398 74.2813Z"
                    fill="#86CED3"
                />
                <path
                    d="M47.9902 57.3313C48.8402 54.2613 49.4502 51.8413 49.3802 51.4613C49.3102 51.0513 48.3502 53.7013 47.1602 57.2613C47.4402 57.2613 47.7202 57.2813 47.9902 57.3313Z"
                    fill="#14808D"
                />
                <path
                    d="M55.17 86.1413C52.65 84.5113 50.14 82.8713 47.62 81.2413C42.94 78.2013 39.28 76.3113 36.22 71.4513C33.2 66.6613 31.33 61.2013 29.99 55.7313C29.24 52.6813 30.21 48.1313 32.94 46.1313C36.79 43.3113 41.62 47.5613 44.63 50.3813C53.25 58.4713 55.76 72.6113 58.64 83.6813L55.17 86.1513V86.1413Z"
                    fill="#14808D"
                />
                <path
                    d="M64.6901 61.2213L56.3701 54.0313L62.5101 57.5713L64.6901 61.2213Z"
                    fill="#86CED3"
                />
                <path
                    d="M66.3601 41.3013C65.7501 40.6713 59.6201 35.1213 59.6201 35.1213L69.0101 40.3413L66.3601 41.2913V41.3013Z"
                    fill="#86CED3"
                />
                <path
                    d="M84.4805 50.3413L88.7605 46.1313L84.9305 52.5113L84.4805 50.3413Z"
                    fill="#86CED3"
                />
                <path
                    d="M81.21 39.1513L84.1799 36.4613L82.08 40.9513L81.21 39.1513Z"
                    fill="#86CED3"
                />
                <path
                    d="M72.6204 35.5713L69.8604 31.0613C69.8604 31.0613 69.9504 21.5713 66.9304 21.3913C63.9104 21.2013 61.3705 22.2313 61.8305 26.7813C62.2905 31.3313 70.7504 36.7313 70.7504 36.7313C70.7504 36.7313 71.4104 36.8613 72.2904 36.6113C73.1704 36.3613 72.6304 35.5713 72.6304 35.5713H72.6204Z"
                    fill="#14808D"
                />
                <path
                    d="M63.0298 26.2713C64.1898 26.6013 65.2898 26.4813 66.3798 25.9613C67.3698 25.4913 68.2498 24.8113 69.0498 24.0413C68.6198 22.5813 67.9498 21.4513 66.9298 21.3913C64.1198 21.2113 61.7398 22.0913 61.7798 25.8613C62.1898 26.0013 62.6098 26.1513 63.0298 26.2713Z"
                    fill="#46A0A6"
                />
                <path
                    d="M73.2006 38.1413C73.2006 38.1413 72.2105 26.2813 72.9105 24.1313C73.6105 21.9813 74.1005 20.4613 74.1005 20.4613C74.1005 20.4613 77.6206 19.4013 79.0306 20.2113C80.4406 21.0213 82.6805 22.5413 82.6805 22.5413C82.6805 22.5413 82.7805 25.4813 80.6105 28.9113C78.4405 32.3413 76.5105 31.3113 76.6005 33.8613C76.6905 36.4113 75.4305 37.4513 75.4305 37.4513L73.2006 38.1413Z"
                    fill="#14808D"
                />
                <path
                    d="M75.6399 21.6713L76.7899 16.4913C76.7899 16.4913 79.9499 13.8213 81.2299 15.0313C82.5099 16.2413 84.4399 17.9013 83.2699 19.8713C82.0999 21.8413 80.1299 24.1013 80.1299 24.1013L75.6299 21.6713H75.6399Z"
                    fill="#46A0A6"
                />
                <path
                    d="M89.3911 20.7413C92.1511 20.3413 94.6911 18.5713 96.0011 16.1013C96.3911 15.3613 96.6511 14.3613 96.0611 13.7513C95.5511 13.2313 94.6711 13.3013 94.0811 12.8713C93.7011 12.6013 93.4911 12.1613 93.2611 11.7613C92.6311 10.6513 91.741 9.69126 90.671 9.00126C90.151 8.67126 89.531 8.39126 88.931 8.52126C88.441 8.62126 88.041 8.97127 87.681 9.32127C86.001 10.9213 84.5311 12.7413 83.3211 14.7113C82.5511 15.9613 81.8711 17.3113 81.7011 18.7813C81.5311 20.2413 81.941 21.8313 83.051 22.8013"
                    fill="#F2947F"
                />
                <path
                    d="M79.6403 18.1713C82.0803 17.8113 84.3402 16.3613 85.6702 14.2813C87.0002 12.2013 87.3903 9.55126 86.7103 7.18126C86.5103 6.47126 86.2102 5.78127 86.1002 5.05127C85.9802 4.25127 86.1002 3.40127 85.7902 2.64127C85.6402 2.26127 85.3903 1.93127 85.1503 1.60127C85.0803 1.51127 85.0202 1.42126 84.9202 1.37126C84.7902 1.30126 84.6402 1.30127 84.4902 1.30127C83.9002 1.32127 83.3003 1.33127 82.7103 1.35127C82.2003 1.36127 81.6802 1.38126 81.1802 1.48126C79.4902 1.82126 78.1102 3.11126 77.2902 4.62126C76.4602 6.13126 76.1203 7.85127 75.8403 9.55127C75.4803 11.8013 75.2303 14.1213 75.6903 16.3613C75.7803 16.8213 75.9102 17.2713 76.1102 17.6913C76.7002 18.9313 78.0103 19.8013 79.3803 19.8713"
                    fill="#F2947F"
                />
                <path
                    d="M87.0303 9.97127C85.6203 11.4113 84.3702 12.9913 83.3102 14.7113C82.7802 15.5813 82.3002 16.4913 81.9902 17.4513C83.4802 16.7613 84.7802 15.6613 85.6702 14.2813C86.5002 13.0013 86.9603 11.4913 87.0303 9.97127Z"
                    fill="#CA7B6A"
                />
                <path
                    d="M74.0204 13.8213C75.3204 12.6513 76.4504 11.3013 77.3904 9.83127C77.7004 9.34128 78.0004 8.82127 78.1604 8.26127C78.3204 7.74127 78.3504 7.19127 78.3904 6.64127C78.4604 5.51127 78.5104 4.37128 78.5604 3.24128C78.5804 2.72128 78.6004 2.18127 78.4104 1.70127C78.0604 0.821271 77.0904 0.361268 76.1704 0.151268C75.3904 -0.0287325 74.5504 -0.0987307 73.8204 0.231269C73.1204 0.551269 72.6104 1.19128 72.2104 1.85128C70.9904 3.88128 70.5204 6.30127 70.5304 8.67127C70.5304 10.0313 70.7104 11.4713 71.5504 12.5413C71.9604 13.0613 72.5104 13.4613 73.0604 13.8413C74.2704 14.6713 75.5404 15.4313 76.8504 16.1013"
                    fill="#F2947F"
                />
                <path
                    d="M77.291 4.63127C76.461 6.14127 76.121 7.86126 75.841 9.56126C75.691 10.4713 75.571 11.4013 75.501 12.3213C76.191 11.5413 76.831 10.7113 77.391 9.83127C77.701 9.34127 78.0009 8.82126 78.1609 8.26126C78.3209 7.74126 78.351 7.19127 78.391 6.64127C78.461 5.51127 78.511 4.37127 78.561 3.24127C78.561 3.13127 78.561 3.03126 78.571 2.92126C78.061 3.42126 77.621 4.00126 77.281 4.62126L77.291 4.63127Z"
                    fill="#CA7B6A"
                />
                <path
                    d="M76.861 16.1113L75.501 15.0113C75.511 15.1313 75.521 15.2513 75.531 15.3713C75.971 15.6213 76.411 15.8713 76.861 16.1013V16.1113Z"
                    fill="#CA7B6A"
                />
                <path
                    d="M79.7505 16.9013C81.0705 16.2513 82.6605 17.2413 84.1005 16.9413C84.5505 16.8513 85.0005 16.6013 85.1705 16.1713C85.3105 15.8113 85.2205 15.3913 85.0605 15.0413C84.8805 14.6613 84.5705 14.2913 84.1605 14.2413C83.8505 14.2013 83.4605 14.3213 83.2805 14.0713C83.6705 13.2113 83.4505 12.1113 82.7705 11.4613C82.9005 10.9913 82.5305 10.5013 82.0705 10.3413C81.6105 10.1813 81.1105 10.2613 80.6405 10.3613C80.5105 10.3913 80.3605 10.4313 80.2805 10.5313C80.2005 10.6313 80.2205 10.8313 80.3505 10.8713C79.7505 11.0113 79.0705 11.2213 78.8105 11.7813C78.5305 12.3913 78.9005 13.1413 78.6805 13.7813C78.4405 14.4813 77.5405 14.8913 77.5205 15.6313C79.0905 16.9813 81.1505 17.5813 83.1405 18.1513"
                    fill="#FFF48D"
                />
                <path
                    d="M81.2902 20.4513C83.0202 20.4513 84.8302 20.4213 86.3802 19.6513C87.0402 19.3213 87.6302 18.8713 88.2602 18.5013C90.5702 17.1313 93.4902 16.8413 96.0202 17.7313C96.1602 17.7813 96.3202 17.8413 96.4002 17.9713C96.4902 18.1013 96.4802 18.2813 96.4602 18.4313C96.2502 20.0713 95.2902 21.5513 94.0302 22.6213C92.7702 23.6913 91.2302 24.3813 89.6502 24.8713C88.3602 25.2713 87.0202 25.5413 85.6702 25.4513C84.7002 25.3913 83.7502 25.1413 82.8202 24.8513C81.9702 24.5913 81.1002 24.2813 80.4602 23.6613C79.6602 22.8913 79.3302 21.7813 79.0302 20.7113C78.9902 20.5813 79.0802 20.3713 79.1802 20.4613"
                    fill="#CA7B6A"
                />
                <path
                    d="M75.0806 20.8813C75.7506 18.5713 76.0205 16.1413 75.8605 13.7413C75.8405 13.4113 75.8105 13.0813 75.6705 12.7813C75.4005 12.2113 74.8006 11.8713 74.4006 11.3813C73.8106 10.6513 73.7206 9.66127 73.5706 8.73127C73.2506 6.78127 72.4706 4.80127 70.9006 3.61127C70.8306 3.56127 70.7605 3.51128 70.6805 3.52128C70.6105 3.52128 70.5405 3.58128 70.4905 3.63128C69.2305 4.78128 68.6106 6.51128 68.5106 8.21128C68.4106 9.91128 68.7806 11.6113 69.2606 13.2513C69.8906 15.4113 70.6905 17.5313 71.6605 19.5613C71.8505 19.9513 72.0406 20.3413 72.3206 20.6713C72.6106 21.0113 72.9705 21.2713 73.3505 21.5113C74.2005 22.0613 75.1105 22.5313 76.0605 22.9013C76.2205 22.9613 76.4006 23.0313 76.5706 22.9813C76.7406 22.9313 76.8606 22.7113 76.7606 22.5713"
                    fill="#CA7B6A"
                />
                <path
                    d="M83.2508 25.5313C85.0808 24.9813 86.6708 23.7113 87.6608 22.0713C88.2408 21.1113 88.6208 19.8913 88.1308 18.8813C88.0108 18.6213 87.8308 18.3913 87.5808 18.2413C87.3708 18.1113 87.1208 18.0613 86.8708 18.0413C85.5408 17.9013 84.1109 18.4913 82.9009 17.9313C82.1609 17.5813 81.6708 16.8713 81.1708 16.2213C80.2508 15.0313 79.1808 13.9713 77.9908 13.0513C77.8108 12.9113 77.6108 12.7713 77.3808 12.7213C76.8008 12.6113 76.3108 13.1313 75.9908 13.6213C75.1208 14.9313 74.4608 16.3813 74.0508 17.8913C73.8508 18.6213 73.7109 19.3713 73.7709 20.1313C73.8209 20.7313 73.9908 21.3113 74.1608 21.8913C74.2708 22.2613 74.3808 22.6313 74.6108 22.9413C75.3808 23.9913 77.0308 24.8213 78.2508 25.2613C79.8808 25.8513 81.5708 26.0513 83.2608 25.5413L83.2508 25.5313Z"
                    fill="#CA7B6A"
                />
                <path
                    d="M80.0703 15.5513C80.5703 16.2013 81.0602 16.9113 81.8002 17.2613C81.9202 17.3213 82.0402 17.3513 82.1602 17.3913C81.7902 17.0413 81.4702 16.6213 81.1602 16.2113C80.2402 15.0213 79.1702 13.9613 77.9802 13.0413C77.8002 12.9013 77.6002 12.7613 77.3702 12.7113C77.3402 12.7113 77.3103 12.7113 77.2803 12.7113C78.3103 13.5513 79.2503 14.5013 80.0703 15.5513Z"
                    fill="#BA5242"
                />
                <path
                    d="M87.5805 18.2313C87.3905 18.1113 87.1605 18.0613 86.9405 18.0413C86.9805 18.1013 87.0105 18.1613 87.0405 18.2213C87.5305 19.2313 87.1505 20.4513 86.5705 21.4113C85.5805 23.0413 83.9905 24.3213 82.1605 24.8713C80.4705 25.3813 78.7905 25.1813 77.1505 24.5913C76.7105 24.4313 76.2205 24.2213 75.7305 23.9713C76.5205 24.5313 77.4705 24.9713 78.2505 25.2513C79.8805 25.8413 81.5705 26.0413 83.2605 25.5313C85.0905 24.9813 86.6805 23.7113 87.6705 22.0713C88.2505 21.1113 88.6305 19.8913 88.1405 18.8813C88.0205 18.6213 87.8405 18.3913 87.5905 18.2413L87.5805 18.2313Z"
                    fill="#BA5242"
                />
                <path
                    d="M66.9 73.9513C65.95 73.5613 64.05 66.2513 63.6 65.0113C62.3 61.4613 61.13 57.8013 61 53.9913C60.81 48.3113 64.59 37.7713 70.02 34.9213C73.22 33.2513 77.99 34.0413 80.8 36.2013C83.56 38.3313 84.23 42.0313 85.08 45.2113C85.76 47.7613 86.19 50.4413 85.7 53.0313C84.95 57.0413 82.23 60.7513 80.16 64.1913C79.47 65.3313 72.72 76.2813 71.92 75.9513L66.9 73.9313V73.9513Z"
                    fill="#46A0A6"
                />
                <path
                    d="M71.9297 75.9713C72.2997 76.1013 73.8797 73.9313 75.5697 71.4113C74.2897 73.1013 72.9497 74.7913 71.9297 75.9713Z"
                    fill="#86CED3"
                />
                <path
                    d="M67.6797 70.6313C67.1597 70.4513 66.6198 70.2613 66.0598 70.2613C65.7798 70.2613 65.4997 70.2613 65.2197 70.2813C65.8197 72.1213 66.4598 73.7613 66.8998 73.9413L71.9197 75.9613C72.1897 76.0713 73.1097 74.9313 74.2397 73.3313C72.3097 72.3713 70.1397 71.5013 67.6797 70.6313Z"
                    fill="#14808D"
                />
                <path
                    d="M73.6002 46.1113C74.0752 46.1113 74.4603 45.7262 74.4603 45.2513C74.4603 44.7763 74.0752 44.3913 73.6002 44.3913C73.1253 44.3913 72.7402 44.7763 72.7402 45.2513C72.7402 45.7262 73.1253 46.1113 73.6002 46.1113Z"
                    fill="#332421"
                />
                <path
                    d="M78.1198 39.5013C78.5947 39.5013 78.9797 39.1162 78.9797 38.6413C78.9797 38.1663 78.5947 37.7813 78.1198 37.7813C77.6448 37.7813 77.2598 38.1663 77.2598 38.6413C77.2598 39.1162 77.6448 39.5013 78.1198 39.5013Z"
                    fill="#332421"
                />
                <path
                    d="M84.3701 90.7313L88.0401 87.1713L84.3701 92.9513V90.7313Z"
                    fill="#0B5D68"
                />
                <path
                    d="M52.6206 104.731C52.6206 104.731 42.2106 98.9913 41.5506 98.9713C40.8906 98.9513 53.9406 106.961 53.9406 106.961L52.6306 104.741L52.6206 104.731Z"
                    fill="#86CED3"
                />
                <path
                    d="M65.6001 88.8712C65.6001 88.8712 59.3601 69.0113 58.5401 68.1713C57.7201 67.3413 64.6501 89.9312 64.6501 89.9312L65.6001 88.8612V88.8712Z"
                    fill="#86CED3"
                />
                <path
                    d="M77.7407 84.8213C77.7407 84.8213 82.7907 74.0613 82.7607 73.3913C82.7307 72.7213 76.1807 84.1213 76.1807 84.1213L77.7407 84.8313V84.8213Z"
                    fill="#0B5D68"
                />
                <path
                    d="M61.6007 126.931C61.6007 126.931 49.8808 122.021 49.6508 122.651C49.4208 123.281 61.2808 128.221 61.2808 128.221L61.6007 126.941V126.931Z"
                    fill="#86CED3"
                />
                <path
                    d="M54.1605 93.5313L41.5205 83.5613L52.3805 94.9213L54.1605 93.5313Z"
                    fill="#86CED3"
                />
                <path
                    d="M85.7207 146.441C83.5007 144.981 80.6407 144.941 78.2307 143.811C67.8007 138.911 56.2908 125.381 51.5008 115.161C46.3808 104.251 45.2907 72.6613 64.0407 72.6313C64.6007 72.6313 65.1307 72.8213 65.6607 73.0013C77.4507 77.1513 82.4807 81.4613 86.3707 93.8113C91.9307 111.421 86.0408 128.641 85.7108 146.431L85.7207 146.441Z"
                    fill="#14808D"
                />
                <path
                    d="M72.9805 140.701C74.7405 141.941 76.5005 143.001 78.2405 143.811C80.6405 144.941 83.5105 144.991 85.7305 146.441C85.9205 135.971 88.0405 125.701 88.6205 115.431C85.6805 117.581 83.2605 120.411 81.5805 123.711L73.5305 139.541C73.3405 139.921 73.1605 140.311 72.9905 140.691L72.9805 140.701Z"
                    fill="#0B5D68"
                />
                <path
                    d="M74.6802 88.0113C75.2435 88.0113 75.7002 87.5546 75.7002 86.9913C75.7002 86.4279 75.2435 85.9713 74.6802 85.9713C74.1168 85.9713 73.6602 86.4279 73.6602 86.9913C73.6602 87.5546 74.1168 88.0113 74.6802 88.0113Z"
                    fill="#332421"
                />
                <path
                    d="M72.6401 121.861C73.2035 121.861 73.6601 121.405 73.6601 120.841C73.6601 120.278 73.2035 119.821 72.6401 119.821C72.0768 119.821 71.6201 120.278 71.6201 120.841C71.6201 121.405 72.0768 121.861 72.6401 121.861Z"
                    fill="#332421"
                />
                <path
                    d="M81.4507 115.371C82.014 115.371 82.4706 114.915 82.4706 114.351C82.4706 113.788 82.014 113.331 81.4507 113.331C80.8874 113.331 80.4307 113.788 80.4307 114.351C80.4307 114.915 80.8874 115.371 81.4507 115.371Z"
                    fill="#332421"
                />
                <path
                    d="M67.0104 98.5213C67.4853 98.5213 67.8704 98.1362 67.8704 97.6613C67.8704 97.1863 67.4853 96.8013 67.0104 96.8013C66.5354 96.8013 66.1504 97.1863 66.1504 97.6613C66.1504 98.1362 66.5354 98.5213 67.0104 98.5213Z"
                    fill="#332421"
                />
                <path
                    d="M59.9508 111.281C60.4258 111.281 60.8108 110.896 60.8108 110.421C60.8108 109.946 60.4258 109.561 59.9508 109.561C59.4758 109.561 59.0908 109.946 59.0908 110.421C59.0908 110.896 59.4758 111.281 59.9508 111.281Z"
                    fill="#332421"
                />
                <path
                    d="M79.7203 103.161C80.1953 103.161 80.5804 102.776 80.5804 102.301C80.5804 101.826 80.1953 101.441 79.7203 101.441C79.2454 101.441 78.8604 101.826 78.8604 102.301C78.8604 102.776 79.2454 103.161 79.7203 103.161Z"
                    fill="#332421"
                />
                <path
                    d="M78.2702 132.961C78.7452 132.961 79.1302 132.576 79.1302 132.101C79.1302 131.626 78.7452 131.241 78.2702 131.241C77.7952 131.241 77.4102 131.626 77.4102 132.101C77.4102 132.576 77.7952 132.961 78.2702 132.961Z"
                    fill="#332421"
                />
                <path
                    d="M59.9508 85.2713C60.4258 85.2713 60.8108 84.8862 60.8108 84.4113C60.8108 83.9363 60.4258 83.5513 59.9508 83.5513C59.4758 83.5513 59.0908 83.9363 59.0908 84.4113C59.0908 84.8862 59.4758 85.2713 59.9508 85.2713Z"
                    fill="#332421"
                />
                <path
                    d="M72.8903 70.1913C73.3652 70.1913 73.7502 69.8062 73.7502 69.3313C73.7502 68.8563 73.3652 68.4713 72.8903 68.4713C72.4153 68.4713 72.0303 68.8563 72.0303 69.3313C72.0303 69.8062 72.4153 70.1913 72.8903 70.1913Z"
                    fill="#332421"
                />
                <path
                    d="M80.6998 54.0413C81.1748 54.0413 81.5598 53.6562 81.5598 53.1813C81.5598 52.7063 81.1748 52.3213 80.6998 52.3213C80.2249 52.3213 79.8398 52.7063 79.8398 53.1813C79.8398 53.6562 80.2249 54.0413 80.6998 54.0413Z"
                    fill="#332421"
                />
                <path
                    d="M34.6198 54.8913C35.0947 54.8913 35.4797 54.5062 35.4797 54.0313C35.4797 53.5563 35.0947 53.1713 34.6198 53.1713C34.1448 53.1713 33.7598 53.5563 33.7598 54.0313C33.7598 54.5062 34.1448 54.8913 34.6198 54.8913Z"
                    fill="#332421"
                />
                <path
                    d="M38.6901 66.9713C39.165 66.9713 39.55 66.5862 39.55 66.1113C39.55 65.6363 39.165 65.2513 38.6901 65.2513C38.2151 65.2513 37.8301 65.6363 37.8301 66.1113C37.8301 66.5862 38.2151 66.9713 38.6901 66.9713Z"
                    fill="#332421"
                />
                <path
                    d="M47.2604 66.5013C47.7353 66.5013 48.1204 66.1162 48.1204 65.6413C48.1204 65.1663 47.7353 64.7813 47.2604 64.7813C46.7854 64.7813 46.4004 65.1663 46.4004 65.6413C46.4004 66.1162 46.7854 66.5013 47.2604 66.5013Z"
                    fill="#332421"
                />
                <path
                    d="M71.6206 60.4613C72.1839 60.4613 72.6406 60.0046 72.6406 59.4413C72.6406 58.8779 72.1839 58.4213 71.6206 58.4213C71.0573 58.4213 70.6006 58.8779 70.6006 59.4413C70.6006 60.0046 71.0573 60.4613 71.6206 60.4613Z"
                    fill="#332421"
                />
                <path
                    d="M63.6703 52.9613C64.2337 52.9613 64.6904 52.5046 64.6904 51.9413C64.6904 51.3779 64.2337 50.9213 63.6703 50.9213C63.107 50.9213 62.6504 51.3779 62.6504 51.9413C62.6504 52.5046 63.107 52.9613 63.6703 52.9613Z"
                    fill="#332421"
                />
                <path
                    d="M48.2798 73.6613C48.8431 73.6613 49.2997 73.2046 49.2997 72.6413C49.2997 72.078 48.8431 71.6213 48.2798 71.6213C47.7165 71.6213 47.2598 72.078 47.2598 72.6413C47.2598 73.2046 47.7165 73.6613 48.2798 73.6613Z"
                    fill="#332421"
                />
                <path
                    d="M41.52 58.3513C42.0833 58.3513 42.54 57.8946 42.54 57.3313C42.54 56.7679 42.0833 56.3113 41.52 56.3113C40.9567 56.3113 40.5 56.7679 40.5 57.3313C40.5 57.8946 40.9567 58.3513 41.52 58.3513Z"
                    fill="#332421"
                />
                <path
                    d="M86.3898 167.121L75.2598 157.381L85.6697 168.371L86.3898 167.121Z"
                    fill="#86CED3"
                />
                <path
                    d="M85.2899 169.511L84.9499 168.521C84.9099 168.401 84.9499 168.261 85.0499 168.181L86.3799 167.121C86.5099 167.021 86.6899 167.031 86.7999 167.151L87.6599 168.081L85.2899 169.511Z"
                    fill="#332421"
                />
                <path
                    d="M147.66 50.8513L145.54 46.0013C145.54 46.0013 146.93 36.6213 143.96 36.0113C140.99 35.4013 138.34 36.0813 138.17 40.6513C138 45.2213 145.65 51.7313 145.65 51.7313C145.65 51.7313 146.29 51.9513 147.19 51.8213C148.09 51.6913 147.67 50.8413 147.67 50.8413L147.66 50.8513Z"
                    fill="#46A0A6"
                />
                <path
                    d="M141.5 40.8813C142.96 40.8413 144.59 40.3313 145.8 39.8613C145.64 37.9413 145.16 36.2613 143.96 36.0213C141.23 35.4713 138.77 36.0013 138.26 39.6413C139.01 40.2913 140.09 40.9213 141.5 40.8813Z"
                    fill="#14808D"
                />
                <path
                    d="M152.2 84.6213C150.54 82.6413 148.88 80.6713 147.22 78.6913C144.14 75.0213 141.6 72.5412 140.3 67.7712C139.02 63.0712 138.85 58.1112 139.12 53.2712C139.27 50.5712 141.21 47.0712 143.96 46.1112C147.84 44.7512 150.74 49.4613 152.51 52.5413C157.57 61.3613 156.09 73.6212 155.68 83.4612L152.2 84.6213Z"
                    fill="#14808D"
                />
                <path
                    d="M147.7 79.2613C149.2 81.0513 150.7 82.8313 152.2 84.6213L155.68 83.4613C155.79 80.8513 155.97 78.0713 156.09 75.2313C153.16 75.4713 150.28 77.0813 147.71 79.2613H147.7Z"
                    fill="#0B5D68"
                />
                <path
                    d="M144.22 91.8713C144.22 91.8713 140.73 79.0813 140.77 78.5213C140.81 77.9613 145.14 89.6613 145.14 89.6613L144.22 91.8713Z"
                    fill="#86CED3"
                />
                <path
                    d="M136.261 104.021C136.261 104.021 132.451 90.2013 132.491 89.6413C132.531 89.0813 136.861 100.781 136.861 100.781L136.261 104.031V104.021Z"
                    fill="#86CED3"
                />
                <path
                    d="M118.92 112.161C118.92 112.161 125.5 102.891 125.85 103.311C126.2 103.731 119.55 113.081 119.55 113.081L118.92 112.161Z"
                    fill="#86CED3"
                />
                <path
                    d="M136.5 156.551C136.5 156.551 144.26 144.431 144.61 144.841C144.96 145.251 137.13 157.471 137.13 157.471L136.5 156.551Z"
                    fill="#86CED3"
                />
                <path
                    d="M157.11 100.901L170.05 92.3313L158.87 102.621L157.11 100.901Z"
                    fill="#0B5D68"
                />
                <path
                    d="M151.54 120.471L165.68 114.071L153.01 122.451L151.54 120.471Z"
                    fill="#0B5D68"
                />
                <path
                    d="M130.771 135.011C131.651 127.471 132.64 119.941 133.19 112.361C133.71 105.101 134.2 97.5613 137.72 91.0213C141.26 84.4313 150.61 73.0813 159.22 77.3013C164.41 79.8513 165.161 86.5613 165.051 91.6913C164.621 110.681 153.98 127.971 138.48 138.541C136.21 140.091 133.49 140.591 131 141.441L130.781 135.011H130.771Z"
                    fill="#46A0A6"
                />
                <path
                    d="M137.46 120.821L132.85 116.571C132.29 122.731 131.5 128.871 130.78 135.011L131 141.441C133.49 140.591 136.2 140.091 138.48 138.541C140.63 137.081 142.67 135.481 144.62 133.771C143.7 128.801 141.2 124.251 137.47 120.811L137.46 120.821Z"
                    fill="#0B5D68"
                />
                <path
                    d="M99.6203 114.441C99.6203 114.441 93.2202 103.471 92.6602 103.821C92.1002 104.171 98.5903 115.271 98.5903 115.271L99.6203 114.441Z"
                    fill="#86CED3"
                />
                <path
                    d="M89.9698 183.731C89.9698 183.731 79.7198 164.821 78.1098 148.471C77.5698 143.021 77.5798 137.531 80.0598 132.641L88.1098 116.811C90.7898 111.541 95.3698 107.471 100.93 105.441C109.16 102.431 118.38 104.261 124.83 110.201L131.64 116.471C135.56 120.081 138.14 124.931 138.93 130.211L142.06 151.081C142.15 151.651 142.18 152.221 142.13 152.801C141.43 162.711 130.77 183.961 130.77 183.961C130.77 183.961 120.96 188.791 108.66 186.971C96.3498 185.151 89.9698 183.741 89.9698 183.741V183.731Z"
                    fill="#46A0A6"
                />
                <path
                    d="M100.31 107.071C105.17 105.401 112.24 105.111 118.74 106.181C113.21 103.641 106.81 103.301 100.92 105.451C96.4897 107.071 92.6897 109.981 89.9697 113.771C92.7297 110.771 96.2798 108.451 100.31 107.071Z"
                    fill="#86CED3"
                />
                <path
                    d="M105.57 131.001C106.255 131.001 106.81 130.446 106.81 129.761C106.81 129.076 106.255 128.521 105.57 128.521C104.885 128.521 104.33 129.076 104.33 129.761C104.33 130.446 104.885 131.001 105.57 131.001Z"
                    fill="#332421"
                />
                <path
                    d="M102.97 174.541C103.655 174.541 104.211 173.986 104.211 173.301C104.211 172.616 103.655 172.061 102.97 172.061C102.286 172.061 101.73 172.616 101.73 173.301C101.73 173.986 102.286 174.541 102.97 174.541Z"
                    fill="#332421"
                />
                <path
                    d="M99.4502 149.381C100.025 149.381 100.49 148.916 100.49 148.341C100.49 147.767 100.025 147.301 99.4502 147.301C98.8758 147.301 98.4102 147.767 98.4102 148.341C98.4102 148.916 98.8758 149.381 99.4502 149.381Z"
                    fill="#332421"
                />
                <path
                    d="M128.761 143.521C129.335 143.521 129.801 143.056 129.801 142.481C129.801 141.907 129.335 141.441 128.761 141.441C128.186 141.441 127.721 141.907 127.721 142.481C127.721 143.056 128.186 143.521 128.761 143.521Z"
                    fill="#332421"
                />
                <path
                    d="M130.771 171.591C131.345 171.591 131.81 171.126 131.81 170.551C131.81 169.977 131.345 169.511 130.771 169.511C130.196 169.511 129.73 169.977 129.73 170.551C129.73 171.126 130.196 171.591 130.771 171.591Z"
                    fill="#332421"
                />
                <path
                    d="M119.971 165.861C120.545 165.861 121.011 165.396 121.011 164.821C121.011 164.247 120.545 163.781 119.971 163.781C119.396 163.781 118.931 164.247 118.931 164.821C118.931 165.396 119.396 165.861 119.971 165.861Z"
                    fill="#332421"
                />
                <path
                    d="M89.9706 136.391C90.545 136.391 91.0107 135.926 91.0107 135.351C91.0107 134.777 90.545 134.311 89.9706 134.311C89.3963 134.311 88.9307 134.777 88.9307 135.351C88.9307 135.926 89.3963 136.391 89.9706 136.391Z"
                    fill="#332421"
                />
                <path
                    d="M109.28 117.641C109.855 117.641 110.32 117.176 110.32 116.601C110.32 116.027 109.855 115.561 109.28 115.561C108.706 115.561 108.24 116.027 108.24 116.601C108.24 117.176 108.706 117.641 109.28 117.641Z"
                    fill="#332421"
                />
                <path
                    d="M121.011 128.531C121.585 128.531 122.051 128.066 122.051 127.491C122.051 126.917 121.585 126.451 121.011 126.451C120.436 126.451 119.971 126.917 119.971 127.491C119.971 128.066 120.436 128.531 121.011 128.531Z"
                    fill="#332421"
                />
                <path
                    d="M113.011 152.191C113.585 152.191 114.051 151.726 114.051 151.151C114.051 150.577 113.585 150.111 113.011 150.111C112.436 150.111 111.971 150.577 111.971 151.151C111.971 151.726 112.436 152.191 113.011 152.191Z"
                    fill="#332421"
                />
                <path
                    d="M87.3105 162.271C87.8849 162.271 88.3505 161.806 88.3505 161.231C88.3505 160.657 87.8849 160.191 87.3105 160.191C86.7361 160.191 86.2705 160.657 86.2705 161.231C86.2705 161.806 86.7361 162.271 87.3105 162.271Z"
                    fill="#332421"
                />
                <path
                    d="M143.711 54.0313C144.186 54.0313 144.571 53.6462 144.571 53.1713C144.571 52.6963 144.186 52.3113 143.711 52.3113C143.236 52.3113 142.851 52.6963 142.851 53.1713C142.851 53.6462 143.236 54.0313 143.711 54.0313Z"
                    fill="#332421"
                />
                <path
                    d="M151.7 63.7913C152.175 63.7913 152.56 63.4062 152.56 62.9313C152.56 62.4563 152.175 62.0713 151.7 62.0713C151.225 62.0713 150.84 62.4563 150.84 62.9313C150.84 63.4062 151.225 63.7913 151.7 63.7913Z"
                    fill="#332421"
                />
                <path
                    d="M147.76 71.0513C148.235 71.0513 148.62 70.6662 148.62 70.1913C148.62 69.7163 148.235 69.3313 147.76 69.3313C147.285 69.3313 146.9 69.7163 146.9 70.1913C146.9 70.6662 147.285 71.0513 147.76 71.0513Z"
                    fill="#332421"
                />
                <path
                    d="M152.2 85.6413C152.763 85.6413 153.22 85.1846 153.22 84.6213C153.22 84.0579 152.763 83.6013 152.2 83.6013C151.636 83.6013 151.18 84.0579 151.18 84.6213C151.18 85.1846 151.636 85.6413 152.2 85.6413Z"
                    fill="#332421"
                />
                <path
                    d="M145.52 97.6613C146.083 97.6613 146.54 97.2046 146.54 96.6413C146.54 96.078 146.083 95.6213 145.52 95.6213C144.957 95.6213 144.5 96.078 144.5 96.6413C144.5 97.2046 144.957 97.6613 145.52 97.6613Z"
                    fill="#332421"
                />
                <path
                    d="M148.44 112.461C149.003 112.461 149.46 112.005 149.46 111.441C149.46 110.878 149.003 110.421 148.44 110.421C147.877 110.421 147.42 110.878 147.42 111.441C147.42 112.005 147.877 112.461 148.44 112.461Z"
                    fill="#332421"
                />
                <path
                    d="M156.04 102.261C156.604 102.261 157.06 101.805 157.06 101.241C157.06 100.678 156.604 100.221 156.04 100.221C155.477 100.221 155.021 100.678 155.021 101.241C155.021 101.805 155.477 102.261 156.04 102.261Z"
                    fill="#332421"
                />
                <path
                    d="M153.53 93.3313C154.093 93.3313 154.55 92.8746 154.55 92.3113C154.55 91.7479 154.093 91.2913 153.53 91.2913C152.966 91.2913 152.51 91.7479 152.51 92.3113C152.51 92.8746 152.966 93.3313 153.53 93.3313Z"
                    fill="#332421"
                />
                <path
                    d="M145.52 125.441C146.083 125.441 146.54 124.985 146.54 124.421C146.54 123.858 146.083 123.401 145.52 123.401C144.957 123.401 144.5 123.858 144.5 124.421C144.5 124.985 144.957 125.441 145.52 125.441Z"
                    fill="#332421"
                />
                <path
                    d="M138.95 108.191C139.513 108.191 139.97 107.735 139.97 107.171C139.97 106.608 139.513 106.151 138.95 106.151C138.386 106.151 137.93 106.608 137.93 107.171C137.93 107.735 138.386 108.191 138.95 108.191Z"
                    fill="#332421"
                />
            </g>
        </BasePlant>
    )
}

export default resilifloraRare10
