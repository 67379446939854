import {
    Box,
    Button,
    DialogContent,
    Grow,
    Stack,
    useMediaQuery,
    useTheme
} from '@mui/material'
import { UserPlantInfo } from '../../../../graphql/generated/autogenerated'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback } from 'react'
import { AchievementReward } from '../../../../hooks/useGetReward/useGetReward'
import { PlantCard } from '../../../../components/features/Garden'

export type AchievementRewardDialogProps = {
    achievementReward: AchievementReward
    open: boolean
    onClose: () => void
}

const AchievementRewardDialog: React.FC<AchievementRewardDialogProps> = ({
    achievementReward,
    open,
    onClose
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    const handleButtonClick = useCallback(() => {
        achievementReward.onButtonClick?.()
        onClose()
    }, [achievementReward, onClose])

    return (
        <LeafDialog open={open} onClose={() => onClose()}>
            <DialogContent>
                <Stack
                    data-testid="achievement-reward-dialog"
                    alignItems="center"
                    justifyContent="center"
                    height="100%"
                    width="100%"
                >
                    <Grow in>
                        <Stack
                            alignItems="center"
                            justifyContent="center"
                            gap={2}
                            px={isMobile ? 0 : 6}
                            pb={isMobile ? 0 : 2.5}
                        >
                            {achievementReward?.productUpc && (
                                <Box width={268} flex={1} pb={2}>
                                    <PlantCard
                                        hideCard
                                        plant={
                                            {
                                                productUpc:
                                                    achievementReward?.productUpc
                                            } as UserPlantInfo
                                        }
                                        preserveAspectRatio={false}
                                    />
                                </Box>
                            )}
                            <Stack
                                flexDirection="column"
                                alignItems="center"
                                justifyContent="center"
                                gap={3}
                                my={2}
                            >
                                <Stack gap={2} alignItems="center">
                                    <CoreTypography variant="h3">
                                        {achievementReward?.title}
                                    </CoreTypography>
                                    <CoreTypography
                                        variant="body1"
                                        textAlign="center"
                                    >
                                        {achievementReward?.description}
                                    </CoreTypography>
                                </Stack>
                                {achievementReward?.onButtonClick &&
                                    achievementReward?.buttonText && (
                                        <Button
                                            variant="contained"
                                            onClick={handleButtonClick}
                                            fullWidth
                                        >
                                            <CoreTypography customVariant="buttonNormal">
                                                {achievementReward?.buttonText}
                                            </CoreTypography>
                                        </Button>
                                    )}
                            </Stack>
                        </Stack>
                    </Grow>
                </Stack>
            </DialogContent>
        </LeafDialog>
    )
}

export default AchievementRewardDialog
