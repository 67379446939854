export enum AchievementTypes {
    committed = 'COMMITTED', //inactive
    checkIn = 'CHECK_IN',
    challengeCompleted = 'CHALLENGE_COMPLETED', //inactive
    challengeJoined = 'CHALLENGE_JOINED', //inactive
    resetWatched = 'RESET_WATCHED',
    pulseCompleted = 'PULSE_SURVEY_COMPLETED',
    articleCompleted = 'ARTICLE_COMPLETED', //inactive
    podcastCompleted = 'PODCAST_COMPLETED', //inactive
    journeyComplete = 'JOURNEY_LEVEL_COMPLETED',
    referralCompleted = 'REFERRAL_COMPLETED'
}
