export const AchievementUpcs = {
    connect: 'achievement.journey.connect',
    dailyReflection: 'achievement.dailyreflection',
    focus: 'achievement.journey.focus',
    food: 'achievement.journey.food',
    microStepper: 'achievement.microstepper',
    mindfulMoments: 'achievement.mindfulmoments',
    money: 'achievement.journey.money',
    move: 'achievement.journey.move',
    recharge: 'achievement.journey.recharge',
    communityBuilder: 'achievement.communitybuilder',
    stress: 'achievement.journey.stress'
} as const

export type AchievementUpcs =
    (typeof AchievementUpcs)[keyof typeof AchievementUpcs]
