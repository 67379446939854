import { Box, BoxProps } from '@mui/material'
import React, { memo } from 'react'

export interface ImageProps extends BoxProps {
    src: string
    alt?: string
}

const Image: React.FC<ImageProps> = ({ src, alt = '', ...props }) => (
    <Box
        component="img"
        alt={alt}
        sx={{
            width: '100%'
        }}
        src={src}
        {...props}
    />
)

export default memo(Image)
