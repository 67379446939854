import {
    Box,
    Collapse,
    Fade,
    styled,
    type SxProps,
    type Theme
} from '@mui/material'
import { memo, useState } from 'react'
import { AchievementV3Info } from '../../../../graphql/generated/autogenerated'
import useAchievementV3Info from '../../../../hooks/useAchievementV3Info/useAchievementV3Info'
import AchievementProgressV3 from '../AchievementProgressV3/AchievementProgressV3'

const COLLAPSE_TRANSITION_TIMEOUT = {
    exit: 1000,
    enter: 250,
    appear: 500
}

const FADE_TRANSITION_TIMEOUT = {
    exit: 750,
    enter: 250,
    appear: 750
}

/**
 * A container component that handles the transition of its children based on the "show" prop.
 * It uses the Collapse and Fade components from the MUI for the transitions.
 */
const TransitionContainer: React.FC<
    React.PropsWithChildren<{ show: boolean }>
> = ({ show, children }) => {
    const styleOverrides = {
        transitionDelay: !show ? '250ms' : '0ms'
    }
    return (
        <Collapse
            timeout={COLLAPSE_TRANSITION_TIMEOUT}
            in={show}
            style={styleOverrides}
        >
            <Fade
                in={show}
                timeout={FADE_TRANSITION_TIMEOUT}
                style={styleOverrides}
            >
                <Box>{children}</Box>
            </Fade>
        </Collapse>
    )
}

const ListItem = styled('li')()

type AchievementsV3ProgressListItemProps = {
    index: number
    achievementInfo: AchievementV3Info
    currentProgress: number
    previousProgress: number
    sx?: SxProps<Theme>
}

const AchievementsV3ProgressListItem: React.FC<
    AchievementsV3ProgressListItemProps
> = ({ index, achievementInfo, currentProgress, previousProgress, sx }) => {
    const [progress, setProgress] = useState(previousProgress)
    const getAchievementInfo = useAchievementV3Info()
    const { name, badgeUrl, label, actionNumber, actionPath } =
        getAchievementInfo(achievementInfo)

    if (index === 0 && progress !== currentProgress) {
        const timeout = setTimeout(() => {
            setProgress(currentProgress)
            clearTimeout(timeout)
        }, 500)
    }
    return (
        <TransitionContainer show={progress !== actionNumber}>
            <ListItem sx={sx}>
                <AchievementProgressV3
                    name={name}
                    badgeUrl={badgeUrl}
                    label={label}
                    currentProgress={progress}
                    actionNumber={actionNumber}
                    actionPath={actionPath}
                />
            </ListItem>
        </TransitionContainer>
    )
}

export default memo(AchievementsV3ProgressListItem)
