import { Card, Stack, Typography } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage } from 'react-intl'

const GardenWidgetFallback = () => {
    const { palette } = useTheme()
    return (
        <Card elevation={0}>
            <Stack
                p={2}
                width="100%"
                height={160}
                bgcolor={palette.background.default}
            >
                <Typography variant="body1">
                    <FormattedMessage
                        defaultMessage="We can't load your Garden at this time. Try again later."
                        description="Error when we can't load the garden widget card"
                    />
                </Typography>
            </Stack>
        </Card>
    )
}

export default GardenWidgetFallback
