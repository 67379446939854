import { Button, DialogContent, Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    ProductInfo,
    ProductType
} from '../../../graphql/generated/autogenerated'
import ProductInfoCard from '../ProductInfoCard/ProductInfoCard'
import WaterPlantCard from '../WaterPlantCard/WaterPlantCard'

const MESSAGES = defineMessages({
    dialogTitle: {
        defaultMessage: 'Plant details',
        description: 'Title of the plant details dialog'
    },
    dialogSubtitle: {
        defaultMessage:
            'Water your plant when you complete Microsteps or watch Resets.',
        description: 'Subtitle of the plant details dialog'
    },
    closeButton: {
        defaultMessage: 'Close',
        description: 'button text for closing the dialog'
    }
})

export type ProductDetailsDialogProps = {
    open: boolean
    product?: ProductInfo
    title?: string
    subtitle?: string
    onClose: () => void
    secondaryAction?: {
        message: string
        onClick: () => void
    }
}

const ProductDetailsDialog: React.FC<ProductDetailsDialogProps> = ({
    open,
    product,
    title,
    subtitle,
    onClose,
    secondaryAction
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const { formatMessage } = useIntl()

    return (
        <LeafDialog
            open={open}
            maxWidth="md"
            fullScreen={isMobile}
            onClose={onClose}
            dialogTitle={
                <CoreTypography variant="h3">
                    {title ? title : formatMessage(MESSAGES.dialogTitle)}
                </CoreTypography>
            }
        >
            <DialogContent
                sx={{
                    minWidth: !isMobile ? 624 : undefined,
                    overflow: 'unset'
                }}
            >
                <Stack gap={4.5}>
                    <CoreTypography variant="body1" lineHeight={1.3}>
                        {subtitle ?? formatMessage(MESSAGES.dialogSubtitle)}
                    </CoreTypography>
                    <Stack
                        gap={3}
                        direction={isMobile ? 'column' : 'row'}
                        justifyContent="center"
                    >
                        <Stack gap={3}>
                            <ProductInfoCard
                                productInfo={product}
                                fullWidth={isMobile}
                            />
                            {!!secondaryAction && (
                                <Button
                                    onClick={secondaryAction.onClick}
                                    variant="outlined"
                                >
                                    <CoreTypography customVariant="buttonNormal">
                                        {secondaryAction.message}
                                    </CoreTypography>
                                </Button>
                            )}
                        </Stack>
                        {!secondaryAction &&
                            product?.productTypeEnum !== ProductType.Object && (
                                <WaterPlantCard onActionClick={onClose} />
                            )}
                    </Stack>
                </Stack>
            </DialogContent>
        </LeafDialog>
    )
}

export default memo(ProductDetailsDialog)
