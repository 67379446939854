import { useContext } from 'react'
import AchievementsProviderContext, {
    AchievementsProviderValue
} from './AchievementsProviderContext'

export const useAchievementsContext = (): AchievementsProviderValue => {
    const context = useContext(AchievementsProviderContext)

    if (context === undefined) {
        throw new Error(
            'useAchievementsContext must be used within a AchievementsContext'
        )
    }

    return context
}
