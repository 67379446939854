import { Button, Divider, Stack } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback, useMemo } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../../routes'
import AchievementsEarnedWidgetBanner from '../AchievementsEarnedWidgetBanner/AchievementsEarnedWidgetBanner'
import AchievementsV3ProgressList from '../AchievementsV3ProgressList/AchievementsV3ProgressList'
import Tokens from '../Tokens/Tokens'
import { usePreviousAchievementsV3Progress } from '../../../../shared'

const messages = defineMessages({
    earnFirstAchievement: {
        defaultMessage: 'Earn your first Badge',
        description: 'first time badge subtitle'
    },
    keepMakingProgress: {
        defaultMessage: 'Keep making progress towards your next badge!',
        description: 'second time badge subtitle'
    }
})

type AchievementWidgetProps = Omit<
    React.ComponentProps<typeof Stack>,
    'children'
>

const AchievementWidget: React.FC<AchievementWidgetProps> = ({
    ...stackProps
}) => {
    const history = useHistory()
    const { formatMessage } = useIntl()
    const { progressV3 } = useAppSelector((state) => state.achievement)
    const { newAchievementsCount, previousProgress } =
        usePreviousAchievementsV3Progress()

    const message = useMemo(() => {
        const anyEarned = progressV3.some((a) => a.total > 0)
        if (anyEarned) {
            return formatMessage(messages.keepMakingProgress)
        } else {
            return formatMessage(messages.earnFirstAchievement)
        }
    }, [formatMessage, progressV3])

    const handleViewShop = useCallback(() => {
        history.push(ROUTES.SHOP)
    }, [history])

    return (
        <Stack p={2} gap={2} {...stackProps}>
            <Stack gap={1}>
                <CoreTypography variant="overline" color="text.secondary">
                    <FormattedMessage
                        defaultMessage="Tokens"
                        description="tokens title"
                    />
                </CoreTypography>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Tokens />
                    <Button
                        onClick={handleViewShop}
                        variant="text"
                        endIcon={
                            <LeafIcon icon="arrow-right" fontSize="small" />
                        }
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                defaultMessage="View shop"
                                description="view shop button"
                            />
                        </CoreTypography>
                    </Button>
                </Stack>
                <Divider />
            </Stack>
            <Stack gap={1}>
                <Stack gap={1}>
                    <CoreTypography variant="overline" color="text.secondary">
                        <FormattedMessage
                            defaultMessage="Badges"
                            description="badges title"
                        />
                    </CoreTypography>
                    {newAchievementsCount > 0 ? (
                        <AchievementsEarnedWidgetBanner
                            count={newAchievementsCount}
                        />
                    ) : (
                        <CoreTypography variant="body1" color="text.secondary">
                            {message}
                        </CoreTypography>
                    )}
                </Stack>
                <AchievementsV3ProgressList
                    progress={progressV3}
                    previousProgress={previousProgress}
                />
            </Stack>
        </Stack>
    )
}

export default memo(AchievementWidget)
