import { useContext } from 'react'
import ShopProviderContext, { ShopProviderValue } from './shopProviderContext'

export const useShopContext = (): ShopProviderValue => {
    const context = useContext(ShopProviderContext)

    if (context === undefined) {
        throw new Error('useShopContext must be used within a ShopContext')
    }

    return context
}
