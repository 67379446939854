import { useCallback, useMemo } from 'react'
import { AchievementV2 } from '../../graphql/generated/autogenerated'
import { AchievementTypes } from '../../enums'
import { AchievementIcons } from '../../enums/achievementIcons'
import {
    CompletedSmall,
    connect,
    dailyReflection,
    focus,
    food,
    microstepper,
    mindfulMoments,
    money,
    move,
    recharge,
    referral,
    stress
} from '../../components/icons'

export const useAchievementIcons = () => {
    const achievementIcons = useMemo(
        () => ({
            [AchievementIcons.connect]: connect,
            [AchievementIcons.dailyReflection]: dailyReflection,
            [AchievementIcons.focus]: focus,
            [AchievementIcons.food]: food,
            [AchievementIcons.microstepper]: microstepper,
            [AchievementIcons.mindfulMoments]: mindfulMoments,
            [AchievementIcons.money]: money,
            [AchievementIcons.move]: move,
            [AchievementIcons.recharge]: recharge,
            [AchievementIcons.communitybuilder]: referral,
            [AchievementIcons.stress]: stress
        }),
        []
    )
    const getAchievementIcon = useCallback(
        (achievement: AchievementV2) => {
            if (!achievement) {
                return CompletedSmall
            }

            let selectedIcon

            switch (achievement.actionType) {
                case AchievementTypes.committed:
                case AchievementTypes.checkIn:
                    selectedIcon =
                        achievementIcons[AchievementIcons.microstepper]
                    break
                case AchievementTypes.resetWatched:
                    selectedIcon =
                        achievementIcons[AchievementIcons.mindfulMoments]
                    break
                case AchievementTypes.pulseCompleted:
                    selectedIcon =
                        achievementIcons[AchievementIcons.dailyReflection]
                    break
                case AchievementTypes.referralCompleted:
                    selectedIcon =
                        achievementIcons[AchievementIcons.communitybuilder]
                    break
                case AchievementTypes.journeyComplete:
                    selectedIcon = achievementIcons[achievement.upc]
                    break
                default:
                    selectedIcon =
                        achievementIcons[AchievementIcons.microstepper]
                    break
            }

            if (!selectedIcon) {
                return achievementIcons[AchievementIcons.microstepper]
            }

            return selectedIcon
        },
        [achievementIcons]
    )

    return getAchievementIcon
}
