export enum PlantType {
    // Trophies
    referralTrophyPlant = 'product.referral-trophy-plant-1',
    personalResetTrophyPlant = 'plant.personalreset',
    // Plants
    advanced = 'plant.advanced',
    advancedRare = 'plant.advanced.rare',
    intermediate = 'plant.intermediate',
    intermediateRare = 'plant.intermediate.rare',
    beginner = 'plant.beginner',
    beginnerRare = 'plant.beginner.rare',
    resilifloraRare = 'plant.resiliflora.rare',
    nutrivora = 'plant.nutrivora',
    novafutura = 'plant.onboarding',
    revalesca = 'plant.revalesca',
    audaciaflora = 'plant.audaciaflora',
    solaflora = 'plant.solaflora',
    serenita = 'plant.serenita',
    bonvira = 'plant.bonvira',
    harmonia = 'plant.harmonia',
    cresfolia = 'plant.cresfolia',
    vitara = 'plant.vitara',
    fortituda = 'plant.fortituda'
}

export function isTrophy(plantType: PlantType): boolean {
    return (
        plantType === PlantType.referralTrophyPlant ||
        plantType === PlantType.personalResetTrophyPlant
    )
}
