import { Box, Stack } from '@mui/material'
import {
    IconButton,
    LeafCircularProgress
} from '@thriveglobal/thrive-web-leafkit'
import { useIntl } from 'react-intl'
import { AchievementAccessibilityDescriptions } from '../../../../enums'

import { useMemo } from 'react'
import useAchievementAccessibilityDescriptions from '../../../../hooks/useAchievementAccessibilityDescriptions/useAchievementAccessibilityDescriptions'
import { usePlantProgress } from '../../../../hooks/usePlantProgress/usePlantProgress'
import Plant, { PlantProps } from '../Plant/Plant'

const PLANT_SIZE_MAP = {
    medium: 161,
    large: 210
}

type ProgressContainerProps = {
    ariaLabel: string
    label: string
    percentageCompleted: number
    progressIcon?: React.ReactNode
}

const ProgressContainer: React.FC<ProgressContainerProps> = ({
    ariaLabel,
    label,
    percentageCompleted,
    progressIcon
}) => {
    return (
        <Stack justifyContent="flex-end" position="relative">
            <LeafCircularProgress
                aria-label={ariaLabel}
                color="accent"
                size={25}
                label={label}
                showLabel={!progressIcon}
                value={percentageCompleted}
                variant="determinate"
            />
            {!!progressIcon && (
                <Stack
                    position="absolute"
                    bottom={0}
                    left={0}
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                >
                    {progressIcon}
                </Stack>
            )}
        </Stack>
    )
}

export type PlantProgressProps = PlantProps & {
    progressIcon?: React.ReactNode
    loading?: boolean
    onProgressClick?: () => void
    maxPlantSize?: 'medium' | 'large'
    showProgress?: boolean
}

const PlantProgress: React.FC<PlantProgressProps> = ({
    plant,
    product,
    loading,
    progressIcon,
    onProgressClick,
    maxPlantSize = 'medium',
    showProgress = false
}) => {
    const { formatNumber } = useIntl()
    const ariaLabels = useAchievementAccessibilityDescriptions()

    const { totalStepsNeeded, remainingSteps, percentageCompleted } =
        usePlantProgress(plant, product)

    const progressAriaLabel = ariaLabels[
        AchievementAccessibilityDescriptions.ActivePlantStatus
    ]({
        hasActivePlant: true,
        plantName: product?.title,
        daysLeft: remainingSteps
    })

    const shouldShowProgress = useMemo(() => {
        return totalStepsNeeded > 1 && (remainingSteps > 0 || showProgress)
    }, [totalStepsNeeded, remainingSteps, showProgress])

    return (
        <Stack alignItems="center" width="100%" position="relative">
            <Box
                maxWidth={PLANT_SIZE_MAP[maxPlantSize]}
                maxHeight={PLANT_SIZE_MAP[maxPlantSize]}
            >
                <Plant plant={plant} product={product} />
            </Box>
            {shouldShowProgress && (
                <Stack
                    position="absolute"
                    justifyContent="flex-end"
                    bottom={onProgressClick ? -10 : 0}
                    right={onProgressClick ? -10 : 0}
                >
                    {onProgressClick ? (
                        <IconButton onClick={onProgressClick}>
                            <ProgressContainer
                                ariaLabel={progressAriaLabel}
                                label={formatNumber(remainingSteps)}
                                percentageCompleted={percentageCompleted}
                                progressIcon={progressIcon}
                            />
                        </IconButton>
                    ) : (
                        <ProgressContainer
                            ariaLabel={progressAriaLabel}
                            label={formatNumber(remainingSteps)}
                            percentageCompleted={percentageCompleted}
                            progressIcon={progressIcon}
                        />
                    )}
                </Stack>
            )}
        </Stack>
    )
}

export default PlantProgress
