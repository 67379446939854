import { Box } from '@mui/material'
import React from 'react'
import { GardenObjectTypes } from '../../../../enums/gardenObjectTypes'
import { ProductInfo } from '../../../../graphql/generated/autogenerated'
import { ProductTypes } from '../../../../enums'
import { useGardenObjectIcons } from '../../../../hooks/useGardenObjectIcons/useGardenObjectIcons'
import Image from '../../../elements/Image/Image'

type GardenObjectProps = {
    productInfo: ProductInfo
}

const GardenObject: React.FC<GardenObjectProps> = ({ productInfo }) => {
    const { getObjectSrc } = useGardenObjectIcons()

    if (!productInfo || productInfo.productType !== ProductTypes.Object) {
        return null
    }

    return (
        <Box width="100%" height="100%">
            <Image
                alt={productInfo.title}
                src={getObjectSrc(productInfo.upc as GardenObjectTypes)}
                sx={{
                    width: '100%',
                    height: '100%'
                }}
            />
        </Box>
    )
}

export default GardenObject
