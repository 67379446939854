import { IconButton, LinearProgress, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { promptSelectedDefaultValues } from '../../../../utils/Avo/defaultValues'

const ariaLabels = defineMessages({
    goTo: {
        defaultMessage: 'Go to action',
        description: 'label for view details button'
    },
    progress: {
        defaultMessage: 'Progress to completion.',
        description: 'label for progress bar'
    }
})

export type AchievementProgressV3Props = {
    name: string
    badgeUrl: string
    label: string
    currentProgress: number
    actionNumber: number
    actionPath: string
}
const AchievementProgressV3: React.FC<AchievementProgressV3Props> = ({
    name,
    badgeUrl,
    label,
    currentProgress,
    actionNumber,
    actionPath
}) => {
    const history = useHistory()
    const { formatMessage } = useIntl()
    const navigateToAction = useCallback(
        (actionPath: string) => () => {
            Avo.promptSelected({
                ...promptSelectedDefaultValues,
                featureType: 'achievement',
                activityType: 'make_badge_progress_selected'
            })
            history.push(actionPath)
        },
        [history]
    )

    return (
        <Stack direction="row" gap={1.5} alignItems="center">
            <Stack alignItems="center">
                <img src={badgeUrl} alt={label} width="100%" height="100%" />
            </Stack>
            <Stack gap={1} width="100%">
                <Stack
                    gap={1}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <CoreTypography variant="body1">{name}</CoreTypography>
                </Stack>
                {actionNumber > 1 && (
                    <LinearProgress
                        aria-label={formatMessage(ariaLabels.progress)}
                        variant="determinate"
                        color="accent"
                        value={(currentProgress * 100) / actionNumber}
                    />
                )}
            </Stack>
            <IconButton
                aria-label={formatMessage(ariaLabels.goTo)}
                onClick={navigateToAction(actionPath)}
            >
                <LeafIcon icon="arrow-right" />
            </IconButton>
        </Stack>
    )
}

export default AchievementProgressV3
