import { gql } from '@thriveglobal/thrive-web-core'

export const GET_USER_FLAG = gql`
    query GetUserFlag($key: String!) {
        achievements {
            getUserFlag(key: $key)
        }
    }
`

export const GET_USER_FLAGS = gql`
    query GetUserFlags {
        achievements {
            getUserFlags {
                createdAt
                key
                updatedAt
                value
            }
        }
    }
`
