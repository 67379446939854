import { Box } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'

const HiddenDescription: React.FC<any> = (props) => {
    const theme = useTheme()

    return (
        <Box
            sx={{
                top: 0,
                left: theme.spacing(-0.25),
                width: theme.spacing(0.125),
                height: theme.spacing(0.125),
                position: 'absolute',
                overflow: 'hidden'
            }}
            {...props}
        >
            {props.children}
        </Box>
    )
}

export default React.memo(HiddenDescription)
