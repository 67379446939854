import { memo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import InformationalPlantsDialog from '../InformationalPlantsContainers/InformationalPlantsDialog'

const messages = defineMessages({
    title: {
        defaultMessage: 'Way to grow!',
        description:
            'title of the dialog that explains to the user that they reached their daily plant limit'
    },
    body: {
        defaultMessage:
            "You've reached the daily limit for new plants, but you can keep building those healthy habits! Keep exploring Thrive, and come back tomorrow to keep growing your garden.",
        description:
            'explanation that the user has reached their daily plant limit'
    }
})

export type PlantsLimitReachedDialogProps = {
    open: boolean
    onClose: () => void
}

const PlantsLimitReachedDialog = ({
    open,
    onClose
}: PlantsLimitReachedDialogProps) => {
    const { formatMessage } = useIntl()
    return (
        <InformationalPlantsDialog
            open={open}
            onClose={onClose}
            title={formatMessage(messages.title)}
            body={formatMessage(messages.body)}
        />
    )
}

export default memo(PlantsLimitReachedDialog)
