import React, { memo } from 'react'
import { FormattedMessage } from 'react-intl'
import InformationalPlantsDialog from '../InformationalPlantsContainers/InformationalPlantsDialog'

export type PlantActionsExplainerDialogProps = {
    open: boolean
    onClose: () => void
}

const PlantActionsExplainerDialog: React.FC<
    PlantActionsExplainerDialogProps
> = ({ open, onClose }) => {
    return (
        <InformationalPlantsDialog
            open={open}
            onClose={onClose}
            title={
                <FormattedMessage
                    defaultMessage="Plant growth actions"
                    description="Title of the plant actions explainer dialog"
                />
            }
            body={
                <FormattedMessage
                    defaultMessage="Your plant grows when you take one of two actions: completing Microsteps or watching Resets. The number of actions required to fully grow a plant varies based on the kind of plant you choose."
                    description="plant actions explainer dialog message"
                />
            }
        />
    )
}

export default memo(PlantActionsExplainerDialog)
