import { useContext } from 'react'
import PlantsProviderContext, {
    PlantsProviderValue
} from './plantsProviderContext'

export const usePlantsContext = (): PlantsProviderValue => {
    const context = useContext(PlantsProviderContext)

    if (context === undefined) {
        throw new Error('usePlantsContext must be used within a PlantsContext')
    }

    return context
}
