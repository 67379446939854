import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    styled,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo, useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ROUTES } from '../../../routes'

const MESSAGES = defineMessages({
    dialogTitle: {
        defaultMessage: 'How it works',
        description: 'Title of the garden how-to dialog'
    },
    stepOne: {
        defaultMessage:
            'Visit the plant shop to choose a plant for your garden. Make progress on your well-being journey by completing Microsteps and watching Resets and watch your plant grow.',
        description: 'Step 1 of the garden how-to dialog'
    },
    stepTwo: {
        defaultMessage:
            'You can also earn Achievements as you continue to progress. Achievements will earn you tokens.',
        description: 'Step 2 of the garden how-to dialog'
    },
    stepThree: {
        defaultMessage:
            'Spend your tokens on extra items for your garden like a bird house or a koi pond.',
        description: 'Step 3 of the garden how-to dialog'
    },
    oldStepOne: {
        defaultMessage:
            'Make progress towards earning Achievements by completing Microsteps, Resets, and Journey lessons.',
        description: 'Step 1 of the garden how-to dialog'
    },
    oldStepTwo: {
        defaultMessage:
            'Once an Achievement has been unlocked, claim it to earn Tokens.',
        description: 'Step 2 of the garden how-to dialog'
    },
    oldStepThree: {
        defaultMessage: 'Use your Tokens to buy plants for your garden.',
        description: 'Step 3 of the garden how-to dialog'
    },
    oldStepFour: {
        defaultMessage:
            'Once you start growing a plant, come back each day to water it, once it’s fully grown it will be added to your garden.',
        description: 'Step 4 of the garden how-to dialog'
    },
    button: {
        defaultMessage: "Let's grow",
        description: 'Button text for garden how-to dialog'
    }
})
const StyledOl = styled('ol')({
    display: 'flex',
    flexDirection: 'column',
    gap: 16
})

export type GardenHowToDialogProps = {
    open: boolean
    onClose: () => void
}

const GardenHowToDialog: React.FC<GardenHowToDialogProps> = ({
    open,
    onClose
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('md'))
    const { formatMessage } = useIntl()
    const history = useHistory()

    const handleRoute = useCallback(() => {
        onClose()
        history.push(ROUTES.SHOP)
    }, [onClose, history])

    return (
        <LeafDialog
            open={open}
            maxWidth="md"
            fullScreen={isMobile}
            onClose={onClose}
            dialogTitle={
                <CoreTypography variant="h3" as={'span'}>
                    {formatMessage(MESSAGES.dialogTitle)}
                </CoreTypography>
            }
        >
            <DialogContent sx={{ maxWidth: 624 }}>
                <CoreTypography variant="body1" lineHeight={1.3} as={Box}>
                    <StyledOl>
                        <li>{formatMessage(MESSAGES.stepOne)}</li>
                        <li>{formatMessage(MESSAGES.stepTwo)}</li>
                        <li>{formatMessage(MESSAGES.stepThree)}</li>
                    </StyledOl>
                </CoreTypography>
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={handleRoute}>
                    <CoreTypography customVariant="buttonNormal">
                        {formatMessage(MESSAGES.button)}
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default memo(GardenHowToDialog)
