import { LinearProgress, Stack } from '@mui/material'
import React, { memo, useMemo } from 'react'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'

export interface AchievementMilestoneProgressProps {
    currentLevel: number
    milestones: number[]
    maxLevel: number
}

const AchievementMilestoneProgress: React.FC<
    AchievementMilestoneProgressProps
> = ({ currentLevel, milestones, maxLevel }) => {
    const { progress, markers } = useMemo(
        () => ({
            progress: Math.min(currentLevel / maxLevel, 1) * 100,
            markers: milestones.map((goal) => (goal / maxLevel) * 100)
        }),
        [currentLevel, maxLevel, milestones]
    )

    return (
        <Stack
            height={32}
            width="100%"
            justifyContent="flex-end"
            position="relative"
        >
            {markers.map((marker, index) => {
                const isAchieved = currentLevel >= milestones[index]
                return (
                    <Stack
                        key={index}
                        position="absolute"
                        left={`calc(${marker}% - 1px)`}
                        top={0}
                        bottom={0}
                        alignItems="center"
                        justifyContent="center"
                        zIndex={2}
                    >
                        <Stack height="100%" direction="row" gap={0.23625}>
                            <Stack
                                height="100%"
                                width={2}
                                bgcolor={
                                    isAchieved ? 'accent.main' : 'text.disabled'
                                }
                            />
                            <LeafIcon
                                icon={'trophy'}
                                color={isAchieved ? 'success' : 'disabled'}
                            />
                        </Stack>
                    </Stack>
                )
            })}
            <LinearProgress
                variant="determinate"
                value={progress}
                color="accent"
            />
        </Stack>
    )
}

export default memo(AchievementMilestoneProgress)
