import { Card, Fade, Grid, Grow, Stack } from '@mui/material'
import {
    CoreTypography,
    IconButton,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { ReactNode, useEffect, useMemo, useRef } from 'react'
import {
    FormattedDate,
    FormattedMessage,
    FormattedNumber,
    defineMessages,
    useIntl
} from 'react-intl'
import {
    ProductInfo,
    UserPlantInfo
} from '../../../../graphql/generated/autogenerated'

const messages = defineMessages({
    rare: {
        defaultMessage: 'Rare',
        description: 'rare product'
    },
    common: {
        defaultMessage: 'Common',
        description: 'common product'
    },
    closeDetails: {
        defaultMessage: 'close {plantName} details',
        description: 'button text'
    }
})

export interface PlantCardDetailsProps {
    productComponent: ReactNode
    plant?: UserPlantInfo
    product?: ProductInfo
    isLarge?: boolean
    isWidget?: boolean
    onBack?: () => void
}

const PlantCardDetails: React.FC<PlantCardDetailsProps> = ({
    productComponent,
    plant,
    product,
    isLarge,
    isWidget,
    onBack
}) => {
    const { formatMessage } = useIntl()
    const { spacing } = useTheme()
    const ref = useRef(null)

    const { isRare, daysLeft } = useMemo(() => {
        return {
            isRare: product?.upc.includes('.rare'),
            daysLeft:
                (product?.plantWateringSteps || 0) - (plant?.progressSteps || 0)
        }
    }, [product?.upc, product?.plantWateringSteps, plant?.progressSteps])

    useEffect(() => {
        if (ref?.current) {
            ref.current.focus()
        }
    }, [ref])

    return (
        <Stack
            width="100%"
            height="100%"
            alignItems="center"
            justifyContent="center"
            pt={isLarge ? 2.5 : 0}
            px={isLarge ? 2 : 0}
            pb={isLarge ? 2 : 0}
            data-testid="plant-card-details"
        >
            <Fade in>
                <Stack
                    position="absolute"
                    top={isLarge ? spacing(2.5) : spacing(1)}
                    left={isLarge ? spacing(2) : spacing(1)}
                    zIndex={1002}
                >
                    <IconButton
                        ref={ref}
                        variant="text"
                        color="primary"
                        onClick={() => onBack()}
                        aria-label={formatMessage(messages.closeDetails, {
                            plantName: product?.title
                        })}
                    >
                        <LeafIcon icon={'chevron-left'} />
                    </IconButton>
                </Stack>
            </Fade>
            <Grow in>
                <Stack
                    height="100%"
                    alignItems="flex-end"
                    justifyContent="center"
                    direction={!isLarge ? 'column' : 'row'}
                    gap={isLarge ? 3 : 0}
                    pb={isLarge ? 5 : 0}
                >
                    <Stack
                        width="100%"
                        p={isWidget ? 2 : 1}
                        flex={isLarge ? 1 : 0}
                    >
                        {productComponent}
                    </Stack>
                    <Card
                        elevation={isLarge ? 1 : 0}
                        sx={{
                            borderTopLeftRadius: isLarge ? undefined : 0,
                            borderTopRightRadius: isLarge ? undefined : 0,
                            flex: 1,
                            bgcolor: 'info.dark',
                            p: 2
                        }}
                    >
                        <Stack alignItems="center" justifyContent="center">
                            <Grid
                                container
                                rowSpacing={isLarge ? 2 : 0.6}
                                columnSpacing={5}
                                component="output"
                                aria-live="polite"
                            >
                                <Grid item xs={6}>
                                    <Stack
                                        flexDirection="column"
                                        width="100%"
                                        gap={!isLarge ? 0 : 0.5}
                                    >
                                        <CoreTypography variant="overline">
                                            <FormattedMessage
                                                defaultMessage="Name"
                                                description="name of the plant"
                                            />
                                        </CoreTypography>
                                        <CoreTypography
                                            {...(isLarge && {
                                                customVariant: 'form'
                                            })}
                                            {...(!isLarge && {
                                                variant: 'h6'
                                            })}
                                        >
                                            {product?.title}
                                        </CoreTypography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack
                                        flexDirection="column"
                                        width="100%"
                                        gap={!isLarge ? 0 : 0.5}
                                    >
                                        <CoreTypography variant="overline">
                                            <FormattedMessage
                                                defaultMessage="Level"
                                                description="name of the plant"
                                            />
                                        </CoreTypography>
                                        <CoreTypography
                                            {...(isLarge && {
                                                customVariant: 'form'
                                            })}
                                            {...(!isLarge && {
                                                variant: 'h6'
                                            })}
                                        >
                                            {isRare
                                                ? formatMessage(messages.rare)
                                                : formatMessage(
                                                      messages.common
                                                  )}
                                        </CoreTypography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack
                                        flexDirection="column"
                                        width="100%"
                                        gap={!isLarge ? 0 : 0.5}
                                    >
                                        <CoreTypography variant="overline">
                                            <FormattedMessage
                                                defaultMessage="Growth"
                                                description="name of the plant"
                                            />
                                        </CoreTypography>
                                        <CoreTypography
                                            {...(isLarge && {
                                                customVariant: 'form'
                                            })}
                                            {...(!isLarge && {
                                                variant: 'h6'
                                            })}
                                        >
                                            <FormattedMessage
                                                defaultMessage="{days} days"
                                                values={{
                                                    days: product?.plantWateringSteps
                                                }}
                                                description="text describing the cost of a product"
                                            />
                                        </CoreTypography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack
                                        flexDirection="column"
                                        width="100%"
                                        gap={!isLarge ? 0 : 0.5}
                                    >
                                        <CoreTypography variant="overline">
                                            <FormattedMessage
                                                defaultMessage="Cost"
                                                description="name of the plant"
                                            />
                                        </CoreTypography>
                                        <CoreTypography
                                            {...(isLarge && {
                                                customVariant: 'form'
                                            })}
                                            {...(!isLarge && {
                                                variant: 'h6'
                                            })}
                                        >
                                            {product?.cost}
                                        </CoreTypography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack
                                        flexDirection="column"
                                        width="100%"
                                        gap={!isLarge ? 0 : 0.5}
                                    >
                                        <CoreTypography variant="overline">
                                            <FormattedMessage
                                                defaultMessage="Started"
                                                description="started date"
                                            />
                                        </CoreTypography>
                                        <CoreTypography
                                            {...(isLarge && {
                                                customVariant: 'form'
                                            })}
                                            {...(!isLarge && {
                                                variant: 'h6'
                                            })}
                                        >
                                            <FormattedDate
                                                value={plant?.createdAt}
                                                day="2-digit"
                                                month="2-digit"
                                                year="numeric"
                                            />
                                        </CoreTypography>
                                    </Stack>
                                </Grid>
                                <Grid item xs={6}>
                                    <Stack
                                        flexDirection="column"
                                        width="100%"
                                        gap={!isLarge ? 0 : 0.5}
                                    >
                                        <CoreTypography variant="overline">
                                            <FormattedMessage
                                                defaultMessage="Days left"
                                                description="days left to ha"
                                            />
                                        </CoreTypography>
                                        <CoreTypography
                                            {...(isLarge && {
                                                customVariant: 'form'
                                            })}
                                            {...(!isLarge && {
                                                variant: 'h6'
                                            })}
                                        >
                                            <FormattedNumber value={daysLeft} />
                                        </CoreTypography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Card>
                </Stack>
            </Grow>
        </Stack>
    )
}

export default React.memo(PlantCardDetails)
