import { Divider, Stack } from '@mui/material'
import {
    fetchAndStoreAchievements,
    fetchAndStoreGarden,
    fetchAndStoreWallet
} from '@thriveglobal/thrive-web-core'
import { useEffect } from 'react'
import AchievementWidget from '../AchievementWidget/AchievementWidget'
import { GardenWidget } from '../../../../components/features/Garden'

const AchievementsCenter = () => {
    // Load achievements, wallet, and garden on mount
    // If there have been changes since last load, redux will dispatch new values
    useEffect(() => {
        fetchAndStoreAchievements()
        fetchAndStoreWallet()
        fetchAndStoreGarden()
    }, [])
    return (
        <Stack gap={2}>
            <GardenWidget autoShowPlantSelector={false} />
            <Divider />
            <AchievementWidget p={0} />
        </Stack>
    )
}

export default AchievementsCenter
