export const GardenObjectTypes = {
    waterPail: 'object.waterpail',
    wishingWell: 'object.wishingwell',
    gardenWellies: 'object.gardenwellies',
    arbor: 'object.arbor',
    koiPond: 'object.koipond',
    birdhouse: 'object.birdhouse',
    wateringCan: 'object.wateringcan',
    arch: 'object.arch',
    birdFeeder: 'object.birdfeeder',
    bunny: 'object.bunny',
    charles: 'object.dukethedog',
    gardenTools: 'object.gardentools',
    ladyTheLadybug: 'object.ladytheladybug',
    lawnMower: 'object.lawnmower',
    gardenSeeds: 'object.gardenseeds',
    gardenShed: 'object.gardenshed'
} as const

export type GardenObjectTypes =
    (typeof GardenObjectTypes)[keyof typeof GardenObjectTypes]
