import {
    Card,
    CardActions,
    Icon,
    Link,
    Stack,
    useMediaQuery
} from '@mui/material'
import { claimAchievement, useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import {
    AchievementProgress,
    AchievementV2
} from '../../../../graphql/generated/autogenerated'
import {
    referralEmailStandard,
    referralEmailStandardText
} from '../../../../utils/referralEmail'
import { useAchievementIcons } from '../../../../hooks/useAchievementIcons/useAchievementIcons'
import {
    AchievementReward,
    useGetReward
} from '../../../../hooks/useGetReward/useGetReward'
import AchievementMilestoneProgress from '../AchievementMilestoneProgress/AchievementMilestoneProgress'
import AchievementRewardDialog from '../AchievementRewardDialog/AchievementRewardDialog'
import AchievementRewardMobilePrompt from '../AchievementRewardMobilePrompt/AchievementRewardMobilePrompt'

export interface AchievementMilestoneProps {
    achievement: AchievementV2
    progress: AchievementProgress
    message: string
    milestones: number[]
    maxLevel: number
}

const AchievementMilestone: React.FC<AchievementMilestoneProps> = ({
    achievement,
    progress,
    message,
    milestones,
    maxLevel
}) => {
    const { formatNumber, formatMessage } = useIntl()
    const { palette, breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const getAchievementIcon = useAchievementIcons()
    const [loading, setLoading] = useState(false)
    const { getAchievementReward } = useGetReward()
    const [showReferralRewardDialog, setShowReferralRewardDialog] =
        useState<boolean>(false)
    const [showMobilePrompt, setShowMobilePrompt] = useState<boolean>(false)
    const [achievementReward, setAchievementReward] =
        useState<AchievementReward>()
    const { fullName, email } = useAppSelector((state) => ({
        fullName: state.user.fullName,
        email: state.user.email
    }))

    const currentLevel = useMemo(
        () =>
            progress.levels
                .slice()
                .reverse()
                .find((level) => !level?.completedAt)?.level || 0,
        [progress?.levels]
    )

    const unclaimedLevels = useMemo(() => {
        const nextMilestone = milestones.find(
            (milestone) =>
                !progress.levels.some(
                    (level) => level.total >= milestone && level.claimedAt
                )
        )

        if (nextMilestone === undefined) {
            return []
        }

        const eligibleUnclaimedLevels = progress.levels
            .filter(
                (level) =>
                    !level.claimedAt &&
                    level.completedAt &&
                    level.total <= nextMilestone
            )
            .map((level) => level.total)

        return eligibleUnclaimedLevels.includes(nextMilestone)
            ? eligibleUnclaimedLevels
            : []
    }, [progress.levels, milestones])

    const lastMilestoneReached = useMemo(() => {
        return (
            milestones
                .filter((goal) => goal <= currentLevel)
                .reverse()
                .find((goal) =>
                    progress?.levels.some(
                        (level) =>
                            level.total === goal &&
                            level.completedAt &&
                            level.claimedAt
                    )
                ) || 0
        )
    }, [currentLevel, milestones, progress?.levels])

    const onClaim = useCallback(() => {
        setLoading(true)
        claimAchievement(achievement.id, unclaimedLevels).then(() => {
            unclaimedLevels?.forEach((level) => {
                const achievementReward = getAchievementReward({
                    achievementInfo: achievement,
                    userProgress: { level: level }
                })
                if (achievementReward) {
                    setAchievementReward(achievementReward)
                    setShowReferralRewardDialog(true)
                }

                Avo.achievementCompleted({
                    achievementId: achievement.id,
                    activityType: 'achievement_claimed',
                    featureType: 'achievement',
                    level: level,
                    source: 'referral'
                })
            })
            setLoading(false)
        })
    }, [unclaimedLevels, achievement, getAchievementReward])

    const onContactClick = useCallback(() => {
        if (isMobile) {
            setShowMobilePrompt(true)
        } else {
            return
        }
    }, [isMobile])

    return (
        <React.Fragment>
            <Stack>
                <Card variant="outlined">
                    <Stack
                        direction={isMobile ? 'column' : 'row'}
                        px={3}
                        py={2}
                        gap={3}
                    >
                        <Stack
                            direction={isMobile ? 'column' : 'row'}
                            gap={3}
                            flex={1}
                        >
                            <Stack
                                alignItems="center"
                                justifyContent="center"
                                gap={0.625}
                            >
                                <Icon
                                    titleAccess={achievement.title}
                                    component={getAchievementIcon(achievement)}
                                    sx={{ width: 56.8, height: 64 }}
                                />
                                <CoreTypography
                                    variant="overline"
                                    color="text.primary"
                                >
                                    <FormattedMessage
                                        defaultMessage="Level {level}"
                                        description="achievement level"
                                        values={{
                                            level: formatNumber(currentLevel)
                                        }}
                                    />
                                </CoreTypography>
                            </Stack>
                            <Stack gap={2}>
                                <Stack gap={1}>
                                    <CoreTypography variant="overline">
                                        {achievement.title}
                                    </CoreTypography>
                                    <CoreTypography variant="caption">
                                        {message}
                                    </CoreTypography>
                                </Stack>
                                <AchievementMilestoneProgress
                                    currentLevel={currentLevel}
                                    milestones={milestones}
                                    maxLevel={maxLevel}
                                />
                            </Stack>
                        </Stack>
                        <Stack alignItems="center" justifyContent="center">
                            <LoadingButton
                                variant="text"
                                loading={loading}
                                onClick={onClaim}
                                disabled={unclaimedLevels.length === 0}
                                fixWidth
                            >
                                <CoreTypography customVariant="buttonNormal">
                                    <FormattedMessage
                                        defaultMessage="Claim"
                                        description="claim achievements button"
                                    />
                                </CoreTypography>
                            </LoadingButton>
                        </Stack>
                    </Stack>
                    {lastMilestoneReached > 0 && (
                        <CardActions sx={{ p: 0 }}>
                            <Stack
                                direction="row"
                                alignItems="center"
                                bgcolor={palette.secondary.main}
                                width="100%"
                                py={2.25}
                                px={3}
                            >
                                <Stack flex={1}>
                                    <CoreTypography variant="caption">
                                        <FormattedMessage
                                            defaultMessage="Way to go! You reached level {level}, contact us to claim your prize!"
                                            description="achievement level reached message"
                                            values={{
                                                level: formatNumber(
                                                    lastMilestoneReached
                                                )
                                            }}
                                        />
                                    </CoreTypography>
                                </Stack>
                                <Link
                                    onClick={onContactClick}
                                    href={referralEmailStandard(
                                        lastMilestoneReached,
                                        achievement.title,
                                        fullName,
                                        email,
                                        formatMessage,
                                        formatNumber
                                    )}
                                >
                                    <CoreTypography variant="body2">
                                        <FormattedMessage
                                            defaultMessage="Contact us"
                                            description="contact us link"
                                        />
                                    </CoreTypography>
                                </Link>
                            </Stack>
                        </CardActions>
                    )}
                </Card>
            </Stack>
            {isMobile && (
                <AchievementRewardMobilePrompt
                    open={showMobilePrompt}
                    onClose={() => setShowMobilePrompt(false)}
                    text={referralEmailStandardText(
                        lastMilestoneReached,
                        achievement.title,
                        fullName,
                        email,
                        formatMessage,
                        formatNumber
                    )}
                />
            )}
            <AchievementRewardDialog
                achievementReward={achievementReward}
                open={showReferralRewardDialog}
                onClose={() => setShowReferralRewardDialog(false)}
            />
        </React.Fragment>
    )
}

export default memo(AchievementMilestone)
