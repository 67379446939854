import { defineMessages } from 'react-intl'

export const LEVELS_TEXT = defineMessages({
    rareLevel: {
        defaultMessage: 'Rare',
        description: 'rare level'
    },
    commonLevel: {
        defaultMessage: 'Common',
        description: 'common level'
    },
    epicLevel: {
        defaultMessage: 'Epic',
        description: 'epic level'
    }
})

export const getLevelForActions = (actions = 0) => {
    switch (true) {
        case actions >= 15:
            return {
                text: LEVELS_TEXT.epicLevel,
                value: 2
            }
        case actions >= 10:
            return {
                text: LEVELS_TEXT.rareLevel,
                value: 1
            }
        case actions >= 5:
        default:
            return {
                text: LEVELS_TEXT.commonLevel,
                value: 0
            }
    }
}

export const getLevelForCost = (cost = 0) => {
    switch (true) {
        case cost >= 1500:
            return {
                text: LEVELS_TEXT.epicLevel,
                value: 2
            }
        case cost >= 500:
            return {
                text: LEVELS_TEXT.rareLevel,
                value: 1
            }
        case cost < 500:
        default:
            return {
                text: LEVELS_TEXT.commonLevel,
                value: 0
            }
    }
}
