import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import { useCallback } from 'react'
import { AchievementV2 } from '../../graphql/generated/autogenerated'
import { AchievementTypes } from '../../enums'
import { defineMessages, useIntl } from 'react-intl'

const messages = defineMessages({
    profile: {
        defaultMessage:
            'navigate to profile to level {achievementName} achievement',
        description: 'description of the navigate'
    },
    reset: {
        defaultMessage:
            'navigate to resets to level {achievementName} achievement',
        description: 'description of the navigate'
    }
})

export const useAchievementLinks = () => {
    const navigate = useCrossAppNavigation()
    const { formatMessage } = useIntl()

    const getAchievementLink = useCallback(
        (achievement: AchievementV2) => {
            switch (achievement?.actionType) {
                case AchievementTypes.referralCompleted:
                    return {
                        link: () => navigate('/profile/invite'),
                        label: formatMessage(messages.profile, {
                            achievementName: achievement?.title
                        })
                    }
                case AchievementTypes.resetWatched:
                    return {
                        link: () => navigate('reset'),
                        label: formatMessage(messages.reset, {
                            achievementName: achievement?.title
                        })
                    }
                default:
                    return { link: null, label: null }
            }
        },
        [navigate, formatMessage]
    )

    return getAchievementLink
}

export default useAchievementLinks
