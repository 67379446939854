import { ApolloError, ApolloQueryResult } from '@apollo/client'
import { Apollo } from '@thriveglobal/thrive-web-core'
import { useCallback, useMemo } from 'react'
import {
    GetUserFlagsQuery,
    SetUserFlagMutation,
    UserFlag,
    useGetUserFlagsQuery,
    useSetUserFlagMutation
} from '../../graphql/generated/autogenerated'
import { GET_USER_FLAGS } from '../../graphql/queries/achievements'

export enum FlagKey {
    LAST_ACHIEVEMENT_BANNER = 'lastAchievementBanner',
    VIEWED_V2_HOW_TO_MODAL = 'viewedV2HowToModal',
    MISSED_PLANT_GROWTH_COUNTER = 'missedPlantGrowthCounter',
    ONBOARDING_PLANT_INTERACTED = 'onboardingPlantInteracted',
    VIEWED_FIRST_PLANT_CELEBRATION = 'viewedFirstPlantCelebration',
    ACHIEVEMENTS_PREVIOUS_PROGRESS = 'previousAchievementsProgress'
}

export interface FlagStoreData {
    flags: UserFlag[]
    loading: boolean
    error: ApolloError | undefined
    hasFlag: (key: FlagKey) => boolean
    setFlag: (
        key: string,
        value: string
    ) => Promise<Apollo.FetchResult<SetUserFlagMutation>>
    getFlag: (key: FlagKey) => string | undefined
    refetch: () => Promise<ApolloQueryResult<GetUserFlagsQuery>>
}

export const useFlagStore = () => {
    const { data, loading, error, refetch } = useGetUserFlagsQuery({
        fetchPolicy: 'network-only',
        nextFetchPolicy: 'cache-and-network'
    })
    const [setUserFlagMutation] = useSetUserFlagMutation({
        refetchQueries: [GET_USER_FLAGS]
    })

    const flags = useMemo(
        () => data?.achievements?.getUserFlags as UserFlag[],
        [data?.achievements?.getUserFlags]
    )

    const hasFlag = useCallback(
        (key: FlagKey) =>
            flags?.some((flag) => flag.key === key && !!flag?.value),
        [flags]
    )

    const setFlag = useCallback(
        (key: string, value: string) =>
            setUserFlagMutation({
                variables: { key, value: value }
            }),
        [setUserFlagMutation]
    )

    const getFlag = useCallback(
        (key: FlagKey) => flags?.find((flag) => flag.key === key)?.value,
        [flags]
    )

    return useMemo<FlagStoreData>(() => {
        return { flags, loading, error, hasFlag, setFlag, getFlag, refetch }
    }, [flags, loading, error, hasFlag, setFlag, getFlag, refetch])
}

export default useFlagStore
