import { useMemo } from 'react'
import { GardenObjectTypes } from '../../enums/gardenObjectTypes'

const GARDEN_OBJECT_TO_UPC: Record<GardenObjectTypes, string> = {
    [GardenObjectTypes.waterPail]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c8958727-4bbf-4785-a353-da238091b900/cms',
    [GardenObjectTypes.wishingWell]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/1825be90-1d8d-4b05-c6e5-575147f1ec00/cms',
    [GardenObjectTypes.gardenWellies]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/879294e7-8b2b-4059-59b5-658880cda900/cms',
    [GardenObjectTypes.arbor]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/be060da8-17e9-4201-f96b-0dc4eafbb400/cms',
    [GardenObjectTypes.koiPond]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/9efa6fd4-790a-4dee-40be-ca9174ec0100/cms',
    [GardenObjectTypes.birdhouse]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/c5d1546d-3714-4716-f0a5-198980b49a00/cms',
    [GardenObjectTypes.wateringCan]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/0211bce2-9233-47c2-af81-1eb7a42e6400/cms',
    [GardenObjectTypes.arch]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/11b32bda-7532-4a3b-0986-e291e4fabc00/cms',
    [GardenObjectTypes.birdFeeder]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/35d07f8e-2adb-4221-d1dd-d8074f4da800/cms',
    [GardenObjectTypes.bunny]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/13be99e7-358b-4041-55e6-b35d75355c00/cms',
    [GardenObjectTypes.charles]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/2d3b6cd2-a2fe-41c8-ef65-ebe57d880500/cms',
    [GardenObjectTypes.gardenTools]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/d6b1f48f-7627-41dc-5830-98645361da00/cms',
    [GardenObjectTypes.ladyTheLadybug]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/6cbf98a0-5eca-45c8-909f-0381d420cf00/cms',
    [GardenObjectTypes.lawnMower]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/2be611c2-ada5-46e3-bbdf-8ecc2df5f600/cms',
    [GardenObjectTypes.gardenSeeds]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/591f57fb-fd1b-4fd2-dd6e-acf89d2b9700/cms',
    [GardenObjectTypes.gardenShed]:
        'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/38887e86-d000-4537-2d55-f70bc5a81b00/cms'
}

export const useGardenObjectIcons = () => {
    const getObjectSrc = (upc: GardenObjectTypes) => {
        return GARDEN_OBJECT_TO_UPC[upc]
    }

    return useMemo(() => ({ getObjectSrc }), [])
}
