import {
    Box,
    Button,
    DialogContent,
    IconButton,
    Popover,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { UserPlantInfo } from '../../../../graphql/generated/autogenerated'
import { ROUTES } from '../../../../routes'
import { MappedAchievement, useAchievementsContext } from '../../../../hooks'
import AchievementsCenter from '../AchievementsCenter/AchievementsCenter'

export type AchievementsCenterMenuProps = {
    isOpen: boolean
    handleOnClose: () => void
    achievements: MappedAchievement[]
    activePlants: UserPlantInfo[]
    tokenCount: number
    isPopover?: boolean
}

const messages = defineMessages({
    yourAchievements: {
        defaultMessage: 'Your achievements',
        description: 'title of the achievements center modal'
    },
    yourGarden: {
        defaultMessage: 'Your garden',
        description: 'title of the achievements center modal'
    },
    viewGardenButton: {
        defaultMessage: 'View garden',
        description: 'button to view garden'
    }
})

const AchievementsCenterMenu: React.FC<AchievementsCenterMenuProps> = ({
    isOpen,
    handleOnClose,
    achievements,
    activePlants,
    tokenCount,
    isPopover
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const history = useHistory()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [hasFiredEvent, setHasFiredEvent] = useState(false)
    const { fetchAchievements } = useAchievementsContext()

    useEffect(() => {
        if (isOpen && !hasFiredEvent) {
            setHasFiredEvent(true)
            fetchAchievements()
        }
    }, [isOpen, hasFiredEvent, activePlants, tokenCount, fetchAchievements])

    const onHandleClose = useCallback(() => {
        setHasFiredEvent(false)

        return handleOnClose()
    }, [handleOnClose])

    const handleViewGarden = useCallback(() => {
        history.push(ROUTES.GARDEN, {
            previousLocation: location.pathname
        })
        handleOnClose()
    }, [handleOnClose, history])

    return (
        <>
            {isMobile && isPopover && (
                <LeafDialog
                    open={isOpen}
                    fullScreen={true}
                    onClose={onHandleClose}
                    dialogTitle={formatMessage(messages.yourGarden)}
                >
                    <DialogContent>
                        <Stack overflow={'visible'} gap={2} mt={2}>
                            <Box>
                                <Button
                                    onClick={handleViewGarden}
                                    variant="text"
                                    endIcon={
                                        <LeafIcon
                                            icon="arrow-right"
                                            fontSize="small"
                                        />
                                    }
                                >
                                    <CoreTypography customVariant="buttonNormal">
                                        {formatMessage(
                                            messages.viewGardenButton
                                        )}
                                    </CoreTypography>
                                </Button>
                            </Box>

                            <AchievementsCenter />
                        </Stack>
                    </DialogContent>
                </LeafDialog>
            )}

            {!isMobile && isPopover && (
                <Popover
                    data-testid="achievement-center"
                    open={isOpen}
                    onClose={handleOnClose}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 75, left: window.innerWidth - 100 }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    PaperProps={{
                        style: {
                            position: 'relative',
                            borderWidth: 1,
                            borderColor: theme.palette.divider,
                            width: isMobile ? '100%' : 330
                        }
                    }}
                >
                    <Stack
                        p={2}
                        sx={{
                            overflowY: 'scroll',
                            scrollSnapType: 'x mandatory',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            }
                        }}
                    >
                        <Stack gap={2}>
                            <CoreTypography variant="h4">
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    {formatMessage(messages.yourGarden)}
                                    <Box position="relative">
                                        <Box
                                            position="absolute"
                                            top={-20}
                                            right={0}
                                        >
                                            <IconButton
                                                aria-label={formatMessage(
                                                    messages.viewGardenButton
                                                )}
                                                color="inherit"
                                                sx={{ minWidth: 0 }}
                                                onClick={handleViewGarden}
                                            >
                                                <LeafIcon icon="arrow-right" />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                </Stack>
                            </CoreTypography>
                            <AchievementsCenter />
                        </Stack>
                    </Stack>
                </Popover>
            )}

            {!isPopover && <AchievementsCenter />}
        </>
    )
}

export default AchievementsCenterMenu
