import React from 'react'

export const connect = React.lazy(() => import('./connect'))
export const dailyReflection = React.lazy(() => import('./daily-reflection'))
export const stress = React.lazy(() => import('./stress'))
export const focus = React.lazy(() => import('./focus'))
export const food = React.lazy(() => import('./food'))
export const microstepper = React.lazy(() => import('./microstepper'))
export const mindfulMoments = React.lazy(() => import('./mindful-moments'))
export const money = React.lazy(() => import('./money'))
export const move = React.lazy(() => import('./move'))
export const recharge = React.lazy(() => import('./recharge'))
export const referral = React.lazy(() => import('./referral'))
