import {
    fetchAndStoreAchievements,
    fetchAndStoreGarden,
    useAppSelector
} from '@thriveglobal/thrive-web-core'
import debounce from 'lodash/debounce'
import { useMemo } from 'react'
import { PlantType } from '../../enums/plantTypes'
import useFlagStore, { FlagKey } from '../useFlagStore/useFlagStore'

export const AutoWaterCounter = {
    MAX: 5,
    MIN: 0
} as const

export const useAutoWaterPlants = (debounceMs = 300) => {
    const { activePlants, plantedPlants } = useAppSelector(
        (state) => state.garden
    )
    const { getFlag, setFlag, hasFlag } = useFlagStore()

    return useMemo(() => {
        return debounce(() => {
            // If there is no active plant and the user has no planted plants, increment missed plant growth counter
            const hasCounter = hasFlag(FlagKey.MISSED_PLANT_GROWTH_COUNTER)
            const plantedExcludingOnboarding = plantedPlants.filter(
                (p) => p.productUpc !== PlantType.novafutura
            )
            const shouldUpdateFlag =
                !activePlants[0] &&
                plantedExcludingOnboarding.length < 1 &&
                hasCounter

            if (shouldUpdateFlag) {
                const flag = getFlag(FlagKey.MISSED_PLANT_GROWTH_COUNTER)
                const counter = Number(flag)
                setFlag(
                    FlagKey.MISSED_PLANT_GROWTH_COUNTER,
                    String(counter + 1)
                )
            } else if (!hasCounter) {
                setFlag(
                    FlagKey.MISSED_PLANT_GROWTH_COUNTER,
                    String(AutoWaterCounter.MAX)
                )
            }

            // Refetch Achievements and Garden
            fetchAndStoreGarden()
            fetchAndStoreAchievements()
        }, debounceMs)
    }, [hasFlag, getFlag, setFlag, debounceMs, activePlants, plantedPlants])
}
