import { List, useMediaQuery } from '@mui/material'
import React, { memo, useEffect, useRef, useState } from 'react'
import { AchievementV3Progress } from '../../../../graphql/generated/autogenerated'
import { PreviousAchievementsProgress } from '../../../../hooks/usePreviousAchievementsV3Progress/usePreviousAchievementsV3Progress'
import AchievementsV3ProgressListItem from './AchievementsV3ProgressListItem'
import { useAchievementsV3Progress } from './useAchievementsV3Progress'

type AchievementsV3ProgressListProps = {
    progress: AchievementV3Progress[]
    previousProgress?: PreviousAchievementsProgress | null
}

const AchievementsV3ProgressList: React.FC<AchievementsV3ProgressListProps> = ({
    progress,
    previousProgress
}) => {
    const listRef = useRef<HTMLUListElement>(null)
    const prefersReducedMotion = useMediaQuery(
        '(prefers-reduced-motion: reduce)'
    )

    const processedProgress = useAchievementsV3Progress(
        progress,
        previousProgress,
        !prefersReducedMotion
    )

    const [visibleAchievements, setVisibleAchievements] =
        useState(processedProgress)

    useEffect(() => {
        setVisibleAchievements(processedProgress)
    }, [processedProgress])

    return (
        <List
            ref={listRef}
            sx={{
                padding: 0,
                height: listRef.current?.clientHeight || undefined
            }}
        >
            {visibleAchievements.slice(0, 3).map((a, idx) => (
                <AchievementsV3ProgressListItem
                    key={a.upc}
                    index={idx}
                    achievementInfo={a.achievementInfo}
                    currentProgress={a.currentProgress}
                    previousProgress={
                        previousProgress?.progress.find(
                            (prev) => a.upc === prev.upc
                        )?.progress || a.currentProgress
                    }
                    sx={{ mb: 1 }}
                />
            ))}
        </List>
    )
}

export default memo(AchievementsV3ProgressList)
