import {
    useCallback,
    useEffect,
    useLayoutEffect,
    useRef,
    useState
} from 'react'

// When using the below hook make sure to wrap the Refs in a useCallback thats sets
// Since just setting a ref does not cause a re-render a useCallback is needed to trigger it to get the correct dimensions
export const useActiveRefDimensions = (activeKey = 'single-component') => {
    const componentsRef = useRef({})
    const [activeComponentWidth, setActiveComponentWidth] = useState(0)
    const [activeComponentHeight, setActiveComponentHeight] = useState(0)

    const calculateComponentDimensions = useCallback(() => {
        const calculate = () => {
            const currentRef = componentsRef.current[activeKey]

            if (currentRef) {
                setActiveComponentWidth(currentRef.offsetWidth / 8)
                setActiveComponentHeight(currentRef.offsetHeight / 8)
            } else {
                setActiveComponentWidth(0)
                setActiveComponentHeight(0)
            }
        }

        calculate()
    }, [activeKey, componentsRef])

    useEffect(() => {
        calculateComponentDimensions()
    }, [activeKey, calculateComponentDimensions])

    const getRef = useCallback(
        (element, key = 'single-component') => {
            if (element !== null) {
                componentsRef.current[key] = element
            }
        },
        [componentsRef]
    )

    useLayoutEffect(() => {
        window.addEventListener('resize', calculateComponentDimensions)
        calculateComponentDimensions()

        return () =>
            window.removeEventListener('resize', calculateComponentDimensions)
    }, [calculateComponentDimensions])

    useEffect(() => {
        const currentRef = componentsRef.current[activeKey]

        if (currentRef) {
            const resizeObserver = new ResizeObserver((entries) => {
                window.requestAnimationFrame(() => {
                    if (!Array.isArray(entries) || !entries.length) {
                        return
                    }

                    calculateComponentDimensions()
                })
            })
            resizeObserver.observe(currentRef)
            return () => resizeObserver.disconnect()
        }
    }, [componentsRef, activeKey, calculateComponentDimensions])

    return {
        activeComponentWidth,
        activeComponentHeight,
        ref: componentsRef?.current?.[activeKey],
        getRef,
        calculateComponentDimensions
    }
}

export default useActiveRefDimensions
