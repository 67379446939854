import {
    fetchAndStoreWallet,
    fetchAndStoreAchievements,
    setTokens,
    setClearWalletRefetch,
    setAchievementProgress,
    store,
    useAppSelector
} from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { UserProgressDetail } from '../withAchievementsProvider'

export const useClaimTokens = () => {
    const { tokens, clearWalletRefetch, progress } = useAppSelector(
        (state) => ({
            tokens: state.wallet.tokens,
            clearWalletRefetch: state.wallet.clearWalletRefetch,
            progress: state.achievement.progress
        })
    )
    const [tokenCount, setTokenCount] = useState(tokens)
    const [keepRefetchActive, setKeepRefetchActive] = useState(false)
    const timer = useRef(null)

    useEffect(() => {
        if (tokens !== tokenCount) {
            setTokenCount(tokens)
        }
    }, [tokens, tokenCount])

    // We listen for any other components that have triggered a claim
    // If we have triggered a claim beforehand it should clear out the refetch so it doesn't retch more than once
    // If the refetch was triggered from here we just wait for the flag in the store to be set back to false and mark this as not the active refetch without clearing the timeout
    useEffect(() => {
        if (clearWalletRefetch && !keepRefetchActive && timer.current) {
            clearTimeout(timer.current)
        } else if (!clearWalletRefetch) {
            setKeepRefetchActive(false)
        }

        setTimeout(() => store.dispatch(setClearWalletRefetch(false)), 50)
    }, [clearWalletRefetch, keepRefetchActive])

    // We wait a period of time before fetching to make sure everything has lined up correctly on the BE
    // Since a user can spam claim achievements we also reset the timer each time to block spamming the query
    const fetchAchievementDataOnTimeout = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current)
        }

        // On fetch we want to make sure any other components that have made achievement claims clear their refetch so we don't have multiple refetches triggering
        // But we mark this refetch as active so the useEffect does not clear the current active Refetch
        setKeepRefetchActive(true)
        store.dispatch(setClearWalletRefetch(true))

        timer.current = setTimeout(() => {
            fetchAndStoreWallet()
            fetchAndStoreAchievements()
        }, 3000)
    }, [])

    const claimTokens = useCallback(
        (tokens) => {
            store.dispatch(setTokens(tokenCount + tokens))
            fetchAchievementDataOnTimeout()
        },
        [tokenCount, fetchAchievementDataOnTimeout]
    )

    const claimAchievementLevels = useCallback(
        (userProgressDetails: UserProgressDetail[], skipFetch?: boolean) => {
            const updatedProgresses = JSON.parse(JSON.stringify(progress))

            userProgressDetails?.forEach((userProgressDetail) => {
                const claimedLevel = updatedProgresses
                    ?.find(
                        (updatedProgress) =>
                            updatedProgress.achievementId ===
                            userProgressDetail.achievementId
                    )
                    ?.levels?.find(
                        (level) => level.level === userProgressDetail.level
                    )
                claimedLevel.claimedAt = new Date()
            })

            store.dispatch(setAchievementProgress(updatedProgresses))

            if (!skipFetch) {
                fetchAchievementDataOnTimeout()
            }
        },
        [progress, fetchAchievementDataOnTimeout]
    )

    return {
        tokenCount,
        claimTokens,
        claimAchievementLevels,
        fetchAchievementDataOnTimeout
    }
}

export default useClaimTokens
