import { Stack, useMediaQuery } from '@mui/material'
import {
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import React, { memo } from 'react'
import PlantsImage from './PlantsImage'

type InformationalPlantsDialogProps = {
    open: boolean
    onClose: () => void
    title: React.ReactNode
    body: React.ReactNode
}

const InformationalPlantsDialog: React.FC<InformationalPlantsDialogProps> = ({
    open,
    onClose,
    title,
    body
}) => {
    const { breakpoints } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))

    return (
        <LeafDialog
            open={open}
            maxWidth="md"
            onClose={onClose}
            dialogTitle={<CoreTypography variant="h3">{title}</CoreTypography>}
            PaperProps={{
                sx: {
                    overflow: 'hidden'
                }
            }}
        >
            <Stack width={624} maxWidth="100%" pt={1.5}>
                <CoreTypography>{body}</CoreTypography>
            </Stack>
            <Stack pb={isMobile ? 10 : 15}>
                <Stack
                    position="absolute"
                    bottom={0}
                    left={-3}
                    width="calc(100% + 6px)"
                >
                    <PlantsImage />
                </Stack>
            </Stack>
        </LeafDialog>
    )
}

export default memo(InformationalPlantsDialog)
