import { DialogContent, Stack } from '@mui/material'
import { CoreTypography, LeafDialog } from '@thriveglobal/thrive-web-leafkit'
import React from 'react'
import { FormattedMessage } from 'react-intl'

interface AchievementRewardMobilePromptProps {
    open: boolean
    text: React.ReactNode
    onClose: () => void
}

const AchievementRewardMobilePrompt: React.FC<
    AchievementRewardMobilePromptProps
> = ({ open, text, onClose }) => {
    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            maxWidth="md"
            dialogTitle={
                <CoreTypography variant="h4">
                    <FormattedMessage
                        defaultMessage="Contact us"
                        description="title of the contact us mobile reward prompt dialog"
                    />
                </CoreTypography>
            }
            PaperProps={{ sx: { width: 672 } }}
        >
            <DialogContent>{text}</DialogContent>
        </LeafDialog>
    )
}

export default AchievementRewardMobilePrompt
