import { ReactNullValue } from '../Nulls'

export const promptSelectedDefaultValues = {
    tabName: ReactNullValue,
    notificationTime: ReactNullValue,
    resetId: ReactNullValue,
    resetName: ReactNullValue,
    isOnboarding: ReactNullValue,
    onboardingAttemptNumber: ReactNullValue,
    userId_: ReactNullValue,
    contentFormatType: ReactNullValue,
    contentId: ReactNullValue,
    contentSource: ReactNullValue,
    contentSubtype: ReactNullValue,
    contentTitle: ReactNullValue,
    contentType: ReactNullValue,
    contentUrl: ReactNullValue,
    displayText: ReactNullValue,
    tstPlatform: ReactNullValue,
    notificationCount: ReactNullValue,
    microstepId: ReactNullValue,
    microstepBody: ReactNullValue,
    isAutomaticCheckin: ReactNullValue,
    microstepName: ReactNullValue,
    checkInDate: ReactNullValue
}
