import { useAppSelector } from '@thriveglobal/thrive-web-core'
import { useCallback, useEffect, useState } from 'react'
import { AutoWaterCounter } from '../../../hooks/useAutoWaterPlants/useAutoWaterPlants'
import { FlagKey, useFlagStore } from '../../../hooks/useFlagStore/useFlagStore'
import PlantSelectorDialog from '../PlantSelectorDialog/PlantSelectorDialog'

type PlantSelectorProps = {
    open?: boolean
    onClose?: (dailyLimitReached?: boolean) => void
    autoShow?: boolean
}

const PlantSelector: React.FC<PlantSelectorProps> = ({
    open,
    onClose,
    autoShow = false
}) => {
    const [openDialog, setOpenDialog] = useState(false)

    const { getFlag, setFlag, loading: loadingFlags } = useFlagStore()
    const { activePlants } = useAppSelector((state) => state.garden)

    const handleClose = useCallback(
        (dailyLimitReached?: boolean) => {
            setFlag(
                FlagKey.MISSED_PLANT_GROWTH_COUNTER,
                String(AutoWaterCounter.MIN)
            )
            setOpenDialog(false)
            onClose?.(dailyLimitReached)
        },
        [onClose, setFlag]
    )

    useEffect(() => {
        if (activePlants[0] || loadingFlags || !autoShow) return

        const missedPlantGrowthCounter = getFlag(
            FlagKey.MISSED_PLANT_GROWTH_COUNTER
        )
        // if the user made progress on their microstep or resets show the modal
        if (Number(missedPlantGrowthCounter) >= AutoWaterCounter.MAX) {
            setOpenDialog(true)
        }
    }, [activePlants, getFlag, loadingFlags, autoShow])

    useEffect(() => {
        if (open !== undefined) setOpenDialog(open)
    }, [open])

    return <PlantSelectorDialog open={openDialog} onClose={handleClose} />
}

export default PlantSelector
