import React from 'react'
import {
    useAchievementsContext,
    useClaimTokens,
    usePlantsContext
} from '../../../../hooks'
import AchievementsCenterMenu from './AchievementsCenterMenu'

export type AchievementsCenterMenuContextProps = {
    isOpen: boolean
    isPopover?: boolean
    handleOnClose: () => void
}

const AchievementsCenterMenuContext: React.FC<
    AchievementsCenterMenuContextProps
> = ({ isOpen, handleOnClose, isPopover = true }) => {
    const { achievements } = useAchievementsContext()
    const { activePlants } = usePlantsContext()
    const { tokenCount } = useClaimTokens()

    return (
        <AchievementsCenterMenu
            isOpen={isOpen}
            handleOnClose={handleOnClose}
            achievements={achievements}
            activePlants={activePlants}
            tokenCount={tokenCount}
            isPopover={isPopover}
        />
    )
}

export default AchievementsCenterMenuContext
