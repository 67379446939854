import { Box, Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { usePlantsContext } from '../../../../hooks'
import Plant from '../Plant/Plant'

const MESSAGES = defineMessages({
    wayToGrow: {
        defaultMessage: 'Way to grow!',
        description: 'title of when the user has grown their plant'
    }
})

const ResetEmbeddedPlant = () => {
    const { activePlants, products } = usePlantsContext()
    const { formatMessage } = useIntl()

    const product = useMemo(
        () => products.find((p) => p.upc === activePlants[0].productUpc),
        [products, activePlants]
    )

    return (
        <Stack width="100%" alignItems="center">
            <Box width="100%">
                <CoreTypography customVariant="form">
                    {formatMessage(MESSAGES.wayToGrow)}
                </CoreTypography>
            </Box>
            <Box maxWidth={200} maxHeight={200}>
                <Plant plant={activePlants[0]} product={product} showSway />
            </Box>
        </Stack>
    )
}

export default ResetEmbeddedPlant
