import groupBy from 'lodash/groupBy'
import orderBy from 'lodash/orderBy'
import { ComponentType, useMemo, useState } from 'react'
import { ProductInfo } from '../../graphql/generated/autogenerated'
import { ProductTypes } from '../../enums'
import ShopProviderContext, { ShopProviderValue } from './shopProviderContext'

export default function withShopProvider<Props>(
    Component: ComponentType<Props>
) {
    return (props: Props): JSX.Element => {
        const [products, setProducts] = useState<ProductInfo[]>([])
        const [hasActivePlant, setHasActivePlant] = useState(false)

        const filteredPlantProducts = useMemo(() => {
            const filtered = products?.filter(
                (product) => product.productType === ProductTypes.Plant
            )
            const ordered = orderBy(filtered, ['cost'], ['asc'])
            const grouped = groupBy(ordered, 'title')
            const structured = []

            Object.entries(grouped).map(([_, value]) => {
                structured.push({
                    title: value[0].title,
                    products: value
                })
            })
            return structured
        }, [products])

        const filteredObjectProducts = useMemo(() => {
            const filtered = products?.filter(
                (product) => product.productType === ProductTypes.Object
            )
            const ordered = orderBy(filtered, ['cost'], ['asc'])
            const grouped = groupBy(ordered, 'title')
            const structured = []

            Object.entries(grouped).map(([_, value]) => {
                structured.push({
                    title: value[0].title,
                    products: value
                })
            })
            return structured
        }, [products])

        const state = useMemo<ShopProviderValue>(
            () => ({
                plantProducts: filteredPlantProducts,
                objectProducts: filteredObjectProducts,
                setProducts,
                hasActivePlant,
                setHasActivePlant
            }),
            [filteredPlantProducts, filteredObjectProducts, hasActivePlant]
        )

        return (
            <ShopProviderContext.Provider value={state}>
                <Component {...props} />
            </ShopProviderContext.Provider>
        )
    }
}
