export enum PlantIcons {
    // Trophies
    ReferralTrophyPlant = 'referral-trophy-plant-1',
    PersonalResetTrophyPlant = 'personalreset',
    // Plants
    Seed = 'seed',
    Beginner_1 = 'beginner1',
    Beginner_2 = 'beginner2',
    Beginner_3 = 'beginner3',
    Beginner_4 = 'beginner4',
    Beginner_5 = 'beginner5',
    Beginner_Rare_1 = 'beginner.rare1',
    Beginner_Rare_2 = 'beginner.rare2',
    Beginner_Rare_3 = 'beginner.rare3',
    Beginner_Rare_4 = 'beginner.rare4',
    Beginner_Rare_5 = 'beginner.rare5',
    Intermediate_1 = 'intermediate1',
    Intermediate_2 = 'intermediate2',
    Intermediate_3 = 'intermediate3',
    Intermediate_4 = 'intermediate4',
    Intermediate_5 = 'intermediate5',
    Intermediate_6 = 'intermediate6',
    Intermediate_7 = 'intermediate7',
    Intermediate_8 = 'intermediate8',
    Intermediate_9 = 'intermediate9',
    Intermediate_10 = 'intermediate10',
    Intermediate_Rare_1 = 'intermediate.rare1',
    Intermediate_Rare_2 = 'intermediate.rare2',
    Intermediate_Rare_3 = 'intermediate.rare3',
    Intermediate_Rare_4 = 'intermediate.rare4',
    Intermediate_Rare_5 = 'intermediate.rare5',
    Intermediate_Rare_6 = 'intermediate.rare6',
    Intermediate_Rare_7 = 'intermediate.rare7',
    Intermediate_Rare_8 = 'intermediate.rare8',
    Intermediate_Rare_9 = 'intermediate.rare9',
    Intermediate_Rare_10 = 'intermediate.rare10',
    Advanced_1 = 'advanced1',
    Advanced_2 = 'advanced2',
    Advanced_3 = 'advanced3',
    Advanced_4 = 'advanced4',
    Advanced_5 = 'advanced5',
    Advanced_6 = 'advanced6',
    Advanced_7 = 'advanced7',
    Advanced_8 = 'advanced8',
    Advanced_9 = 'advanced9',
    Advanced_10 = 'advanced10',
    Advanced_11 = 'advanced11',
    Advanced_12 = 'advanced12',
    Advanced_13 = 'advanced13',
    Advanced_14 = 'advanced14',
    Advanced_15 = 'advanced15',
    Advanced_Rare_1 = 'advanced.rare1',
    Advanced_Rare_2 = 'advanced.rare2',
    Advanced_Rare_3 = 'advanced.rare3',
    Advanced_Rare_4 = 'advanced.rare4',
    Advanced_Rare_5 = 'advanced.rare5',
    Advanced_Rare_6 = 'advanced.rare6',
    Advanced_Rare_7 = 'advanced.rare7',
    Advanced_Rare_8 = 'advanced.rare8',
    Advanced_Rare_9 = 'advanced.rare9',
    Advanced_Rare_10 = 'advanced.rare10',
    Advanced_Rare_11 = 'advanced.rare11',
    Advanced_Rare_12 = 'advanced.rare12',
    Advanced_Rare_13 = 'advanced.rare13',
    Advanced_Rare_14 = 'advanced.rare14',
    Advanced_Rare_15 = 'advanced.rare15',
    Resiflora_Rare_1 = 'resiliflora.rare1',
    Resiflora_Rare_2 = 'resiliflora.rare2',
    Resiflora_Rare_3 = 'resiliflora.rare3',
    Resiflora_Rare_4 = 'resiliflora.rare4',
    Resiflora_Rare_5 = 'resiliflora.rare5',
    Resiflora_Rare_6 = 'resiliflora.rare6',
    Resiflora_Rare_7 = 'resiliflora.rare7',
    Resiflora_Rare_8 = 'resiliflora.rare8',
    Resiflora_Rare_9 = 'resiliflora.rare9',
    Resiflora_Rare_10 = 'resiliflora.rare10',
    Nutrivora_1 = 'nutrivora1',
    Nutrivora_2 = 'nutrivora2',
    Nutrivora_3 = 'nutrivora3',
    Nutrivora_4 = 'nutrivora4',
    Nutrivora_5 = 'nutrivora5',
    Nutrivora_6 = 'nutrivora6',
    Nutrivora_7 = 'nutrivora7',
    Nutrivora_8 = 'nutrivora8',
    Nutrivora_9 = 'nutrivora9',
    Nutrivora_10 = 'nutrivora10',
    Nutrivora_11 = 'nutrivora11',
    Nutrivora_12 = 'nutrivora12',
    Nutrivora_13 = 'nutrivora13',
    Nutrivora_14 = 'nutrivora14',
    Nutrivora_15 = 'nutrivora15',
    Novafutura = 'novafutura',
    Revalesca_1 = 'revalesca1',
    Revalesca_2 = 'revalesca2',
    Revalesca_3 = 'revalesca3',
    Revalesca_4 = 'revalesca4',
    Revalesca_5 = 'revalesca5',
    Revalesca_6 = 'revalesca6',
    Revalesca_7 = 'revalesca7',
    Revalesca_8 = 'revalesca8',
    Revalesca_9 = 'revalesca9',
    Revalesca_10 = 'revalesca10',
    Audaciaflora_1 = 'audaciaflora1',
    Audaciaflora_2 = 'audaciaflora2',
    Audaciaflora_3 = 'audaciaflora3',
    Audaciaflora_4 = 'audaciaflora4',
    Audaciaflora_5 = 'audaciaflora5',
    Audaciaflora_6 = 'audaciaflora6',
    Audaciaflora_7 = 'audaciaflora7',
    Audaciaflora_8 = 'audaciaflora8',
    Audaciaflora_9 = 'audaciaflora9',
    Audaciaflora_10 = 'audaciaflora10',
    Solaflora_1 = 'solaflora1',
    Solaflora_2 = 'solaflora2',
    Solaflora_3 = 'solaflora3',
    Solaflora_4 = 'solaflora4',
    Solaflora_5 = 'solaflora5',
    Serenita_1 = 'serenita1',
    Serenita_2 = 'serenita2',
    Serenita_3 = 'serenita3',
    Serenita_4 = 'serenita4',
    Serenita_5 = 'serenita5',
    Bonvira_1 = 'bonvira1',
    Bonvira_2 = 'bonvira2',
    Bonvira_3 = 'bonvira3',
    Bonvira_4 = 'bonvira4',
    Bonvira_5 = 'bonvira5',
    Bonvira_6 = 'bonvira6',
    Bonvira_7 = 'bonvira7',
    Bonvira_8 = 'bonvira8',
    Bonvira_9 = 'bonvira9',
    Bonvira_10 = 'bonvira10',
    Harmonia_1 = 'harmonia1',
    Harmonia_2 = 'harmonia2',
    Harmonia_3 = 'harmonia3',
    Harmonia_4 = 'harmonia4',
    Harmonia_5 = 'harmonia5',
    Harmonia_6 = 'harmonia6',
    Harmonia_7 = 'harmonia7',
    Harmonia_8 = 'harmonia8',
    Harmonia_9 = 'harmonia9',
    Harmonia_10 = 'harmonia10',
    Cresfolia_1 = 'cresfolia1',
    Cresfolia_2 = 'cresfolia2',
    Cresfolia_3 = 'cresfolia3',
    Cresfolia_4 = 'cresfolia4',
    Cresfolia_5 = 'cresfolia5',
    Cresfolia_6 = 'cresfolia6',
    Cresfolia_7 = 'cresfolia7',
    Cresfolia_8 = 'cresfolia8',
    Cresfolia_9 = 'cresfolia9',
    Cresfolia_10 = 'cresfolia10',
    Vitara_1 = 'vitara1',
    Vitara_2 = 'vitara2',
    Vitara_3 = 'vitara3',
    Vitara_4 = 'vitara4',
    Vitara_5 = 'vitara5',
    Vitara_6 = 'vitara6',
    Vitara_7 = 'vitara7',
    Vitara_8 = 'vitara8',
    Vitara_9 = 'vitara9',
    Vitara_10 = 'vitara10',
    Fortituda_1 = 'fortituda1',
    Fortituda_2 = 'fortituda2',
    Fortituda_3 = 'fortituda3',
    Fortituda_4 = 'fortituda4',
    Fortituda_5 = 'fortituda5',
    Fortituda_6 = 'fortituda6',
    Fortituda_7 = 'fortituda7',
    Fortituda_8 = 'fortituda8',
    Fortituda_9 = 'fortituda9',
    Fortituda_10 = 'fortituda10',
    Empty_1 = 'Empty_1',
    Empty_2 = 'Empty_2',
    Empty_3 = 'Empty_3',
    Empty_4 = 'Empty_4',
    Empty_5 = 'Empty_5'
}
