import { Link, Stack } from '@mui/material'
import { useCrossAppNavigation } from '@thriveglobal/thrive-web-core'
import {
    CoreAlert,
    CoreTypography,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'
import React, { MouseEventHandler } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { ROUTES } from '../../../../routes'

const messages = defineMessages({
    youEarnedXAchievements: {
        defaultMessage:
            'You have recently earned {count} badge{count, plural, one {} other {s}}. <Link>View earned badge{count, plural, one {} other {s}}</Link>',
        description:
            'message indicating how many new badges the user has earned'
    }
})

const AchievementsEarnedWidgetBanner: React.FC<{ count: number }> = ({
    count
}) => {
    const { formatMessage } = useIntl()
    const navigate = useCrossAppNavigation()
    const navigateToBadges: MouseEventHandler = (e) => {
        e.preventDefault()
        navigate(ROUTES.BADGES)
    }
    return (
        <CoreAlert icon={<></>} sx={{ p: 1 }}>
            <Stack gap={1} direction="row" alignItems="center">
                <LeafIcon icon="party-horn" fontSize="inherit"></LeafIcon>
                <CoreTypography variant="body1">
                    {formatMessage(messages.youEarnedXAchievements, {
                        count,
                        Link: (chunks) => (
                            <Link
                                href={ROUTES.BADGES}
                                onClick={navigateToBadges}
                            >
                                {chunks}
                            </Link>
                        )
                    })}
                </CoreTypography>
            </Stack>
        </CoreAlert>
    )
}

export default AchievementsEarnedWidgetBanner
