import { AchievementUpcs } from './achievementUpcs'

export const AchievementIcons = {
    connect: AchievementUpcs.connect,
    dailyReflection: AchievementUpcs.dailyReflection,
    focus: AchievementUpcs.focus,
    food: AchievementUpcs.food,
    microstepper: AchievementUpcs.microStepper,
    mindfulMoments: AchievementUpcs.mindfulMoments,
    money: AchievementUpcs.money,
    move: AchievementUpcs.move,
    recharge: AchievementUpcs.recharge,
    communitybuilder: AchievementUpcs.communityBuilder,
    stress: AchievementUpcs.stress
} as const

export type AchievementIcons =
    (typeof AchievementIcons)[keyof typeof AchievementIcons]
