import { ThemeProvider } from '@mui/material'
import { ProtectedRoute } from '@thriveglobal/thrive-web-auth-core'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense, lazy } from 'react'
import { BrowserRouter, Switch } from 'react-router-dom'

const LazyAchievements = lazy(
    () => import('../pages/AchievementsV3/AchievementsV3')
)

const ShopWithProvider = lazy(() =>
    import('../pages/Shop').then((module) => ({
        default: module.ShopWithProvider
    }))
)

export enum ROUTES {
    ACHIEVEMENTS = '/achievements',
    CLAIMED_ACHIEVEMENTS = '/achievements/claimed',
    GARDEN = '/achievements/garden',
    BADGES = '/achievements/badges',
    SHOP = '/achievements/shop'
}

const Router: React.FC = () => {
    const theme = useTheme()

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter>
                <Suspense fallback={<></>}>
                    <Switch>
                        <ProtectedRoute
                            path={ROUTES.SHOP}
                            component={ShopWithProvider}
                        />
                        <ProtectedRoute
                            path={ROUTES.ACHIEVEMENTS}
                            component={LazyAchievements}
                        />
                    </Switch>
                </Suspense>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default Router
