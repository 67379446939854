import { defineMessages } from 'react-intl'

export const PlantErrorMessages = defineMessages({
    errorPlantAutomation: {
        defaultMessage: 'Failed to automatically plant in garden',
        description:
            'error message for when there is an error automatically planting in garden'
    },
    errorPlantWatering: {
        defaultMessage: 'Failed to water plant',
        description: 'error message for when there is an error watering a plant'
    },
    errorPlantAlreadyFullyGrown: {
        defaultMessage: 'Plant already fully grown',
        description:
            'error message for when there is an error watering a plant that is already fully grown'
    },
    errorPlantAlreadyWatered: {
        defaultMessage: 'Plant already watered',
        description: 'error message for when plant is already watered'
    },
    errorPlantAlreadyPlanted: {
        defaultMessage: 'Plant already planted',
        description: 'error message for when plant is already planted'
    },
    errorPlantGrowthNotComplete: {
        defaultMessage: 'Plant growth not complete',
        description: 'error message for when plant growth is not complete'
    },
    errorPlantNotFound: {
        defaultMessage: 'Plant not found for user',
        description:
            'error message for when there is an error watering a plant that is not found for the user'
    }
})

export const PlantErrors = {
    ERROR_AUTOMATIC_PLANT: {
        code: 'ERROR_AUTOMATIC_PLANT',
        message: PlantErrorMessages.errorPlantAutomation
    },
    ERROR_WATERING_PLANT: {
        code: 'ERROR_WATERING_PLANT',
        message: PlantErrorMessages.errorPlantWatering
    },
    PLANT_ALREADY_FULLY_GROWN: {
        code: 'PLANT_ALREADY_FULLY_GROWN',
        message: PlantErrorMessages.errorPlantAlreadyFullyGrown
    },
    PLANT_ALREADY_WATERED: {
        code: 'PLANT_ALREADY_WATERED',
        message: PlantErrorMessages.errorPlantAlreadyWatered
    },
    PLANT_ALREADY_PLANTED: {
        code: 'PLANT_ALREADY_PLANTED',
        message: PlantErrorMessages.errorPlantAlreadyPlanted
    },
    PLANT_GROWTH_NOT_COMPLETE: {
        code: 'PLANT_GROWTH_NOT_COMPLETE',
        message: PlantErrorMessages.errorPlantGrowthNotComplete
    },
    PLANT_NOT_FOUND_FOR_USER: {
        code: 'PLANT_NOT_FOUND_FOR_USER',
        message: PlantErrorMessages.errorPlantNotFound
    }
}
