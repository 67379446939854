import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { useCallback, useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { AchievementV3Info } from '../../graphql/generated/autogenerated'

const ACHIEVEMENT_THEME = {
    REFLECT: 'reflect',
    RESET: 'reset',
    MICROSTEP: 'microstep',
    LEARN: 'learn',
    MOVE: 'move',
    SOCIAL: 'social'
} as const

type AchievementTheme =
    (typeof ACHIEVEMENT_THEME)[keyof typeof ACHIEVEMENT_THEME]

const ACHIEVEMENT_COLORS = {
    BRONZE: 'bronze',
    SILVER: 'silver',
    GOLD: 'gold',
    DEFAULT: 'default',
    MOVE: 'move',
    SOCIAL: 'social'
} as const

type AchievementColors =
    (typeof ACHIEVEMENT_COLORS)[keyof typeof ACHIEVEMENT_COLORS]

const THEME_LABEL = defineMessages({
    [ACHIEVEMENT_THEME.REFLECT]: {
        defaultMessage: 'Reflect',
        description: 'reflect label'
    },
    [ACHIEVEMENT_THEME.RESET]: {
        defaultMessage: 'Reset',
        description: 'reset label'
    },
    [ACHIEVEMENT_THEME.MICROSTEP]: {
        defaultMessage: 'Microstep',
        description: 'microstep label'
    },
    [ACHIEVEMENT_THEME.LEARN]: {
        defaultMessage: 'Learn',
        description: 'learn label'
    },
    [ACHIEVEMENT_THEME.MOVE]: {
        defaultMessage: 'Move',
        description: 'move label'
    },
    [ACHIEVEMENT_THEME.SOCIAL]: {
        defaultMessage: 'Social',
        description: 'social label'
    }
})

export const ACTION_TYPES_TO_THEME = {
    PULSE_SURVEY_COMPLETED: ACHIEVEMENT_THEME.REFLECT,
    RESET_WATCHED: ACHIEVEMENT_THEME.RESET,
    CHECK_IN: ACHIEVEMENT_THEME.MICROSTEP,
    CONTENT_FINISHED: ACHIEVEMENT_THEME.LEARN,
    PROFILE_IMAGE_ADDED: ACHIEVEMENT_THEME.SOCIAL,
    WORK_DETAILS_COMPLETED: ACHIEVEMENT_THEME.SOCIAL,
    REFERRAL_COMPLETED: ACHIEVEMENT_THEME.SOCIAL,
    SOCIAL_SHARING_COMPLETED: ACHIEVEMENT_THEME.SOCIAL,
    WEARABLE_CONNECTED: ACHIEVEMENT_THEME.MOVE,
    PERSONAL_RESET_CREATED: ACHIEVEMENT_THEME.RESET,
    COURSE_COMPLETED: ACHIEVEMENT_THEME.LEARN
} as const

const BADGE_URLS = {
    [ACHIEVEMENT_THEME.REFLECT]: {
        [ACHIEVEMENT_COLORS.BRONZE]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/9db6a14e-cce7-49a9-1870-745f09651100/cms',
        [ACHIEVEMENT_COLORS.SILVER]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/9a033859-ffb4-4754-1bc8-de0752721d00/cms',
        [ACHIEVEMENT_COLORS.GOLD]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/f8847185-4bc9-4b11-599a-0d6f2f96ec00/cms',
        [ACHIEVEMENT_COLORS.DEFAULT]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4bde4216-7b7f-49ca-1d15-8d715e003700/cms'
    },
    [ACHIEVEMENT_THEME.MICROSTEP]: {
        [ACHIEVEMENT_COLORS.BRONZE]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/ddfe5fef-e12d-4a01-8869-d2c606365b00/cms',
        [ACHIEVEMENT_COLORS.SILVER]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/ce27f93a-33cd-4b95-dbc4-6eac95a78200/cms',
        [ACHIEVEMENT_COLORS.GOLD]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/0c62ca60-712a-4340-10eb-545077fa0000/cms',
        [ACHIEVEMENT_COLORS.DEFAULT]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/0663fa97-6266-4e8a-00e3-9ca91d767200/cms'
    },
    [ACHIEVEMENT_THEME.RESET]: {
        [ACHIEVEMENT_COLORS.BRONZE]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/6d1b26f9-04c9-4825-06da-6db5346de000/cms',
        [ACHIEVEMENT_COLORS.SILVER]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/31463378-f271-4f90-dde0-a7c17f447300/cms',
        [ACHIEVEMENT_COLORS.GOLD]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4170b70c-b557-4557-4445-5b1ec2c80c00/cms',
        [ACHIEVEMENT_COLORS.DEFAULT]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/232a9b41-dc77-4e7a-639b-db7d2502dc00/cms'
    },
    [ACHIEVEMENT_THEME.LEARN]: {
        [ACHIEVEMENT_COLORS.BRONZE]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/04cccbbc-038d-49ee-954f-155107a36500/cms',
        [ACHIEVEMENT_COLORS.SILVER]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/26f1007f-a412-44e8-db70-9ade9f1c4e00/cms',
        [ACHIEVEMENT_COLORS.GOLD]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/68c13574-89bf-4c84-f345-8657b34dd800/cms',
        [ACHIEVEMENT_COLORS.DEFAULT]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/4df46722-6282-40d4-eb6d-5a26282bf200/cms'
    },
    [ACHIEVEMENT_THEME.MOVE]: {
        [ACHIEVEMENT_COLORS.BRONZE]: null,
        [ACHIEVEMENT_COLORS.SILVER]: null,
        [ACHIEVEMENT_COLORS.GOLD]: null,
        [ACHIEVEMENT_COLORS.DEFAULT]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/a4471d4f-6851-4a8c-4221-25c74c114600/cms'
    },
    [ACHIEVEMENT_THEME.SOCIAL]: {
        [ACHIEVEMENT_COLORS.BRONZE]: null,
        [ACHIEVEMENT_COLORS.SILVER]: null,
        [ACHIEVEMENT_COLORS.GOLD]: null,
        [ACHIEVEMENT_COLORS.DEFAULT]:
            'https://image.thriveglobal.com/ZW5qPb0CCPvz30zZFLgJ2w/45fbe671-d307-4582-8816-2cf7355e8a00/cms'
    }
} as const

const ACTION_PATHS = {
    [ACHIEVEMENT_THEME.REFLECT]: '/journeys',
    [ACHIEVEMENT_THEME.RESET]: '/reset',
    [ACHIEVEMENT_THEME.MICROSTEP]: '/today/microsteps',
    [ACHIEVEMENT_THEME.LEARN]: '/learn',
    [ACHIEVEMENT_THEME.MOVE]: '/profile/health-and-fitness-apps',
    [ACHIEVEMENT_THEME.SOCIAL]: '/profile'
} as const

const useAchievementV3Info = () => {
    const { formatMessage } = useIntl()
    const { palette } = useTheme()

    const colorsMap = useMemo(
        () =>
            ({
                [ACHIEVEMENT_COLORS.BRONZE]: palette.journeys.move.dark,
                [ACHIEVEMENT_COLORS.SILVER]: palette.grey[600],
                [ACHIEVEMENT_COLORS.GOLD]: palette.journeys.food.main,
                [ACHIEVEMENT_COLORS.DEFAULT]: palette.journeys.recharge.main,
                [ACHIEVEMENT_COLORS.MOVE]: palette.journeys.move.main,
                [ACHIEVEMENT_COLORS.SOCIAL]: palette.journeys.connect.main
            } as const),
        [palette]
    )

    return useCallback(
        (achievement: AchievementV3Info) => {
            const theme: AchievementTheme =
                ACTION_TYPES_TO_THEME[achievement.actionType]
            let color: AchievementColors
            switch (true) {
                case theme === ACHIEVEMENT_THEME.MOVE:
                    color = ACHIEVEMENT_COLORS.MOVE
                    break
                case theme === ACHIEVEMENT_THEME.SOCIAL:
                    color = ACHIEVEMENT_COLORS.SOCIAL
                    break
                case achievement.frequency !== 'ONE_TIME' &&
                    achievement.actionNumber === 1:
                    color = ACHIEVEMENT_COLORS.DEFAULT
                    break
                case achievement.actionNumber <= 5:
                    color = ACHIEVEMENT_COLORS.BRONZE
                    break
                case achievement.actionNumber > 10:
                    color = ACHIEVEMENT_COLORS.GOLD
                    break
                case achievement.actionNumber <= 10:
                    color = ACHIEVEMENT_COLORS.SILVER
                    break
                default:
                    color = ACHIEVEMENT_COLORS.DEFAULT
                    break
            }

            return {
                theme,
                label: THEME_LABEL[theme]
                    ? formatMessage(THEME_LABEL[theme])
                    : undefined,
                badgeUrl:
                    BADGE_URLS[theme]?.[color] || BADGE_URLS[theme]?.default,
                color: colorsMap[color],
                actionPath: ACTION_PATHS[theme],
                ...achievement
            }
        },
        [formatMessage, colorsMap]
    )
}

export default useAchievementV3Info
