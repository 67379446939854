import { Grow, Stack } from '@mui/material'
import { LeafCircularProgress } from '@thriveglobal/thrive-web-leafkit'
import React, { useMemo, memo } from 'react'
import { useIntl } from 'react-intl'

export type PlantCircularProgressProps = {
    completedSteps: number
    totalSteps: number
    ariaLabel: string
    isLarge?: boolean
}

const PlantCircularProgress: React.FC<PlantCircularProgressProps> = ({
    completedSteps,
    totalSteps,
    ariaLabel,
    isLarge
}) => {
    const { formatNumber } = useIntl()

    const { progress, remainingSteps } = useMemo(() => {
        return {
            progress: Math.round((completedSteps / totalSteps) * 100),
            remainingSteps:
                completedSteps > 0 ? totalSteps - completedSteps : totalSteps
        }
    }, [completedSteps, totalSteps])

    return (
        <Grow
            in
            style={{ transformOrigin: '0 0 0' }}
            timeout={1000}
            data-testid="plant-circular-progress"
        >
            <Stack
                alignItems="center"
                justifyContent="center"
                position="relative"
            >
                <LeafCircularProgress
                    aria-label={ariaLabel}
                    color="accent"
                    size={isLarge ? 44 : 32}
                    label={formatNumber(remainingSteps)}
                    showLabel={remainingSteps > 0}
                    value={progress}
                    variant="determinate"
                />
            </Stack>
        </Grow>
    )
}

export default memo(PlantCircularProgress)
