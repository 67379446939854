// TODO: Delete this old card component that is the old way of displaying plants. It is only used in when showing the details for referral rewards in web-profile.

import { Card, useMediaQuery } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { Suspense, memo, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import {
    ProductInfo,
    UserPlantInfo
} from '../../../../graphql/generated/autogenerated'
import Image from '../../../../components/elements/Image/Image'
import { usePlantIcons } from '../../../../hooks/usePlantIcons/usePlantIcons'
import { usePlantsContext } from '../../../../hooks/usePlantsProvider/usePlantsContext'
import PlantCardActive from '../PlantCardActive/PlantCardActive'
import PlantCardDetails from '../PlantCardDetails/PlantCardDetails'
import PlantInfoTooltip from '../PlantInfoTooltip/PlantInfoTooltip'

export const plantCardMessages = defineMessages({
    firstTimeEmpty: {
        defaultMessage: 'Your garden is waiting.',
        description:
            'text that appears when user is growing no plants, and has not grown plants in the past'
    },
    nextTimeEmpty: {
        defaultMessage: 'Your garden is thriving, keep growing!',
        description:
            'text that appears when user is growing no plants, but has grown plants in the past'
    },
    growAPlant: {
        defaultMessage: 'Grow a plant',
        description: 'button text for navigating to the plant shop'
    },
    growAnotherPlant: {
        defaultMessage: 'Grow another plant',
        description: 'button text for navigating to the plant shop'
    },
    dirtTitleAccess: {
        defaultMessage: 'Dirt',
        description: 'title for dirt'
    },
    plantTitleAccess: {
        defaultMessage: '{plantName} - Step {progress}/{total}',
        description: 'title for plant'
    }
})

export type PlantCardProps = {
    plant?: UserPlantInfo
    product?: ProductInfo
    showSway?: boolean
    totalSteps?: number
    showDefault?: boolean
    completedSteps?: number
    preserveAspectRatio?: boolean
    isLarge?: boolean
    isDashed?: boolean
    isProduct?: boolean
    isActive?: boolean
    isWidget?: boolean
    hideCard?: boolean
    onCompletePlant?: () => void
}

const PlantCard: React.FC<PlantCardProps> = ({
    plant,
    isLarge,
    product,
    isDashed,
    isActive,
    isWidget,
    hideCard,
    showSway,
    isProduct,
    totalSteps,
    completedSteps,
    onCompletePlant,
    preserveAspectRatio = true
}) => {
    const { breakpoints, spacing, palette } = useTheme()
    const isMobile = useMediaQuery(breakpoints.down('sm'))
    const [showDetails, setShowDetails] = useState<boolean>(false)
    const { getPlantIcon, getPlantProductIcon } = usePlantIcons()
    const { getPlantProduct } = usePlantsContext()
    const [tooltipOpen, setTooltipOpen] = React.useState(false)
    const { formatMessage, formatNumber } = useIntl()

    const isEmpty = useMemo(() => {
        return !plant && !product
    }, [plant, product])

    const cardStyling = useMemo(() => {
        return {
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: hideCard ? 'auto' : '100%',
            minHeight: isWidget && isEmpty ? 330 : undefined,
            mb: hideCard ? -3 : undefined,
            position: 'relative',
            overflow: 'visible',
            bgcolor: hideCard ? 'transparent' : undefined,
            ...(preserveAspectRatio && {
                aspectRatio: isActive ? 'auto' : '1 / 1'
            }),
            ...(isDashed && {
                border: `${spacing(0.375)} isDashed ${palette.grey[200]}`
            })
        }
    }, [
        isEmpty,
        palette,
        spacing,
        isActive,
        hideCard,
        isWidget,
        isDashed,
        preserveAspectRatio
    ])

    const { maxPlantIconHeight, maxProductIconHeight } = useMemo(() => {
        // Plant
        let _maxPlantIconHeight = 344
        if (isMobile) {
            _maxPlantIconHeight = 220
        } else if (isEmpty) {
            _maxPlantIconHeight = 128
        } else if (isWidget) {
            _maxPlantIconHeight = 216
        }

        // Product
        let _maxProductIconHeight = 344
        if (isWidget) {
            _maxProductIconHeight = 146
        } else if (!isLarge && !isProduct) {
            _maxProductIconHeight = 66
        }

        return {
            maxPlantIconHeight: _maxPlantIconHeight,
            maxProductIconHeight: _maxProductIconHeight
        }
    }, [isMobile, isEmpty, isLarge, isWidget, isProduct])

    const plantProduct = useMemo(() => {
        return getPlantProduct(plant)
    }, [plant, getPlantProduct])

    const plantComponent = useMemo(() => {
        if (plant?.plantImage) {
            return (
                <Image
                    src={plant.plantImage}
                    width="100%"
                    height="100%"
                    maxHeight={maxPlantIconHeight}
                />
            )
        } else {
            const PlantIcon = getPlantIcon(plant)
            const titleAccess =
                plant && plantProduct
                    ? formatMessage(plantCardMessages.plantTitleAccess, {
                          plantName: plantProduct.title,
                          progress: formatNumber(plant.progressSteps),
                          total: formatNumber(plantProduct.plantWateringSteps)
                      })
                    : formatMessage(plantCardMessages.dirtTitleAccess)
            return (
                <Suspense fallback={<></>}>
                    <PlantIcon
                        titleAccess={titleAccess}
                        showsway={showSway?.toString()}
                        sx={{
                            width: '100%',
                            height: '100%',
                            maxHeight: maxPlantIconHeight
                        }}
                    />
                </Suspense>
            )
        }
    }, [
        plant,
        showSway,
        plantProduct,
        maxPlantIconHeight,
        getPlantIcon,
        formatMessage,
        formatNumber
    ])

    const productComponent = useMemo(() => {
        if (product) {
            const PlantIcon = getPlantProductIcon(product)
            return (
                <Suspense fallback={<></>}>
                    <PlantIcon
                        titleAccess={product.title}
                        sx={{
                            width: '100%',
                            height: '100%',
                            maxHeight: maxProductIconHeight
                        }}
                    />
                </Suspense>
            )
        }
    }, [product, maxProductIconHeight, getPlantProductIcon])

    if (!plantComponent) return

    return (
        <PlantInfoTooltip
            open={tooltipOpen}
            setOpen={setTooltipOpen}
            challengeName={plant?.challengeName}
            dateAdded={plant?.createdAt}
        >
            <Card
                elevation={isDashed || hideCard ? 0 : 1}
                data-testid="plant-card"
                onClick={() =>
                    hideCard && plant?.plantImage && setTooltipOpen(true)
                }
                sx={cardStyling}
            >
                <Suspense fallback={<></>}>
                    {showDetails ? (
                        <PlantCardDetails
                            productComponent={productComponent}
                            plant={plant}
                            product={product}
                            isLarge={isLarge}
                            isWidget={isWidget}
                            onBack={() => setShowDetails(false)}
                        />
                    ) : (
                        <PlantCardActive
                            plantComponent={
                                isProduct ? productComponent : plantComponent
                            }
                            completedSteps={completedSteps}
                            totalSteps={totalSteps}
                            isLarge={isLarge}
                            isEmpty={isEmpty}
                            isActive={isActive}
                            isWidget={isWidget}
                            onShowDetails={() => setShowDetails(true)}
                            onCompletePlant={onCompletePlant}
                        />
                    )}
                </Suspense>
            </Card>
        </PlantInfoTooltip>
    )
}

export default memo(PlantCard)
