import { defineMessages } from 'react-intl'

export const GenericErrorMessages = defineMessages({
    thereWasAnError: {
        defaultMessage: 'There was an error',
        description: 'message for general error alert text'
    },
    failedToFetch: {
        defaultMessage: 'Failed to fetch',
        description: 'message for fetch failure'
    }
})
