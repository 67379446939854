import { Box, Button, Card, IconButton, Stack } from '@mui/material'
import { useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'

import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { useCallback, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { withErrorBoundary } from '../../../../components/elements/ErrorBoundaryWrapper'
import GardenHowToDialog from '../../../../components/elements/GardenHowToDialog/GardenHowToDialog'
import PlantGrowthCelebration from '../../../../components/elements/PlantGrowthCelebration/PlantGrowthCelebration'
import PlantsLimitReachedDialog from '../../../../components/elements/PlantsLimitReached/PlantsLimitReachedDialog'
import ProductDetailsDialog from '../../../../components/elements/ProductDetailsDialog/ProductDetailsDialog'
import WaterDrop from '../../../../components/icons/WaterDrop'
import {
    BUTTON_MESSAGES,
    EARN_TOKENS_ACHIEVEMENTS_MESSAGE,
    SUCCESS_MESSAGES
} from '../../../../constants/messages'
import { ProductTypes } from '../../../../enums'
import { ROUTES } from '../../../../routes'
import { promptSelectedDefaultValues } from '../../../../utils/Avo/defaultValues'

import SuccessMessage from '../../../../components/elements/SuccessMessage/SuccessMessage'
import { useCurrentPlant } from '../../../../hooks/useCurrentPlant/useCurrentPlant'
import useFlagStore, {
    FlagKey
} from '../../../../hooks/useFlagStore/useFlagStore'
import { usePlantProgress } from '../../../../hooks/usePlantProgress/usePlantProgress'
import withPlantsProvider from '../../../../hooks/usePlantsProvider/withPlantsProvider'
import PlantSelector from '../../PlantSelector/PlantSelector'
import PlantProgress from '../PlantProgress/PlantProgress'
import GardenWidgetFallback from './GardenWidgetFallback'

type GardenWidgetProps = {
    autoShowPlantSelector?: boolean
}

const messages = defineMessages({
    howTo: {
        defaultMessage: 'Open modal on how to grow a plant',
        description: 'Aria label for the how to grow a plant button'
    }
})

const GardenWidget: React.FC<GardenWidgetProps> = ({
    autoShowPlantSelector = true
}) => {
    const { palette } = useTheme()
    const { formatMessage } = useIntl()
    const history = useHistory()
    const [showHowTo, setShowHowTo] = useState(false)
    const [showPlantInfo, setShowPlantInfo] = useState(false)
    const [showSelector, setShowSelector] = useState(false)
    const [isShowingCelebration, setIsShowingCelebration] = useState(false)
    const [openPlantsLimitExplainer, setOpenPlantsLimitExplainer] =
        useState<boolean>(false)
    const [dailyLimitReached, setDailyLimitReached] = useState<boolean>(false)

    const { setFlag } = useFlagStore()

    const {
        products,
        isLoading,
        currentPlant,
        plantedPlants,
        isActivePlant,
        isOnboardingPlant,
        currentPlantProduct
    } = useCurrentPlant()

    const { isFullyGrown } = usePlantProgress(currentPlant, currentPlantProduct)

    const tokenCount = useAppSelector((state) => state.wallet?.tokens || 0)

    const affordsAnyPlant = useMemo(
        () =>
            products.some(
                (p) =>
                    p.productType === ProductTypes.Plant && p.cost <= tokenCount
            ),
        [products, tokenCount]
    )

    const buttonText = useMemo(() => {
        switch (true) {
            case plantedPlants.length === 0 && !currentPlant:
                return formatMessage(BUTTON_MESSAGES.growFirstPlant)
            case plantedPlants.length > 0:
                return formatMessage(BUTTON_MESSAGES.growAnotherPlant)
            default:
                return formatMessage(BUTTON_MESSAGES.viewGarden)
        }
    }, [plantedPlants.length, currentPlant, formatMessage])

    const handleCloseHowTo = useCallback(() => {
        setShowHowTo(false)
    }, [])

    const handleShowHowTo = useCallback(() => {
        setShowHowTo(true)
    }, [])

    const handleClosePlantInfo = useCallback(() => {
        setShowPlantInfo(false)
    }, [])

    const handleShowPlantInfo = useCallback(() => {
        setShowPlantInfo(true)
        Avo.promptSelected({
            ...promptSelectedDefaultValues,
            featureType: 'achievement',
            activityType: 'plant_watered',
            checkInDate: new Date().toISOString(),
            isAutomaticCheckin: false,
            microstepBody: 'plant_watered',
            microstepId: 'plant_watered',
            microstepName: 'plant_watered'
        })
    }, [])

    const handleShowPlantSelector = useCallback(() => {
        setShowSelector(true)
    }, [])

    const handleHidePlantSelector = useCallback(
        (dailyLimitReached?: boolean) => {
            setOpenPlantsLimitExplainer(dailyLimitReached)
            setDailyLimitReached(dailyLimitReached)
            setShowSelector(false)
        },
        []
    )

    const buttonDisabled = useMemo(
        () =>
            (isActivePlant && !currentPlant?.canBeWatered) ||
            (plantedPlants.length === 0 && !currentPlant && !affordsAnyPlant) ||
            dailyLimitReached,
        [
            affordsAnyPlant,
            currentPlant,
            dailyLimitReached,
            isActivePlant,
            plantedPlants.length
        ]
    )

    const handleGrowAnotherPlant = useCallback(() => {
        handleClosePlantInfo()
        handleShowPlantSelector()
    }, [handleClosePlantInfo, handleShowPlantSelector])

    const onButtonClick = useCallback(() => {
        if (isOnboardingPlant) {
            setFlag(FlagKey.ONBOARDING_PLANT_INTERACTED, String(true))
        }
        if (!isActivePlant) {
            handleShowPlantSelector()
        } else {
            history.push(ROUTES.GARDEN)
        }
    }, [
        handleShowPlantSelector,
        history,
        isActivePlant,
        isOnboardingPlant,
        setFlag
    ])

    const handleSetIsShowingCelebration = useCallback(() => {
        setIsShowingCelebration(true)
    }, [])

    return (
        <Card elevation={0}>
            <Stack
                gap={3}
                p={2}
                width="100%"
                height="100%"
                bgcolor={palette.background.default}
                justifyContent="space-between"
            >
                <Stack
                    gap={0}
                    height="100%"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    {isOnboardingPlant ? (
                        <>
                            <Box
                                display="flex"
                                width="100%"
                                justifyContent="flex-end"
                            >
                                <IconButton
                                    onClick={handleShowHowTo}
                                    aria-label={formatMessage(messages.howTo)}
                                >
                                    <LeafIcon icon="circle-info" />
                                </IconButton>
                            </Box>
                        </>
                    ) : (
                        isFullyGrown && (
                            <SuccessMessage my={1}>
                                {formatMessage(SUCCESS_MESSAGES.fullyGrown)}
                            </SuccessMessage>
                        )
                    )}
                    <PlantProgress
                        showSway={true}
                        loading={isLoading}
                        plant={currentPlant}
                        product={currentPlantProduct}
                        progressIcon={<WaterDrop />}
                        showProgress
                        onProgressClick={handleShowPlantInfo}
                    />
                </Stack>
                {!currentPlant && !isOnboardingPlant && !affordsAnyPlant && (
                    <CoreTypography variant="body2" my={1} lineHeight="16px">
                        {formatMessage(EARN_TOKENS_ACHIEVEMENTS_MESSAGE)}
                    </CoreTypography>
                )}
                {isOnboardingPlant && (
                    <SuccessMessage>
                        {formatMessage(SUCCESS_MESSAGES.justOnboarded)}
                    </SuccessMessage>
                )}
                {!isActivePlant && (
                    <Button
                        variant="outlined"
                        onClick={onButtonClick}
                        disabled={buttonDisabled}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {buttonText}
                        </CoreTypography>
                    </Button>
                )}
            </Stack>
            <GardenHowToDialog open={showHowTo} onClose={handleCloseHowTo} />
            <PlantsLimitReachedDialog
                open={openPlantsLimitExplainer}
                onClose={() => setOpenPlantsLimitExplainer(false)}
            />
            <ProductDetailsDialog
                subtitle={
                    !isActivePlant
                        ? formatMessage(SUCCESS_MESSAGES.fullyGrownAlt)
                        : undefined
                }
                product={currentPlantProduct}
                open={showPlantInfo}
                onClose={handleClosePlantInfo}
                secondaryAction={
                    !isActivePlant
                        ? {
                              message: formatMessage(
                                  BUTTON_MESSAGES.growAnotherPlant
                              ),
                              onClick: handleGrowAnotherPlant
                          }
                        : undefined
                }
            />
            <PlantGrowthCelebration onOpen={handleSetIsShowingCelebration} />
            <PlantSelector
                open={showSelector}
                onClose={handleHidePlantSelector}
                autoShow={autoShowPlantSelector && !isShowingCelebration}
            />
        </Card>
    )
}

export default withErrorBoundary(
    'GardenWidget',
    'Feature',
    withPlantsProvider(React.memo(GardenWidget)),
    GardenWidgetFallback
)
