import { IconName, IconStyle } from '@fortawesome/fontawesome-common-types'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { AchievementIcons } from '../../enums'

export const useGetAchievementIcon = (icon: AchievementIcons) => {
    const { palette } = useTheme()
    const iconProperties = {
        [AchievementIcons.microstepper]: {
            color: palette.accent.main,
            icon: 'circle-check' as IconName,
            iconStyle: 'regular' as IconStyle
        },
        [AchievementIcons.mindfulMoments]: {
            color: palette.accent.main,
            icon: 'face-smile-relaxed' as IconName,
            iconStyle: 'regular' as IconStyle
        },
        [AchievementIcons.dailyReflection]: {
            color: palette.accent.main,
            icon: 'thought-bubble' as IconName,
            iconStyle: 'regular' as IconStyle
        },
        [AchievementIcons.recharge]: {
            color: palette.journeys.recharge.main,
            icon: 'bed-front' as IconName,
            iconStyle: 'solid' as IconStyle
        },
        [AchievementIcons.food]: {
            color: palette.journeys.food.main,
            icon: 'carrot' as IconName,
            iconStyle: 'solid' as IconStyle
        },
        [AchievementIcons.move]: {
            color: palette.journeys.move.main,
            icon: 'running' as IconName,
            iconStyle: 'solid' as IconStyle
        },
        [AchievementIcons.focus]: {
            color: palette.journeys.focus.main,
            icon: 'bullseye-arrow' as IconName,
            iconStyle: 'solid' as IconStyle
        },
        [AchievementIcons.connect]: {
            color: palette.journeys.connect.main,
            icon: 'heart' as IconName,
            iconStyle: 'solid' as IconStyle
        },
        [AchievementIcons.stress]: {
            color: palette.journeys.stressManagement.main,
            icon: 'head-side-brain' as IconName,
            iconStyle: 'solid' as IconStyle
        },
        [AchievementIcons.money]: {
            color: palette.journeys.money.main,
            icon: 'credit-card' as IconName,
            iconStyle: 'solid' as IconStyle
        },
        [AchievementIcons.communitybuilder]: {
            color: palette.accent.main,
            icon: 'handshake' as IconName,
            iconStyle: 'solid' as IconStyle
        }
    }
    return (
        iconProperties[icon] || {
            color: 'info',
            icon: 'leaf' as IconName,
            iconStyle: 'solid' as IconStyle
        }
    )
}

export default useGetAchievementIcon
