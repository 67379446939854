import {
    CoreTypography,
    CoreTypographyProps,
    LeafIcon
} from '@thriveglobal/thrive-web-leafkit'

type SuccessMessageProps = CoreTypographyProps &
    React.PropsWithChildren<{
        iconProps?: React.ComponentProps<typeof LeafIcon>
    }>

const SuccessMessage: React.FC<SuccessMessageProps> = ({
    children,
    iconProps,
    ...rest
}) => (
    <CoreTypography variant="body2" lineHeight="16px" {...rest}>
        <LeafIcon
            fontSize="small"
            sx={{ position: 'relative', top: 4 }}
            {...iconProps}
            icon="party-horn"
        />{' '}
        {children}
    </CoreTypography>
)

export default SuccessMessage
