import {
    fetchAndStoreGarden,
    useAppSelector,
    useIsFeatureEnabled
} from '@thriveglobal/thrive-web-core'
import isAfter from 'date-fns/isAfter'
import { useCallback } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { PlantType } from '../../enums/plantTypes'
import { ROUTES } from '../../routes'
import {
    referralEmailStandard,
    referralEmailTShirt
} from '../../utils/referralEmail'
import { AchievementIcons } from '../../enums'

const intlMessages = defineMessages({
    wayToGo: {
        defaultMessage: 'Way to go!',
        description: 'referral trophy plant title'
    },
    gardenItemNoContact: {
        defaultMessage: `You reached Level {level} of the Community Builder Achievement. To celebrate, we've given you a special item for your garden.`,
        description: 'garden item description'
    },
    gardenItem: {
        defaultMessage: `You reached Level {level} of the Community Builder Achievement. To celebrate, contact below to receive your prize. We've also given you a special new item for your garden.`,
        description: 'garden item description'
    },
    btnViewGarden: {
        defaultMessage: 'View your garden',
        description: 'button text for view garden button'
    },
    niceWork: {
        defaultMessage: 'Nice work!',
        description: 'nice work title'
    },
    specialPrize: {
        defaultMessage: `You've reached Level {level} of the Community Builder Achievement. To celebrate, we have a special prize waiting for you. Contact us to receive your prize!`,
        description: 'special prize description'
    },
    btnContactUs: {
        defaultMessage: 'Contact us',
        description: 'button text for contact us button'
    },
    claimRewardSubject: {
        defaultMessage: `I've reached level {level} in {achievementTitle}!`,
        description:
            'subject of the email to claim the reward for the achievement'
    }
})

export enum RewardType {
    GardenTrophy,
    SpecialPrize
}

export interface AchievementReward {
    productUpc: string | undefined
    title: string
    description: string
    buttonText: string
    onButtonClick: () => void
}

export const useGetReward = () => {
    const { formatMessage, formatNumber } = useIntl()
    const { fullName, email } = useAppSelector((state) => state.user)
    const { achievements, progress } = useAppSelector(
        (state) => state.achievement
    )
    const history = useHistory()
    const isReferralEnginePilotEnabled = useIsFeatureEnabled(
        'referral_engine_pilot',
        false,
        true
    )

    const createReward = useCallback(
        (rewardType: RewardType, level: number, href: string) => ({
            productUpc:
                rewardType === RewardType.GardenTrophy
                    ? PlantType.referralTrophyPlant
                    : undefined,
            title: formatMessage(
                rewardType === RewardType.GardenTrophy
                    ? intlMessages.wayToGo
                    : intlMessages.niceWork
            ),
            description: formatMessage(
                rewardType === RewardType.GardenTrophy &&
                    isReferralEnginePilotEnabled
                    ? intlMessages.gardenItem
                    : rewardType === RewardType.GardenTrophy &&
                      !isReferralEnginePilotEnabled
                    ? intlMessages.gardenItemNoContact
                    : intlMessages.specialPrize,
                { level }
            ),
            buttonText: isReferralEnginePilotEnabled
                ? formatMessage(intlMessages.btnContactUs)
                : level === 3
                ? formatMessage(intlMessages.btnViewGarden)
                : undefined,
            onButtonClick: () => {
                if (!isReferralEnginePilotEnabled && level === 3) {
                    setTimeout(() => {
                        history.push(ROUTES.GARDEN)
                    }, 0)
                } else {
                    window.location.href = href
                    setTimeout(() => history.push('/profile/invite'), 0)
                }
            }
        }),
        [formatMessage, history, isReferralEnginePilotEnabled]
    )

    const getAchievementReward = useCallback(
        (levelObj) => {
            const level = levelObj?.userProgress?.total
            const hrefStandard = referralEmailStandard(
                level,
                levelObj?.achievementInfo?.title,
                fullName,
                email,
                formatMessage,
                formatNumber
            )
            const hrefTShirt = referralEmailTShirt(
                level,
                levelObj?.achievementInfo?.title,
                fullName,
                email,
                formatMessage,
                formatNumber
            )

            switch (levelObj?.achievementInfo?.upc) {
                case AchievementIcons.communitybuilder: {
                    switch (level) {
                        case 3: {
                            fetchAndStoreGarden()
                            return createReward(
                                RewardType.GardenTrophy,
                                level,
                                hrefStandard
                            )
                        }
                        case 5:
                        case 10: {
                            if (isReferralEnginePilotEnabled) {
                                return createReward(
                                    RewardType.SpecialPrize,
                                    level,
                                    level === 10 ? hrefTShirt : hrefStandard
                                )
                            } else {
                                return undefined
                            }
                        }
                        default: {
                            return undefined
                        }
                    }
                }
                default: {
                    return undefined
                }
            }
        },
        [
            formatMessage,
            formatNumber,
            fullName,
            email,
            createReward,
            isReferralEnginePilotEnabled
        ]
    )

    const getCommunityBuilderReward = useCallback(
        (lastAchievementBannerDateString: string) => {
            const achievementInfo = achievements?.find(
                (a) => a.upc === AchievementIcons.communitybuilder
            )
            const levels = progress?.find(
                (p) => p.achievementId === achievementInfo?.id
            )?.levels
            if (levels) {
                const userProgress = levels
                    .slice()
                    .reverse()
                    .find((l) => l?.completedAt)
                const levelObject = {
                    achievementInfo,
                    userProgress
                }
                if (
                    isAfter(
                        new Date(levelObject?.userProgress?.completedAt),
                        new Date(lastAchievementBannerDateString)
                    )
                ) {
                    return getAchievementReward(levelObject)
                } else {
                    return undefined
                }
            } else {
                return undefined
            }
        },
        [achievements, progress, getAchievementReward]
    )

    return { getAchievementReward, getCommunityBuilderReward }
}

export default useGetReward
