import react, { useMemo } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { AchievementAccessibilityDescriptions } from '../../enums'

const messages = defineMessages({
    claimableAchievementsCount: {
        description:
            'description of the count of claimable achievements the user has',
        defaultMessage:
            'You have {claimCount, plural, one {# achievement to claim. } other {# achievements to claim. }}'
    },
    noClaimableAchievements: {
        description: 'description that the user has no claimable achievements',
        defaultMessage: 'You have no claimable achievements. '
    },
    tokenCount: {
        description: 'description of the current count of tokens',
        defaultMessage:
            'You have {tokenCount, plural, one {# achievement token.} other {# achievements tokens. }}'
    },
    activePlantStatus: {
        description:
            'description of what the current plant is and how many days are left until the plant is fully watered',
        defaultMessage:
            'You are growing the {plantName} and you have {daysLeft, plural, one {# day left of watering until fully grown. } other {# days left of watering until fully grown. }}'
    },
    noActivePlant: {
        description:
            'description that the user does not have any plants growing in their garden',
        defaultMessage: 'You do not have a plant growing in your garden. '
    },
    canWater: {
        defaultMessage: 'You can water your plant today. ',
        description: 'description that the user can water the plant today'
    },
    cannotWater: {
        defaultMessage: 'You have already water your plant today. ',
        description: 'description that the user can not water the plant today'
    },
    achievementLevel: {
        defaultMessage:
            'Out of {totalSteps}, you have {steps, plural, one {# step left until you achieve {achievementName}: level {level}. } other {# steps left until you achieve {achievementName}: level {level} }}',
        description:
            'description ton how many levels left until the next achievement level'
    }
})

export const useAchievementAccessibilityDescriptions = () => {
    const { formatMessage } = useIntl()

    return useMemo(
        () => ({
            [AchievementAccessibilityDescriptions.ClaimableAchievements]: ({
                claimCount
            }) =>
                claimCount > 0
                    ? formatMessage(messages.claimableAchievementsCount, {
                          claimCount
                      })
                    : formatMessage(messages.noClaimableAchievements),
            [AchievementAccessibilityDescriptions.TokenCount]: ({
                tokenCount
            }) => formatMessage(messages.tokenCount, { tokenCount }),
            [AchievementAccessibilityDescriptions.ActivePlantStatus]: ({
                hasActivePlant,
                plantName,
                daysLeft
            }) =>
                hasActivePlant
                    ? formatMessage(messages.activePlantStatus, {
                          plantName,
                          daysLeft
                      })
                    : formatMessage(messages.noActivePlant),
            [AchievementAccessibilityDescriptions.WaterStatus]: ({
                canWater
            }) =>
                formatMessage(
                    canWater ? messages.canWater : messages.cannotWater
                ),
            [AchievementAccessibilityDescriptions.AchievementLevel]: ({
                achievementName,
                totalSteps,
                steps,
                level
            }) =>
                formatMessage(messages.achievementLevel, {
                    achievementName,
                    totalSteps,
                    steps,
                    level
                })
        }),
        [formatMessage]
    )
}

export default useAchievementAccessibilityDescriptions
