import {
    Box,
    ClickAwayListener,
    Tooltip,
    TooltipProps,
    useMediaQuery
} from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import React, { ReactElement } from 'react'

// TooltipProps orginally has title and children as required property,
// We don't care about this anymore since they get set inside this component anyway
// We only want access to the other props incase they need to be accessed
export interface ThriveTooltipProps
    extends Omit<TooltipProps, 'title' | 'children'> {
    open: boolean
    setOpen: (open: boolean) => void
    content?: any
    children?: ReactElement<any, any>
}

export const ThriveTooltip: React.FC<ThriveTooltipProps> = ({
    open,
    setOpen,
    content,
    children,
    ...props
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))

    const handleTooltipOpen = () => {
        setOpen(!open)
    }

    return (
        <ClickAwayListener onClickAway={() => open && handleTooltipOpen()}>
            <Tooltip
                title={<Box>{content}</Box>}
                placement={isMobile ? 'bottom-start' : 'right-start'}
                componentsProps={{
                    tooltip: {
                        sx: {
                            background: theme.palette.background.paper,
                            maxWidth: theme.spacing(37.5),
                            boxShadow: theme.shadows[24],
                            borderRadius: theme.spacing(1),
                            p: 'none'
                        }
                    },
                    arrow: {
                        sx: {
                            color: theme.palette.background.paper,
                            fontSize: theme.spacing(2),
                            '&:before': {
                                boxShadow: theme.shadows[24]
                            }
                        }
                    }
                }}
                onClose={handleTooltipOpen}
                open={open}
                {...props}
            >
                {children}
            </Tooltip>
        </ClickAwayListener>
    )
}

export default ThriveTooltip
