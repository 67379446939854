import { Stack } from '@mui/material'
import Empty from '../../../icons/plants/emptyStatePlants/emptyStatePlant1'

const FallbackPlant = () => {
    return (
        <Stack>
            <Empty
                sx={{
                    width: '100%',
                    height: 161
                }}
            />
        </Stack>
    )
}

export default FallbackPlant
