import React from 'react'

export * from './plants'
export * from './achievements'

export const ClaimedSmall = React.lazy(() => import('./ClaimedSmall'))
export const CompletedLarge = React.lazy(() => import('./CompletedLarge'))
export const CompletedSmall = React.lazy(() => import('./CompletedSmall'))
export const LockedSmall = React.lazy(() => import('./LockedSmall'))
export const UnlockedSmall = React.lazy(() => import('./UnlockedSmall'))
