const WaterDrop = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={10}
        height={14}
        fill="none"
        {...props}
    >
        <path
            fill="#5DBFF0"
            d="M5 13.625A4.876 4.876 0 0 1 .125 8.75c0-2.31 3.3-6.652 4.215-7.82a.847.847 0 0 1 .635-.305H5c.254 0 .482.127.635.305.914 1.168 4.24 5.51 4.24 7.82A4.876 4.876 0 0 1 5 13.625ZM2.562 9.156a.437.437 0 0 0-.406-.406.418.418 0 0 0-.406.406A2.84 2.84 0 0 0 4.594 12 .418.418 0 0 0 5 11.594a.437.437 0 0 0-.406-.406 2.005 2.005 0 0 1-2.032-2.032Z"
        />
    </svg>
)
export default WaterDrop
