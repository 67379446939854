import { Stack } from '@mui/material'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { defineMessages } from 'react-intl'

const br = '%0A'
const referralEmail = 'communitybuilder@thriveglobal.com'

const messages = defineMessages({
    subject: {
        defaultMessage: `I've reached level {level} in {achievementTitle}!`,
        description: `Subject of the email to claim the reward for the achievement`
    },
    bodyStandard: {
        defaultMessage: `Hi Thrive Team!{br}{br}I've reached level {level} in {achievementTitle}! I've included below my full name, email, and shipping address.{br}{br}Full Name: {fullName}{br}Email: {email}{br}{br}Shipping:`,
        description: `Body of the email to claim the reward for the achievement`
    },
    bodyTShirt: {
        defaultMessage: `Hi Thrive Team!{br}{br}I've reached level {level} in {achievementTitle}! I've included below my full name, email, shipping address and t-shirt size.{br}{br}Full Name: {fullName}{br}Email: {email}{br}{br}Shipping:{br}T-Shirt Size:`,
        description: `Body of the email to claim the reward for the achievement with T-Shirt size`
    },
    email: {
        defaultMessage: 'Email:',
        description: 'Email label'
    },
    contactUs: {
        defaultMessage:
            'Contact us with your full name, email, the message below, and your shipping details to receive your gift!',
        description: 'contact us message'
    }
})

export const referralEmailStandard = (
    level: number,
    achievementTitle: string,
    fullName: string,
    email: string,
    formatMessage,
    formatNumber
) => {
    return `mailto:${referralEmail}?subject=${formatMessage(messages.subject, {
        level: formatNumber(level),
        achievementTitle
    })}&body=${formatMessage(messages.bodyStandard, {
        level: formatNumber(level),
        achievementTitle,
        fullName,
        email,
        br
    })}`
}

export const referralEmailStandardText = (
    level: number,
    achievementTitle: string,
    fullName: string,
    email: string,
    formatMessage,
    formatNumber
) => {
    return (
        <Stack gap={2}>
            <Stack>
                <CoreTypography variant="body1" fontWeight="bold">
                    {formatMessage(messages.email)}
                </CoreTypography>
                <CoreTypography variant="body1" sx={{ wordWrap: 'break-word' }}>
                    {referralEmail}
                </CoreTypography>
            </Stack>
            <CoreTypography variant="body1" sx={{ wordWrap: 'break-word' }}>
                {formatMessage(messages.contactUs)}
            </CoreTypography>
            <CoreTypography variant="body1" sx={{ wordWrap: 'break-word' }}>
                "
                {formatMessage(messages.subject, {
                    level: formatNumber(level),
                    achievementTitle
                })}
                "
            </CoreTypography>
        </Stack>
    )
}

export const referralEmailTShirt = (
    level: number,
    achievementTitle: string,
    fullName: string,
    email: string,
    formatMessage,
    formatNumber
) => {
    return `mailto:${referralEmail}?subject=${formatMessage(messages.subject, {
        level: formatNumber(level),
        achievementTitle
    })}&body=${formatMessage(messages.bodyTShirt, {
        level: formatNumber(level),
        achievementTitle,
        fullName,
        email,
        br
    })}`
}
