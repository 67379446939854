import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    Link,
    Stack,
    useMediaQuery
} from '@mui/material'
import {
    CoreAlert,
    CoreTypography,
    LeafDialog,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import PlantToggle from '../../../components/elements/PlantSelectToggle/PlantSelectToggle'
import ProductInfoCard from '../../../components/elements/ProductInfoCard/ProductInfoCard'
import WaterPlantCard from '../../../components/elements/WaterPlantCard/WaterPlantCard'
import { PlantType } from '../../../enums/plantTypes'
import {
    ProductInfo,
    RecommendedPlantsError,
    useGetRecommendedPlantsLazyQuery
} from '../../../graphql/generated/autogenerated'
import { usePlantsContext } from '../../../hooks/usePlantsProvider/usePlantsContext'
import withPlantsProvider from '../../../hooks/usePlantsProvider/withPlantsProvider'
import { ROUTES } from '../../../routes'

const MESSAGES = defineMessages({
    dialogTitle: {
        defaultMessage: 'Great work!',
        description: 'Title of the plant selector dialog'
    },
    confirmedTitle: {
        defaultMessage: 'Nice choice!',
        description:
            'Title of the plant selector dialog confirming plant selection'
    },
    dialogSubtitle: {
        defaultMessage:
            'As you continue on your well-being journey, you can grow plants for your Thrive garden. Choose a plant and watch it grow along with you.',
        description: 'Subtitle of the plant selector dialog'
    },
    selectAnother: {
        defaultMessage: 'Select another plant',
        description:
            'Title of the plant selector dialog when a user already has plants'
    },
    selectAnotherSubtitle: {
        defaultMessage: 'Select another plant to keep growing',
        description:
            'Subtitle of the plant selector dialog when a user already has plants'
    },
    confirmedFirstPlant: {
        defaultMessage:
            'This {plantName} will look great in your garden. Complete a Microstep or watch a Reset to water your plant for the first time.',
        description:
            'Subtitle of the plant selector dialog confirming plant selection'
    },
    closeButton: {
        defaultMessage: 'Close',
        description: 'button text for closing the dialog'
    },
    confirmButton: {
        defaultMessage: 'Confirm',
        description: 'button text for confirming the dialog'
    },
    error: {
        defaultMessage:
            'An error occurred while selecting your plant. Please visit the <ShopLink>shop</ShopLink> to try again.',
        description: 'Error message when selecting plant fails'
    }
})

export type PlantSelectorDialogProps = {
    open: boolean
    onClose: (dailyLimitReached?: boolean) => void
}

const PlantSelectorDialog: React.FC<PlantSelectorDialogProps> = ({
    open,
    onClose
}) => {
    const { breakpoints } = useTheme()
    const isSmallScreen = useMediaQuery(breakpoints.down('sm'))
    const { formatMessage } = useIntl()
    const { plantedPlants, products, purchaseProduct } = usePlantsContext()
    const [getRecommendedPlants, { data, error: fetchError }] =
        useGetRecommendedPlantsLazyQuery()
    const [selectedPlantUpc, setSelectedPlantUpc] =
        useState<ProductInfo['upc']>(undefined)
    const selectedPlant = useMemo(() => {
        return products.find((p) => p.upc === selectedPlantUpc)
    }, [products, selectedPlantUpc])
    const [submitted, setSubmitted] = useState(false)
    const [submitError, setSubmitError] = useState<boolean>(false)

    const options = useMemo(() => {
        const products = data?.retail.getRecommendedPlants
        if (
            products?.__typename === 'RecommendedPlantsResult' &&
            products.recommendedPlants.length > 0
        ) {
            return products.recommendedPlants
        }
        return []
    }, [data])

    const handleSelectPlant = useCallback(
        (_: unknown, upc: ProductInfo['upc']) => {
            setSelectedPlantUpc(upc)
        },
        []
    )

    const handleSubmit = useCallback(async () => {
        if (!selectedPlant) return
        try {
            await purchaseProduct(selectedPlant)
            setSubmitted(true)
            Avo.plantStarted({
                activityType: 'plant_purchased',
                featureType: 'achievement',
                plantId: selectedPlantUpc,
                plantType: selectedPlant.productType
            })
        } catch (e: unknown) {
            setSubmitError(true)
        }
    }, [purchaseProduct, selectedPlant])

    const handleClose = useCallback(() => {
        onClose()
        setSubmitted(false)
        setSubmitError(false)
        setSelectedPlantUpc(undefined)
    }, [onClose])

    const hasGrownOtherPlants = useMemo(
        () =>
            plantedPlants.filter((p) => p.productUpc !== PlantType.novafutura)
                .length > 0,
        [plantedPlants]
    )

    useEffect(() => {
        const plantError = data?.retail?.getRecommendedPlants?.['error']
        if (plantError === RecommendedPlantsError.DailyLimitReached) {
            onClose(true)
        }
    }, [data, onClose])

    useEffect(() => {
        if (open) {
            getRecommendedPlants({ fetchPolicy: 'cache-and-network' })
        }
    }, [getRecommendedPlants, open])

    return (
        <LeafDialog
            open={open && !fetchError && options.length === 2}
            maxWidth="md"
            fullScreen={isSmallScreen}
            onClose={handleClose}
            dialogTitle={
                <CoreTypography variant="h3">
                    {submitted
                        ? formatMessage(MESSAGES.confirmedTitle)
                        : hasGrownOtherPlants
                        ? formatMessage(MESSAGES.selectAnother)
                        : formatMessage(MESSAGES.dialogTitle)}
                </CoreTypography>
            }
        >
            <DialogContent
                sx={{
                    width: isSmallScreen ? undefined : 624,
                    overflow: 'unset'
                }}
            >
                <Stack gap={4.5}>
                    <CoreTypography variant="body1" lineHeight={1.3}>
                        {submitted
                            ? formatMessage(MESSAGES.confirmedFirstPlant, {
                                  plantName: selectedPlant.title
                              })
                            : hasGrownOtherPlants
                            ? formatMessage(MESSAGES.selectAnotherSubtitle)
                            : formatMessage(MESSAGES.dialogSubtitle)}
                    </CoreTypography>
                    <Stack
                        gap={3}
                        alignItems="center"
                        justifyContent="center"
                        direction={isSmallScreen ? 'column' : 'row'}
                    >
                        {submitted ? (
                            <>
                                <ProductInfoCard
                                    productInfo={products.find(
                                        (p) => p.upc === selectedPlantUpc
                                    )}
                                    fullWidth={isSmallScreen}
                                />
                                <WaterPlantCard onActionClick={handleClose} />
                            </>
                        ) : submitError ? (
                            <CoreAlert severity="error">
                                <Box>
                                    {formatMessage(MESSAGES.error, {
                                        ShopLink: (chunk) => (
                                            <span>
                                                <Link href={ROUTES.SHOP}>
                                                    {chunk}
                                                </Link>
                                            </span>
                                        )
                                    })}
                                </Box>
                            </CoreAlert>
                        ) : (
                            <PlantToggle
                                orientation={
                                    isSmallScreen ? 'vertical' : 'horizontal'
                                }
                                options={[options[0], options[1]]}
                                onChange={handleSelectPlant}
                                value={selectedPlantUpc}
                            />
                        )}
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack
                    width={'100%'}
                    direction={isSmallScreen ? 'column-reverse' : 'row'}
                    justifyContent="flex-end"
                    spacing={2}
                >
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(MESSAGES.closeButton)}
                        </CoreTypography>
                    </Button>
                    {!submitted && !submitError && (
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            disabled={!selectedPlant}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                {formatMessage(MESSAGES.confirmButton)}
                            </CoreTypography>
                        </Button>
                    )}
                </Stack>
            </DialogActions>
        </LeafDialog>
    )
}

export default memo(withPlantsProvider(PlantSelectorDialog))
